import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import StartupsByYear from './StartupsByYear';
import StartupByIndustry from './StartupByIndustry';

function StartupsAllYears(props) {
    const { startups, t } = props;
    const years = {};

    const [selectedYear, setSelectedYear] = useState();
    const [clicked, setClicked] = useState(false);

    const changeView = () => {
        setClicked(!clicked);
    }

    let counter = 0;

    Object.values(startups).map((startup) => {
        // Create a dict counting startups regisered in each year
        const date = new Date(startup.registreringsDato);
        if (date.getFullYear() > 2010) {
            if (years[date.getFullYear()] == null) {
                years[date.getFullYear()] = 1;
            } else {
                years[date.getFullYear()] += 1;
            }
        } else {
            counter += 1;
        }
    });
    let first = 0;
    Object.values(startups).forEach(startup => {
        if (startup.teammedlemmer) {
            if (startup.teammedlemmer.length > 0) {
                first += startup.teammedlemmer.length;
            }
        }
    });

    const series = [];
    const categories = [];
    Object.keys(years).map((year) => {
        // Add each year to category and number of startups to series to use in chart
        categories.push(year)
        series.push(years[year])
    });

    const allStartupChart = {
        chart: {
            type: 'line',
        },
        exporting: {
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'center',
                verticalAlign: 'bottom',
                y: 40,
            },
        },
        title: {
            text: t('statistics.StartupsRegistered'),
        },
        xAxis: {
            categories: categories,
        },
        series: [{
            name: "Registrerte",
            data: series
        }],
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: e => {
                            setSelectedYear(parseInt(e.point.category));
                            changeView();
                        }
                    }
                }
            }
        },
    }

    return (
        <>
            {clicked ?
                <>
                    <StartupsByYear startups={startups} year={selectedYear} />
                    <button
                        onClick={() => changeView()}
                        className="btn btn-primary block m-b mt-2"
                    >
                        Tilbake
                    </button>
                </>
                :
                <>
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType="chart"
                        options={allStartupChart}
                    />
                </>
            }
            <div className="mt-4 mb-4">
                <StartupByIndustry startups={startups} />
            </div>
        </>
    );
}


export default withTranslation('common')(StartupsAllYears);