import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import UserProfileHeader from '../components/profile/UserProfileHeader.tsx';
import StartupMiniComponent from '../components/startup/StartupMiniComponent/StartupMiniComponent.tsx';
import PageHeader from '../components/common/PageHeader';
import { updateCurrentView } from '../redux/actions/nav';
import fetchUsersData from '../redux/actions/users';
import LoadingSquare from '../components/common/LoadingSquare';

function UserProfileContainer(props) {
  const { dispatch, veilederId, users, auth, startups, t } = props;
  useEffect(() => {
    dispatch(updateCurrentView('veiledere'));
    dispatch(fetchUsersData());
  }, []);

  if (!startups.startupList || !users.userList) {
    return (
      <div>
        <PageHeader />
        <LoadingSquare />
      </div>
    );
  }

  const userId = veilederId;
  const user = users.userList[userId];

  let editProfile = [];
  if (userId === auth.uid) {
    editProfile = [
      <Link
        key="justAnUniqueKey"
        to={`/edit/veiledere/${userId}`}
        className="btn btn-white btn-xs "
        type="button"
      >
        <i className="fa fa-pencil-alt" /> {t('profile.Edit')}
      </Link>,
    ];
  }
  const alumniStartups = [];
  const aktiveStartups = [];
  const dodeStartups = [];
  const dvaleStartups = [];

  const startupObj = startups.startupList;

  if (user.startups) {
    const startup = Object.keys(user.startups).map((key) => {
      if (startupObj[key]) {
        const list = startupObj[key];
        if (startupObj[key].status.label === 'Aktiv') {
          list.id = key;
          aktiveStartups.push(
            <StartupMiniComponent key={key} startup={list} />
          );
          return list;
        }
        if (startupObj[key].status.label === 'Alumni') {
          list.id = key;
          alumniStartups.push(
            <StartupMiniComponent key={key} startup={list} />
          );
          return list;
        }
        if (startupObj[key].status.label === 'Død') {
          list.id = key;
          dodeStartups.push(<StartupMiniComponent key={key} startup={list} />);
          return list;
        }
        list.id = key;
        dvaleStartups.push(<StartupMiniComponent key={key} startup={list} />);
        return list;
      }
    });
  }

  return (
    <div>
      <PageHeader view={t('nav.Originators')} subview={t('nav.Profile')} />
      <UserProfileHeader
        user={user}
        veilederId={veilederId}
        editProfile={editProfile}
        startupObj={startups.startupList}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center m-t-lg">
            <h1>
              <span className="font-color-orange font-bold">
                {t('startups.Actives')}
              </span>{' '}
              {t('startups.Ideas')}
            </h1>
            <div className="d-flex flex-wrap">{aktiveStartups}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center m-t-lg">
            <h1>
              <span className="font-color-orange font-bold">Alumni</span>{' '}
              {t('startups.Ideas')}
            </h1>
            <div className="d-flex flex-wrap">{alumniStartups}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center m-t-lg">
            <h1>
              <span className="font-color-orange font-bold">
                {t('startups.Dead')}
              </span>{' '}
              {t('startups.Ideas')}
            </h1>
            <div className="d-flex flex-wrap">{dodeStartups}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center m-t-lg">
            <h1>
              <span className="font-color-orange font-bold">
                {t('startups.Inactive')}
              </span>{' '}
              {t('startups.Ideas')}
            </h1>
            <div className="d-flex flex-wrap">{dvaleStartups}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    nav: state.nav,
    users: state.users,
    auth: state.auth,
    startups: state.startups,
  }))
)(UserProfileContainer);
