import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const requestIdehavereData = () => ({
  type: 'REQUEST_IDEHAVERE_DATA',
});

const idehavereDataFetchedSuccessfully = (payload) => ({
  type: 'IDEHAVERE_DATA_FETCHED_SUCCESSFULLY',
  payload,
});

const idehavereDataFetchError = (error) => ({
  type: 'IDEHAVERE_DATA_FETCH_ERROR',
  error,
});

function fetchIdehavereData() {
  return (dispatch) => {
    dispatch(requestIdehavereData());
    firebase
      .database()
      .ref('idehavere')
      .on('value', (snapshot) => {
        const values = snapshot.val();
        Object.keys(values).forEach((key) => {
          if (values[key].studieSted.label === ' Test') {
            values[key].studieSted.label = 'Annet (n/a)';
            values[key].studieSted.value = 'Annet (n/a)';
          }
        });
        dispatch(idehavereDataFetchedSuccessfully(values));
      });
  };
}

export default fetchIdehavereData;
