import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function ideerFakultet(props) {
  const { idehavere, t } = props;
  const studieSted = {};

  Object.values(idehavere.idehaverList).map((ide) => {
    if (Object.prototype.hasOwnProperty.call(ide.studieSted, 'label')) {
      const kode = ide.studieSted.label.split('(')[1].split(')')[0];
      if (!studieSted[kode]) studieSted[kode] = 0;
      studieSted[kode] += 1;
    }
  });

  const meetingNotesChart = {
    chart: {
      type: 'column',
    },
    title: {
      text: `${t('statistics.Originators')}, ${t('statistics.faculty')}`,
    },
    xAxis: {
      categories: Object.keys(studieSted),
    },
    series: [
      {
        name: t('statistics.Originators'),
        data: Object.values(studieSted),
      },
    ],
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="chart"
      options={meetingNotesChart}
    />
  );
}

export default withTranslation('common')(ideerFakultet);
