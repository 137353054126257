export enum DateFilterPresets {
  ThisYear,
  ThisMonth,
  All,
}

interface IGetDateFilterPreset {
  preset: DateFilterPresets;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

const getDateFilterPreset = ({
  setStartDate,
  setEndDate,
  preset,
}: IGetDateFilterPreset): Date[] => {
  const today = new Date();

  // Get year we are in today
  const yearToday = today.getFullYear();

  // Get month we are in today
  const monthToday = today.getMonth();

  /* 
    January is month 0
    new Date(year, monthIndex, day, hours, minutes, seconds)
  */

  let startDate = new Date();

  if (preset == DateFilterPresets.ThisYear) {
    startDate = new Date(yearToday, 0, 1, 0, 0, 0);
  }
  if (preset == DateFilterPresets.ThisMonth) {
    startDate = new Date(yearToday, monthToday, 1, 0, 0, 0);
  }
  if (preset == DateFilterPresets.All) {
    startDate = new Date(2014, 7, 1);
  }

  // Update values inside datepicker
  setStartDate(startDate);
  setEndDate(today);

  return [startDate, today];
};

export default getDateFilterPreset;
