import React from 'react';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import PhaseComponent from './PhaseComponent';
import { IStartup } from '../../types/IStartup';
import { IIdehaver } from '../../types/IIdehaver';
import { ITeammemberList } from '../../types/ITeammember';

interface IStartupProfileWidget {
  startup: IStartup;
  startupId: string;
  teamMembers: ITeammemberList;
  tagLabels: Array<object>;
  idehaver: IIdehaver;
  usersObj: any;
}

const StartupProfileWidget = ({
  startup,
  startupId,
  teamMembers,
  tagLabels,
  idehaver,
  usersObj,
}: IStartupProfileWidget) => {
  const registreringsDato = new Date(startup.registreringsDato);

  const { t } = useTranslation('common');

  return (
    <div className="wrapper wrapper-content project-manager pt-0">
      <div className="ibox ibox-content">
        <div className="row justify-content-md-center">
          <div className="col-8">
            <h1>{startup.ideNavn}</h1>
          </div>
          <div className="col-4 align-self-center">
            <Link to={`/edit/startups/${startupId}`}>
              <button className="btn btn-white w-100" type="button">
                {t('startups.Edit')}
              </button>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="col pl-0">
              <div>
                <h3>{startup.status.label}</h3>
              </div>
              <div>
                <PhaseComponent phase={startup.fase} />
              </div>
            </div>
          </div>
          <div className="col-4 logo-center img-responsive">
            <Link to={startup.nettside}>
              <img className="img-fluid" alt="logo" src={startup.logoUrl} />
            </Link>
          </div>
        </div>
        <hr />

        <div className="row justify-content-md-center">
          <Link to={`/veiledere/${startup.veileder.value}`}>
            <div className="col">
              <div className="text-center veileder-thumb">
                <img
                  alt="profilepicture"
                  className="img-circle veilder-thumb"
                  src={usersObj[startup.veileder.value].profilePicUrl}
                />
              </div>

              <div>{startup.veileder.label}</div>
            </div>
          </Link>
        </div>

        <div className="pl-1 pr-1">
          <h4>{t('startups.IdeaDescription')}</h4>
          <p className="medium m-b-xl">{startup.ideBeskrivelse}</p>

          <div className="row">
            <div className="col-xs-6">
              <p className="big font-bold">
                <FaCircle className="text-navy" />
                <span className="ml-1">{t('startups.Originator')}</span>
              </p>
              <div className="small">
                <ul className="list-remove-intent">
                  <li>
                    <span className="font-bold mr-1">
                      {' '}
                      {t('startups.Name')}:
                    </span>
                    {idehaver.navn}
                  </li>
                  <li>
                    <span className="font-bold mr-1">
                      {' '}
                      {t('startups.Email')}:
                    </span>
                    {idehaver.email}
                  </li>
                  <li>
                    <span className="font-bold mr-1">
                      {' '}
                      {t('startups.Tel')}:
                    </span>
                    {idehaver.mobilnr}
                  </li>
                  <li>
                    <span className="font-bold mr-1">
                      {' '}
                      {t('startups.Study')}:
                    </span>
                    {idehaver.studieSted.label}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-6">
              <p className="big font-bold">
                <FaCircle className="text-warning" />
                <span className="ml-1">{t('startups.Company')}</span>
              </p>
              <div className="small">
                <ul className="list-remove-intent">
                  <li>
                    <span className="font-bold mr-1">
                      {' '}
                      {t('startups.Industry')}:
                    </span>
                    {startup.bransje.label}
                  </li>
                  <li>
                    <span className="font-bold mr-1">
                      {' '}
                      {t('startups.WebPage')}:
                    </span>
                    {startup.nettside}
                  </li>
                  <li>
                    <span className="font-bold mr-1">
                      {t('startups.RegisterDate')}:
                    </span>
                    {`${registreringsDato.getDate()}.${
                      registreringsDato.getMonth() + 1
                    }.${registreringsDato.getFullYear()}`}
                  </li>
                  <li>
                    <span className="font-bold mr-1">Orgnr:</span>
                    {startup.organisasjonsNr}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-1">
          <div className="row">
            <div className="col-xs-12 pt-4">
              <p className="font-bold">
                <FaCircle className="text-info" />
                <span className="ml-1">{t('startups.TeamMembers')}</span>
              </p>

              <ul className="list-remove-intent small">
                {Object.keys(teamMembers).map((teamKey: string) => (
                  <li key={teamKey}>
                    <span className="mr-1"> {teamMembers[teamKey].navn}</span>
                  </li>
                ))}
              </ul>

              <div className="form-group">
                <ul className="tag-list p-0"></ul>
              </div>
            </div>
          </div>

          <div className="row ml-3 d-flex align-items-center">
            <div
              className="font-bold font-color-orange"
              style={{ fontSize: '60px' }}
            >
              #
            </div>

            <div className="col-xs-10">
              <ul className="tag-list pl-2 pt-2">{tagLabels}</ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupProfileWidget;
