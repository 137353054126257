import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function TronderEnergiByYear(props) {
    const { data, year, t } = props;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const roundNumbers = [];
    const sokt = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const innvilget = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    Object.values(data).forEach(round => {
        const date_index = new Date(round["dato"] * 1000).getMonth();
        roundNumbers.push(round["runde"]);
        sokt[date_index] = round["sokt_stotte"];
        innvilget[date_index] = round["innvilget_stotte"];
    });

    const startupByYearChart = {
        chart: {
            type: 'column'
        },
        exporting: {
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'center',
                verticalAlign: 'bottom',
                y: 40,
            },
        },
        title: {
            text: `${year}`,
            crosshair: true
        },
        subtitle: {
            text: `Runde ${Math.min(...roundNumbers)} til ${Math.max(...roundNumbers)}`
        },
        xAxis: {
            categories: months,
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0
            }
        },
        series: [
            {
                name: "Søkt støtte",
                data: sokt
            },
            {
                name: "Innvilget støtte",
                data: innvilget
            },
        ],
    };

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType="chart"
                options={startupByYearChart}
            />
        </>
    );
}

export default withTranslation('common')(TronderEnergiByYear);
