import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { updateCurrentView } from '../redux/actions/nav';
import AdminUserControl from '../components/admin/AdminUserControl/AdminUserControl';
import DeleteStartup from '../components/admin/DeleteStartup';
import ExportToCSVComponent from '../components/admin/ExportToCSV/ExportToCSVComponent';
import UpdateTEBNumbers from '../components/admin/UpdateTEBNumbers.tsx';
import PageHeader from '../components/common/PageHeader';
import LoadingSquare from '../components/common/LoadingSquare';
import hostname from '../config/hostname';
import LackingInfoBoxAdmin from '../components/admin/LackingInfoBoxAdmin';
import SetUserRegDate from '../components/admin/SetUserRegDate';
import ExportMeetingNotesToCSV from '../components/admin/ExportToCSV/ExportMeetingNotesToCSV';
import hasAdminAccess from '../components/utils/hasAdminAccess.ts';
import AboutAdmin from '../components/admin/AboutAdmin.tsx';
import WarningComponent from '../components/admin/WarningComponent.tsx';
import MailList from '../components/admin/MailList/MailList';
import MailSignup from '../components/admin/MailSignup/MailSignup';
import isLeader from '../components/utils/isLeader';

function AdminContainer({
  dispatch,
  users,
  startups,
  teammembers,
  tags,
  idehavere,
  auth,
  lackingInfo,
  t,
}) {
  const { startupList } = startups;
  const { teamList } = teammembers;

  useEffect(() => {
    dispatch(updateCurrentView('admin'));
  }, []);

  if (
    users.fetchingData ||
    startups.fetchingData ||
    teammembers.fetchingData ||
    tags.fetchingData ||
    lackingInfo.fetchingData
  ) {
    return (
      <div>
        <PageHeader view="Admin" subview={null} />
        <LoadingSquare />
      </div>
    );
  }

  // Add key to userObject
  const userArray = Object.keys(users.userList).map((key) => {
    const user = users.userList[key];
    user.uid = key;
    return user;
  });

  const thisUser = userArray.find((user) => user.uid === auth.uid);

  return (
    <div>
      <PageHeader view="Admin" subview={null} />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <AboutAdmin userArray={userArray} />
              <MailList
                userArray={userArray}
                idehaverList={idehavere.idehaverList}
                startupMap={startupList}
                teamList={teamList}
              />
              {(isLeader(thisUser.position, thisUser.status[0].label) ||
                hasAdminAccess(thisUser)) && (
                <AdminUserControl users={userArray} thisUser={thisUser} />
              )}
              {(hostname === 'basen' || hostname === 'localhost') && (
                <MailSignup />
              )}
            </div>
            {hasAdminAccess(thisUser) && (
              <div>
                <hr />
                <ExportToCSVComponent
                  startupList={startupList}
                  idehaverList={idehavere.idehaverList}
                  teamList={teamList}
                />
                <ExportMeetingNotesToCSV
                  startupList={startupList}
                  userList={users.userList}
                />
                {(hostname === 'basen' || hostname === 'localhost') && (
                  <SetUserRegDate users={userArray} />
                )}
                {(hostname === 'basen' || hostname === 'localhost') && (
                  <UpdateTEBNumbers />
                )}
                <LackingInfoBoxAdmin
                  lackingInfo={lackingInfo.lackingInfo}
                  startups={startupList}
                  idehavere={idehavere.idehaverList}
                />
                <WarningComponent
                  title={t('admin.DangerZone')}
                  content={t('admin.DeletionCantBeUndone')}
                />
                <DeleteStartup />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
    users: state.users,
    startups: state.startups,
    idehavere: state.idehavere,
    teammembers: state.teammembers,
    tags: state.tags,
    lackingInfo: state.lackingInfo,
  }))
)(AdminContainer);
