const initialState = {
  fetchingData: true,
  error: null,
  userList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_USERS_DATA':
      return {
        ...state,
        fetchingData: true,
      };

    case 'USERS_DATA_FETCHED_SUCCESSFULLY':
      return {
        fetchingData: false,
        error: null,
        userList: action.payload,
      };
    case 'USERS_DATA_FETCH_ERROR':
      return {
        fetchingData: false,
        error: action.error,
        userList: null,
      };
    default:
      return state;
  }
};
