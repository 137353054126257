// (list of values, where tag is used, name of list of values, checking old value, counting frequency)

function checkTag(objectList, keyvalue, listName, oldObject, pushToObject) {
  if (objectList) {
    Object.keys(objectList).map((key) => {
      if (objectList[key][keyvalue]) {
        if (Object.keys(objectList[key][keyvalue]).includes('label')) {
          if (objectList[key][keyvalue].label === oldObject.label)
            pushToObject(listName, key);
        } else if (objectList[key][keyvalue] === oldObject.label)
          pushToObject(listName, key);
      }
    });
  }
}

export default checkTag;
