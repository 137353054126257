import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { compose } from 'redux';
import FooterComponent from '../components/common/FooterComponent.tsx';
import LeftNavComponent from '../components/common/LeftNavComponent';
import ForceError from '../components/utils/ForceError';
import fetchIdehavereData from '../redux/actions/idehavere';
import fetchLackingInfo from '../redux/actions/lackingInfo';
import fetchStartupData from '../redux/actions/startups';
import fetchTagsData from '../redux/actions/tags';
import fetchTeamMembersData from '../redux/actions/teamMembers';
import fetchTEBNumbers from '../redux/actions/TEBNumbers';
import fetchUsersData from '../redux/actions/users';
import AdminContainer from './AdminContainer';
import DashboardContainer from './DashboardContainer';
import EditProfileContainer from './EditProfileContainer';
import EditStartupContainer from './EditStartupContainer';
import StartupListContainer from './StartupListContainer';
import StatisticsContainer from './StatisticsContainer';
import TagsAdminContainer from './TagsAdminContainer';
import VeilederListContainer from './VeilederListContainer';
//Firebase was never imported? (25.sep 2024)
//And that was the problem.. Might be missing elsewhere as well.
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

function MainContainer(props) {
  const navigate = useNavigate();
  const { dispatch, users, auth, t } = props;
  useEffect(() => {
    dispatch(fetchStartupData());
    dispatch(fetchIdehavereData());
    dispatch(fetchUsersData());
    dispatch(fetchTeamMembersData());
    dispatch(fetchTagsData());
    dispatch(fetchTEBNumbers());
    dispatch(fetchLackingInfo());
  }, []);

  const registerNewUser = () => {
    console.log('Registering new user');
    const { email, uid } = firebase.auth().currentUser;
    const firstname = email.split('.')[0];
    const lastname = email.split('.')[1].split('@')[0];

    firebase
      .database()
      .ref('users')
      .child(uid)
      .set({
        createdAt: new Date().getTime(),
        username: email.split('@')[0],
        firstname,
        lastname,
        about: t('signup.HaventWrittenAboutMeYet'),
        position: 'Veileder',
        sparkMail: email,
        alternativeMail: 'n/a',
        phoneNumber: 'n/a',
        tags: [{ value: '0', label: t('signup.NotYetChangedTag') }],
        startups: [],
        capacity: 0,
        status: [{ value: '0', label: 'Aktiv' }],
        profilePicUrl: 'https://pbs.twimg.com/profile_images/706844157093027840/2Aan_aSU.jpg',
      })
      .then(() => {
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error(error);
        console.log('Error registering new user');
      });
  };

  useEffect(() => {
    // Fixed by importing firebase (25.sep 2024)
    console.log('useEffect test');
    if (users.userList && auth.uid) {
      console.log('useEffect test 2 after if');
      const userKeys = Object.keys(users.userList);
      if (!userKeys.includes(auth.uid)) {
        console.log('useEffect test 3 after if');
        registerNewUser();
      }
    }
  }, [users.userList, auth.uid]);

  return (
    <div>
      <div style={{ position: 'fixed' }}>
        <LeftNavComponent />
      </div>
      <div id="wrapper">
        <div
          id="page-wrapper"
          className="wrapper-bg"
          style={{ minHeight: '100vh' }}
        >
          <Routes>
          <Route path="/*" element={<DashboardContainer />} />
          <Route path="startups/:startupId?" element={<StartupListContainer />} />
          <Route path="admin" element={<AdminContainer />} />
          <Route path="tagsadmin" element={<TagsAdminContainer />} />
          <Route path="veiledere/:veilederId?" element={<VeilederListContainer />} />
          <Route path="edit/startups/:startupId" element={<EditStartupContainer />} />
          <Route path="edit/veiledere/:veilederId" element={<EditProfileContainer />} />
          <Route path="statistikk" element={<StatisticsContainer />} />
          <Route path="kastfeil" element={<ForceError />} />
        </Routes>

          <FooterComponent />
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
    idehavere: state.idehavere,
    users: state.users,
    startups: state.startups,
    teammembers: state.teammembers,
    tags: state.tags,
  }))
)(MainContainer);
