import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import getDateFilterPreset, {
  DateFilterPresets,
} from '../../utils/predefinedDateRanges';

interface IDateFilter {
  setFilter: (columnId: string, updater: Date[]) => void;
}

const DateFilter = ({ setFilter }: IDateFilter) => {
  /* 
    Default values works as initial sort parameters 
    and is set to 1th of January, 2014, when Spark* started its work
  */
  const [startDate, setStartDate] = useState(new Date(2014, 0, 1));
  const [endDate, setEndDate] = useState(new Date());

  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation('common');

  useEffect(() => {
    setErrorMessage('');

    if (startDate > endDate) {
      setErrorMessage(t('startups.EndDateBeforeStartDateErrorMessage'));
    } else {
      setFilter('dateRegistered', [startDate, endDate]);
    }
  }, [startDate, endDate]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <div className="pr-4">
          <p>Start</p>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              const dateFormatted = new Date(date as Date);
              dateFormatted.setHours(0, 0, 0);
              setStartDate(dateFormatted);
            }}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>
          <p>Slutt</p>
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              const dateFormatted = new Date(date as Date);
              dateFormatted.setHours(23, 0, 0);
              setEndDate(dateFormatted);
            }}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>

      <p className="mt-2 text-danger">{errorMessage}</p>

      <div className="d-flex flex-row mt-3">
        <button
          className="mr-3 btn btn-secondary"
          onClick={() =>
            setFilter(
              'dateRegistered',
              getDateFilterPreset({
                setStartDate: setStartDate,
                setEndDate: setEndDate,
                preset: DateFilterPresets.ThisYear,
              })
            )
          }
        >
          Dette året
        </button>

        <button
          className="mr-3 btn btn-secondary"
          onClick={() =>
            setFilter(
              'dateRegistered',
              getDateFilterPreset({
                setStartDate: setStartDate,
                setEndDate: setEndDate,
                preset: DateFilterPresets.ThisMonth,
              })
            )
          }
        >
          Denne måneden
        </button>

        <button
          className="mr-3 btn btn-secondary"
          onClick={() =>
            setFilter(
              'dateRegistered',
              getDateFilterPreset({
                setStartDate: setStartDate,
                setEndDate: setEndDate,
                preset: DateFilterPresets.All,
              })
            )
          }
        >
          Alle
        </button>
      </div>
    </div>
  );
};

export default DateFilter;
