import React, { useState } from 'react';
import setTEBNumbers from '../../redux/actions/setTEBNumbers';
import AdminFrame from './AdminFrame';

function UpdateTEBNumbers() {
  interface ITEBNumbers {
    stotteTotalt: string;
    unikeProsjekterSokt: string;
    antallRunderGjennomfort: string;
    innvilgetStotteTotalt: string;
    unikeProsjekterStottet: string;
    gjennomsnittsligStottePerProsjekt: string;
  }

  const [formTEBNumbers, setFormTEBNumbers] = useState<ITEBNumbers>({
    stotteTotalt: '',
    unikeProsjekterSokt: '',
    antallRunderGjennomfort: '',
    innvilgetStotteTotalt: '',
    unikeProsjekterStottet: '',
    gjennomsnittsligStottePerProsjekt: '',
  });

  function onSubmit(evt: any) {
    evt.preventDefault();

    setTEBNumbers(formTEBNumbers);
  }

  return (
    <AdminFrame
      title="Oppdater Tronderenergibidraget-nokkeltall"
      content={
        <div>
          <form className="m-t" onSubmit={onSubmit}>
            <div className="form-group">
              <h4>Sokt sotte totalt (kr):</h4>
              <input
                type="number"
                className="form-control"
                onChange={(e) =>
                  setFormTEBNumbers({
                    ...formTEBNumbers,
                    stotteTotalt: e.target.value,
                  })
                }
                required
              />
            </div>

            <div className="form-group">
              <h4>Antall unike prosjekter sokt:</h4>
              <input
                type="number"
                className="form-control"
                onChange={(e) =>
                  setFormTEBNumbers({
                    ...formTEBNumbers,
                    unikeProsjekterSokt: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group">
              <h4>Antall runder gjennomfort:</h4>
              <input
                type="number"
                className="form-control"
                onChange={(e) =>
                  setFormTEBNumbers({
                    ...formTEBNumbers,
                    antallRunderGjennomfort: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group">
              <h4>Innvilget stotte totalt (kr):</h4>
              <input
                type="number"
                className="form-control"
                onChange={(e) =>
                  setFormTEBNumbers({
                    ...formTEBNumbers,
                    innvilgetStotteTotalt: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group">
              <h4>Antall unike prosjekter stottet:</h4>
              <input
                type="number"
                className="form-control"
                onChange={(e) =>
                  setFormTEBNumbers({
                    ...formTEBNumbers,
                    unikeProsjekterStottet: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group">
              <h4>Gjennomsnittslig stotte per unike prosjekt (kr):</h4>
              <input
                type="number"
                className="form-control"
                onChange={(e) =>
                  setFormTEBNumbers({
                    ...formTEBNumbers,
                    gjennomsnittsligStottePerProsjekt: e.target.value,
                  })
                }
                required
              />
            </div>
            <button
              className="btn btn-primary block full-width m-b"
              type="submit"
            >
              Oppdater
            </button>
          </form>
        </div>
      }
    />
  );
}

export default UpdateTEBNumbers;
