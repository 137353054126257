import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateCurrentView } from '../redux/actions/nav';
import PageHeader from '../components/common/PageHeader';
import LoadingSquare from '../components/common/LoadingSquare';
import fetchTagsData from '../redux/actions/tags';
import fetchUsersData from '../redux/actions/users';
import fetchStartupData from '../redux/actions/startups';
import fetchIdehavereData from '../redux/actions/idehavere';
import fetchTeamMembersData from '../redux/actions/teamMembers';

import TagsTabComp from '../components/tags/TagsTab/TagsTabComp';
import hasAdminAccess from '../components/utils/hasAdminAccess.ts';
import AboutAdmin from '../components/admin/AboutAdmin.tsx';

function TagsAdminContainer(props) {
  const { nav, tags, t, startups, users, teammembers, idehavere, auth } = props;

  useEffect(() => {
    if (
      !tags.tagsList ||
      !users.userList ||
      !startups.startupList ||
      !teammembers.teamList ||
      !idehavere.idehaverList
    ) {
      const { dispatch } = props;
      dispatch(updateCurrentView('tagsadmin'));
      dispatch(fetchStartupData());
      dispatch(fetchIdehavereData());
      dispatch(fetchUsersData());
      dispatch(fetchTeamMembersData());
      dispatch(fetchTagsData());
    }
  }, []);

  if (tags.fetchingData || users.fetchingData) {
    return (
      <div>
        <PageHeader view="Tag Administration" subview={null} />
        <LoadingSquare />
      </div>
    );
  }

  // Adding id to tagsList for use in tag categories
  Object.keys(tags.tagsList).map((key) => {
    tags.tagsList[key].id = key;
  });

  // Add key to userObject
  const userArray = Object.keys(users.userList).map((key) => {
    const user = users.userList[key];
    user.uid = key;
    return user;
  });

  const thisUser = userArray.find((user) => user.uid === auth.uid);

  return (
    <div>
      <PageHeader view="Tag Admin" subview={null} />
      <div className="wrapper wrapper-content  animated fadeInRight">
        {hasAdminAccess(thisUser) ? (
          <div className="row">
            <TagsTabComp
              nav={nav}
              tags={tags}
              startups={startups}
              users={users}
              teammembers={teammembers}
              idehavere={idehavere}
            />
          </div>
        ) : (
          <AboutAdmin userArray={userArray} />
        )}
      </div>
    </div>
  );
}

export default connect((state) => ({
  nav: state.nav,
  auth: state.auth,
  users: state.users,
  tags: state.tags,
  idehavere: state.idehavere,
  startups: state.startups,
  teammembers: state.teammembers,
}))(TagsAdminContainer);
