import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function GendersAllYears(props) {

  const { startups, idehaverList, t } = props;

  const counter = {};
  const male = [];
  const female = [];
  const other = [];

  Object.values(startups).map((startup) => {
    let date = new Date(startup.registreringsDato);
    if (date.getFullYear() > 2000) {
      let gender = idehaverList[startup.idehaver].sex;
      if (!counter[date.getFullYear()]) {
        counter[date.getFullYear()] = { m: 0, f: 0, o: 0 }
      }
      counter[date.getFullYear()][gender] += 1;
    }
  });

  Object.keys(counter).forEach((year) => {
    male.push(counter[year]["m"]);
    female.push(counter[year]["f"]);
    other.push(counter[year]["o"]);
  });

  const meetingNotesChart = {
    chart: {
      type: 'line',
    },
    exporting: {
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        y: 40,
      },
    },
    title: {
      text: t('statistics.GendersByYear'),
    },
    xAxis: {
      categories: Object.keys(counter),
    },
    series: [
      {
        name: 'Menn',
        data: male
      }, {
        name: 'Kvinner',
        data: female
      }, {
        name: 'Andre',
        data: other
      }
    ]
  };

  return (
    <div className="col-lg-12">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        options={meetingNotesChart}
      />
    </div>
  );
};

export default withTranslation('common')(GendersAllYears);
