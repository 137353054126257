import React, { useEffect } from 'react';

const ForceError = () => {
  useEffect(() => {
    throw new Error('Feilmelding');
  }, []);

  return <div>Denne siden brukes for å manuellt kaste feilmeldinger</div>;
};

export default ForceError;
