import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const requestUsersData = () => ({
  type: 'REQUEST_USERS_DATA',
});

const usersDataFetchedSuccessfully = (payload) => ({
  type: 'USERS_DATA_FETCHED_SUCCESSFULLY',
  payload,
});

const userDataFetchError = (error) => ({
  type: 'USERS_DATA_FETCH_ERROR',
  error,
});

function fetchUsersData() {
  return (dispatch) => {
    dispatch(requestUsersData());
    firebase
      .database()
      .ref('users')
      .on('value', (snapshot) => {
        dispatch(usersDataFetchedSuccessfully(snapshot.val()));
      });
  };
}

export default fetchUsersData;
