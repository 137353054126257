import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

function onDelete(startupId, noteId) {
  firebase
    .database()
    .ref(`startups/${startupId}/meetingNotes`)
    .child(noteId)
    .remove()
    .then(() => {})
    .catch((err) => {
      console.log('Startup update error.');
      console.log(err.message);
    });
}

export default onDelete;
