import msToFormattedDate from '../../utils/msToFormattedDate';

function formatAllData(
  startupList,
  idehaverList,
  teamList,
  hostname,
  chosenDelimiter
) {
  let allKeys = '';
  const allStartupKeys = [];

  const filteredStartupArray = Object.keys(startupList).map((key) => {
    const startupDateString = msToFormattedDate(
      startupList[key].registreringsDato
    );
    const idehaverID = startupList[key].idehaver;
    const idehaver = idehaverList[idehaverID];
    const { studieSted } = idehaver;
    let institutt = 'n/a';
    let fakultet = 'n/a';
    if (typeof studieSted === 'object') {
      fakultet = studieSted.value.slice(
        studieSted.value.indexOf('(') + 1,
        studieSted.value.length - 1
      );
      institutt = studieSted.value.slice(0, studieSted.value.indexOf('(') - 1);
    }
    const {
      alder,
      alternativeMail,
      arbeidserfaring,
      deltattKurs,
      email,
      erfarenGrunder,
      erfaring,
      familieVenner,
      horteomspark,
      kull,
      mobilnr,
      navn,
      sex,
      startupRole,
      shareContactInformation,
      competance,
    } = idehaver;
    let startupTagString = '';
    if (startupList[key].tags) {
      Object.keys(startupList[key].tags).map((tagKey) => {
        startupTagString += `${startupList[key].tags[tagKey].label}, `;
      });
    }
    const ideBeskrivelseNoNewLines = startupList[key].ideBeskrivelse.replace(
      /[\r\n]+/g,
      ' '
    );
    let teamNameString = '';
    let teamEpostString = '';
    let teamTelefonnrString = '';
    let teamHorteomsparkString = '';
    let teamSexString = '';
    let teamStudieretningString = '';
    let teamAarskursString = '';
    let teamDateString = '';
    let teamRoleString = '';
    let teamCompetanceString = '';
    if (startupList[key].teammedlemmer !== undefined) {
      Object.values(startupList[key].teammedlemmer).map((teamKey) => {
        teamNameString += `${teamList[teamKey].navn}, `;
        teamEpostString += `${teamList[teamKey].epost}, `;
        teamTelefonnrString += `${teamList[teamKey].telefonnr}, `;
        teamHorteomsparkString += `${teamList[teamKey].horteomspark}, `;
        teamSexString += `${teamList[teamKey].sex}, `;
        teamStudieretningString += `${teamList[teamKey].studieretning}, `;
        teamAarskursString += `${teamList[teamKey].aarskurs}, `;
        teamDateString += `${msToFormattedDate(
          teamList[teamKey].registreringsdato
        )}, `;
        teamRoleString += `${teamList[teamKey].startupRole}, `;
        teamCompetanceString += `${teamList[teamKey].kompetanse}, `;
      });
    }

    const startup = {
      // Startupinfo
      startupId: key.slice(1, key.length),
      ideNavn: startupList[key].ideNavn,
      ideBeskrivelse: ideBeskrivelseNoNewLines,
      status: startupList[key].status.label,
      nettside: startupList[key].nettside,
      registreringsDato: startupDateString,
      fase: startupList[key].fase.label,
      orgNr: startupList[key].organisasjonsNr,
      stotte: startupList[key].stotte,
      stotteType: startupList[key].stotteType,
      tags: startupTagString,
      // Idehaver
      navn,
      alder,
      sex,
      mobilnr,
      email,
      alternativeMail,
      bransje: startupList[key].bransje.label,
      institutt,
      fakultet,
      kull,
      arbeidserfaring,
      deltattKurs,
      erfarenGrunder,
      erfaring,
      familieVenner,
      horteomspark,
      startupRole,
      // Teammembers
      teamName: teamNameString,
      teamEpost: teamEpostString,
      teamTelefonnr: teamTelefonnrString,
      teamHorteomspark: teamHorteomsparkString,
      teamSex: teamSexString,
      teamStudieretning: teamStudieretningString,
      teamAarskurs: teamAarskursString,
      teamDate: teamDateString,
      teamRole: teamRoleString,
      teamCompetance: teamCompetanceString,
      // Veilederinfo
      veilederId: startupList[key].veileder.value,
    };

    if (hostname === 'basen') {
      startup.eit = startupList[key].eit;
      startup.kvinneprosjektet = startupList[key].kvinneprosjektet;
    }

    if (hostname === 'sparknord') {
      startup.idehaverShareContactInformation =
        idehaver.shareContactInformation;
      startup.idehaverCompetance = idehaver.competance;
      startup.motivation = startupList[key].motivation;
      startup.ambitions = startupList[key].ambitions;
      startup.sparkExpectations = startupList[key].sparkExpectations;
      startup.lackingCompetance = startupList[key].lackingCompetance;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const startupQuestion of Object.keys(startup)) {
      if (!allStartupKeys.includes(startupQuestion)) {
        allStartupKeys.push(startupQuestion);
      }
    }
    return startup;
  });
  allStartupKeys.map((key) => {
    allKeys += `${key}${chosenDelimiter}`;
  });
  return [filteredStartupArray, allKeys];
}

export default formatAllData;
