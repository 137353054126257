import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  FaBullhorn,
  FaDesktop,
  FaStar,
  FaUser,
  FaWheelchair,
  FaQuestion,
} from 'react-icons/fa';
import {
  updateCurrentClientTab,
  updateCurrentClientWidget,
} from '../../../redux/actions/nav';
import checkIfUserBelongs from './checkIfUserBelongs';
import checkIfSimilarTags from './checkIfSimilarTags';
import checkIfChosenVeileder from './checkIfChosenVeileder';
import MenuItem from './MenuItem';
import ClientInfo from '../ClientInfo/ClientInfo';
import makeUserArray from './makeUserArray';
import hostname from '../../../config/hostname';
import isLeader from '../../utils/isLeader';

function ClientTabComponent({ dispatch, nav, users, startupList, tags, t }) {
  const { currentClientTab } = nav;

  const [filterTags, setFilterTags] = useState('');
  const [tagOptions, setTagOptions] = useState('');
  const [filterVeileder, setFilterVeileder] = useState('');

  const userArray = makeUserArray(users);

  useEffect(() => {
    if (tags.tagsList) {
      setTagOptions(tags.tagsList.tagOptions);
    }
  }, [tags]);

  function handleClick(key) {
    dispatch(updateCurrentClientWidget(key));
  }

  function handleTagChange(event) {
    setFilterTags(event);
  }

  function removeFilters() {
    setFilterTags('');
    setFilterVeileder('');
  }

  function updateClientTab(updateTo) {
    dispatch(updateCurrentClientTab(updateTo));
  }

  function handleVeilederChange(event) {
    const userKey = event.value;
    const chosenVeileder = userArray.find((user) => user.uid === userKey);
    const { position } = chosenVeileder;
    const status = chosenVeileder.status[0].label;

    if (status === 'Alumni') {
      dispatch(updateCurrentClientTab('Alumni'));
    } else if (isLeader(position, status)) {
      dispatch(updateCurrentClientTab('Leder'));
    } else {
      dispatch(updateCurrentClientTab(position));
    }
    setFilterVeileder(event);
  }

  const userTable = [];
  Object.keys(userArray).map((key) => {
    if (userArray.hasOwnProperty(key)) {
      const { position } = userArray[key];
      const status = userArray[key].status[0].label;
      if (
        userArray[key] &&
        checkIfUserBelongs(position, status, currentClientTab) &&
        checkIfSimilarTags(userArray[key].tags, filterTags) &&
        checkIfChosenVeileder(userArray[key].uid, filterVeileder)
      ) {
        userTable.push(
          <ClientInfo
            key={userArray[key].uid}
            userIndex={key}
            userArray={userArray}
            startupList={startupList}
            handleClick={handleClick}
          />
        );
      }
    }
  });

  if (userTable.length === 0) {
    userTable.push(
      <tr key={1}>
        <td className="contact-type text-center">{t('sparks.NoOneHere')}</td>
      </tr>
    );
  }

  return (
    <div className="col-sm-12">
      <div className="ibox">
        <div className="ibox-content">
          <h2>{t('sparks.SparkEmployees')}</h2>
          <p>{t('sparks.AddFilter')}</p>
          <div className="form-group">
            <Select
              placeholder="Tags"
              name="tag-input"
              value={filterTags}
              options={tagOptions}
              multi
              onChange={handleTagChange}
            />
          </div>
          <div className="form-group">
            <Select
              placeholder={t('sparks.User')}
              name="veileder"
              value={filterVeileder}
              options={userArray.map((user) => ({
                value: user.uid,
                label: `${user.firstname} ${user.lastname}`,
              }))}
              onChange={handleVeilederChange}
            />
          </div>

          {(filterTags !== '' || filterVeileder !== '') && (
            <button
              type="button"
              className="btn btn-light"
              onClick={removeFilters}
            >
              {t('startups.RemoveFilters')}
            </button>
          )}

          <div className="clients-list">
            <ul className="nav nav-tabs">
              <MenuItem
                faIcon={<FaUser />}
                updateTo="Veileder"
                menuText={t('sparks.Mentors')}
                onClick={updateClientTab}
                currentClientTab={currentClientTab}
              />
              <MenuItem
                faIcon={<FaBullhorn />}
                updateTo="Promo"
                menuText="Promo"
                onClick={updateClientTab}
                currentClientTab={currentClientTab}
              />
              <MenuItem
                faIcon={<FaStar />}
                updateTo="Leder"
                menuText={t('sparks.LeaderGroup')}
                onClick={updateClientTab}
                currentClientTab={currentClientTab}
              />
              <MenuItem
                faIcon={<FaDesktop />}
                updateTo="IT"
                menuText="IT"
                onClick={updateClientTab}
                currentClientTab={currentClientTab}
              />
              {hostname === 'localhost' || hostname === 'basen' ? (
                <MenuItem
                  faIcon={<FaDesktop />}
                  updateTo="Kvinneprosjektet"
                  menuText="Kvinneprosjektet"
                  onClick={updateClientTab}
                  currentClientTab={currentClientTab}
                />
              ) : (
                ''
              )}
              <MenuItem
                faIcon={<FaQuestion />}
                updateTo="Annet"
                menuText={t('startups.Other')}
                onClick={updateClientTab}
                currentClientTab={currentClientTab}
              />
              <MenuItem
                faIcon={<FaWheelchair />}
                updateTo="Alumni"
                menuText="Alumni"
                onClick={updateClientTab}
                currentClientTab={currentClientTab}
              />
            </ul>
          </div>
          <div className="tab-content">
            <div id="tab-1" className="tab-pane active">
              <div className="full-height-scroll">
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <tbody>{userTable}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    users: state.users,
    nav: state.nav,
    tags: state.tags,
  }))
)(ClientTabComponent);
