import React from 'react';
import { FaTag } from 'react-icons/fa';

export default function TagMenuItem({
  tagListName,
  handleChangeTab,
  currentTagsTab,
}) {
  return (
    <li
      key={tagListName}
      className={currentTagsTab === tagListName ? 'active' : ''}
      onClick={() => handleChangeTab(tagListName)}
    >
      <a data-toggle="tab">
        <FaTag /> {tagListName}{' '}
      </a>
    </li>
  );
}
