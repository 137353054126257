import React from 'react';

interface IDateCellStyling {
  row: any;
}

const DateCellStyling = ({ row }: IDateCellStyling) => {
  const startupRegistrationDate = row.original.date;
  const regDate = new Date(startupRegistrationDate);
  const formattedDate = `${regDate.getDate()}.${
    regDate.getMonth() + 1
  }.${regDate.getFullYear()}`;

  return formattedDate;
};

export default DateCellStyling;
