import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import AdminFrame from './AdminFrame';

function SetUserRegDate(props) {
  const { users, t } = props;

  const [valgtVeileder, setVeileder] = useState(null);
  const [valgtDato, setValgtDato] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [dateString, setDateString] = useState('');

  useEffect(() => {
    const tempList = users.filter((user) => user.createdAt === '10');
    setFilteredUsers(tempList);
  }, [users]);

  function handleVeilederChange(e) {
    setVeileder(e);
  }

  function handleDisplayDate(string) {
    // dd.mm.yyyy
    const dateList = string.split('.');
    const day = dateList[0];
    const month = dateList[1] - 1;
    const year = dateList[2];
    setDateString(new Date(year, month, day).getTime());
  }

  function handleValgtDato(e) {
    setValgtDato(e.target.value);
    handleDisplayDate(e.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    firebase
      .database()
      .ref(`users/${valgtVeileder.value}`)
      .update({
        createdAt: dateString,
      })
      .then(() => {
        console.log('Regdate change success', new Date(dateString));
      })
      .catch((err) => {
        console.log(`Error.${err}`);
      });
    setVeileder('');
  }

  return (
    <AdminFrame
      title="Endre veileders registreringsdato (midlertidig funksjon)"
      content={
        <div>
          <p>
            Fra jul ble nye veiledere satt med verdien '10'. Bruk denne
            funksjonen for å sette verdien til noe nærmere deres faktiske
            registreringsdato.
          </p>
          <form className="m-t" onSubmit={onSubmit}>
            <div className="form-group">
              <h4>Veileder</h4>
              <Select
                name="veileder-input"
                value={valgtVeileder}
                options={filteredUsers.map((user) => ({
                  value: user.uid,
                  label: `${user.firstname} ${user.lastname}`,
                  createdAt: user.createdAt,
                }))}
                onChange={handleVeilederChange}
              />
            </div>
            <div className="form-group">
              <h4>Registreringsdato :</h4>
              <input
                name="regdate"
                value={valgtDato}
                placeholder="day.month.yyyy"
                onChange={handleValgtDato}
              />
              <p>
                Nåværende:{' '}
                {valgtVeileder
                  ? new Date(valgtVeileder.createdAt).toString()
                  : ''}
              </p>
              <p>Ny verdi: {new Date(dateString).toString()}</p>
            </div>
            <button
              className="btn btn-primary block full-width m-b"
              type="submit"
              disabled={
                valgtVeileder === '' ||
                new Date(dateString).toString() === 'Invalid Date'
              }
            >
              {t('startups.Save')}
            </button>
          </form>
        </div>
      }
    />
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
  }))
)(SetUserRegDate);
