import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const requestLackingInfoData = () => ({
  type: 'REQUEST_LACKINGINFO_DATA',
});

const lackingInfoDataFetchedSuccessfully = (payload) => ({
  type: 'LACKINGINFO_DATA_FETCHED_SUCCESSFULLY',
  payload,
});

const lackingInfoFetchError = (error) => ({
  type: 'LACKINGINFO_DATA_FETCH_ERROR',
  error,
});

function fetchLackingInfo() {
  return (dispatch) => {
    dispatch(requestLackingInfoData());
    firebase
      .database()
      .ref('/other/lackinginfo')
      .on('value', (snapshot) => {
        dispatch(lackingInfoDataFetchedSuccessfully(snapshot.val()));
      });
  };
}

export default fetchLackingInfo;
