import React from 'react';
import { useTranslation } from 'react-i18next';
import ListAdmins from './ListAdmins';
import AdminFrame from './AdminFrame';
import IUser from '../../types/IUser';

interface IAboutAdmin {
  userArray: Array<IUser>;
}

const AboutAdmin = ({ userArray }: IAboutAdmin) => {
  const { t } = useTranslation('common');

  return (
    <AdminFrame
      title="Admin"
      content={
        <div>
          <p>{t('admin.OnlyItCanChangeValuesInAdminPanel')}:</p>
          <ListAdmins userArray={userArray} />
        </div>
      }
    />
  );
};

export default AboutAdmin;
