import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const requestTagsData = () => ({
  type: 'REQUEST_TAGS_DATA',
});

const tagsDataFetchedSuccessfully = (payload) => ({
  type: 'TAGS_DATA_FETCHED_SUCCESSFULLY',
  payload,
});

const tagsDataFetchError = (error) => ({
  type: 'TAGS_DATA_FETCH_ERROR',
  error,
});

function fetchTagsData() {
  return (dispatch) => {
    dispatch(requestTagsData());
    firebase
      .database()
      .ref('tags')
      .on('value', (snapshot) => {
        dispatch(tagsDataFetchedSuccessfully(snapshot.val()));
      });
  };
}

export default fetchTagsData;
