import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loginError } from '../../redux/actions/auth';
import { Link, useNavigate } from 'react-router-dom';
import hostname from '../../config/hostname';
import { verifyRegistrationCode } from './verifyRegistrationCode';
import { createUser } from './createUser';

function SignupComponent(props) {
  const { t } = useTranslation('common');
  const { dispatch, auth, history } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [appendedDomain, setAppendedDomain] = useState('@sparkntnu.no');
  const [endpoint, setEndpoint] = useState('sparkntnu');

  useEffect(() => {
    // Host specific settings. SparkNTNU settings are default.
    if (hostname === 'sparknord') {
      setAppendedDomain(''); // Should be @sparknord when done, but '' for testing purposes
      setEndpoint('sparknord');
    }
  }, []);

  const navigate = useNavigate();
  
  const onSubmit = async (e) => {
    e.preventDefault();
    const fetchResponse = await verifyRegistrationCode(code, endpoint);
    // Cancel submit if server gives error
    if (fetchResponse.status !== 200) {
      alert('Sjekk av koden feilet. Prøv igjen senere eller hør med IT.');
      return;
    }
    // Data is a boolean. True if correct registration code.
    // ... False if wrong code.
    if (!fetchResponse.data) {
      alert(t('signup.ErrorWrongCode'));
    } else {
      const fullEmail = email + appendedDomain;
      createUser(fullEmail, password, dispatch, loginError, history);
      alert(
        'Vellykket registrering av bruker. ' +
          'Sjekk mail.one.com for verifisering av epost.'
      );
    }
  };

  return (
    <div className="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <h3>{t('signup.Registration')}</h3>
        <p>{t('signup.RegistrationDescription')}.</p>
      </div>
      <form className="m-t" onSubmit={onSubmit}>
        <div className="form-group">
          <label className="pull-left">{t('startups.Email')}</label>
          <div className="input-group m-b">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('startups.Email')}
              required
            />
            {appendedDomain !== '' && (
              <div className="input-group-prepend">
                <div className="input-group-text">{appendedDomain}</div>
              </div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="pull-left">{t('signup.Password')}</label>
          <input
            type="password"
            className="form-control"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="*******"
            required
          />
        </div>

        <div className="form-group">
          <label className="pull-left">{t('signup.Code')}</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => setCode(e.target.value)}
            placeholder={t('signup.Code')}
            required
          />
          <p>{t('signup.ContactItForCode')}</p>
        </div>

        <button
          className="btn btn-primary block full-width m-b"
          type="submit"
          disabled={auth.loading}
        >
          {t('signup.Register')}
        </button>
        <p className="text-muted text-center">
          <small>{t('signup.DoYouHaveAUser')}?</small>
        </p>
        <Link className="btn btn-sm btn-white btn-block" to="/auth/login">
          {t('signup.LogIn')}
        </Link>
      </form>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(SignupComponent);
