import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  FaCog,
  FaUsers as FaGroup,
  FaRocket,
  FaSignOutAlt as FaSignOut,
  FaTag,
  FaThLarge,
  FaChartLine,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { logout } from '../../redux/actions/auth';

function LeftNavComponent(props) {
  const [loggedInUser, setUser] = useState('');
  const [profilePicUrl, setPic] = useState('');
  const [isAdmin, setAdmin] = useState(false);
  const { auth, nav, users, t } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (users.userList) {
      if (auth.uid) {
        const { firstname, lastname, profilePicUrl, position } = users.userList[
          auth.uid
        ];
        setUser(`${firstname} ${lastname}`);
        setPic(profilePicUrl);
        setAdmin(position === 'IT');
      }
    }
  }, [users.userList, auth.uid]);

  function loggingOut() {
    const { dispatch, history } = props;
    dispatch(logout());
    firebase.auth().signOut();
    navigate('auth/login');
  }

  let adminOptions = [];
  if (isAdmin) {
    adminOptions = [
      <ul
        key={123}
        className="nav nav-second-level collapse in"
        aria-expanded="true"
      >
        <li className={nav.currentView === 'tagsadmin' ? 'active ' : ''}>
          <Link to="/tagsadmin">
            <FaTag />
            <span className="nav-label m-l-xs">Tags Admin</span>{' '}
          </Link>
        </li>
      </ul>,
    ];
  }

  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element">
              <span>
                <img
                  alt="profile"
                  className="img-circle img-circle-nav"
                  src={profilePicUrl}
                />
              </span>
              <Link to={`/veiledere/${auth.uid}`} className="dropdown-toggle">
                <span className="clear">
                  {' '}
                  <span className="block m-t-xs">
                    {' '}
                    <strong className="font-bold">{loggedInUser}</strong>
                  </span>{' '}
                  <span className="text-muted text-xs block">
                    {t('nav.MyProfile')}
                  </span>{' '}
                </span>
              </Link>
            </div>
          </li>
          <li className={nav.currentView === 'dashboard' ? 'active' : ''}>
            <Link to="/dashboard">
              <FaThLarge /> <span className="nav-label m-l-xs">Dashboard</span>
            </Link>
          </li>
          <li className={nav.currentView === 'startups' ? 'active' : ''}>
            <Link to="/startups">
              <FaRocket /> <span className="nav-label m-l-xs">Startups</span>{' '}
            </Link>
          </li>
          <li className={nav.currentView === 'veiledere' ? 'active' : ''}>
            <Link to="/veiledere">
              <FaGroup />{' '}
              <span className="nav-label m-l-xs">{t('nav.Sparks')}</span>{' '}
            </Link>
          </li>
          <li className={nav.currentView === 'statistikk' ? 'active' : ''}>
            <Link to="/statistikk">
              <FaChartLine />{' '}
              <span className="nav-label m-l-xs">{t('nav.Statistics')}</span>{' '}
            </Link>
          </li>
          <li className={nav.currentView === 'admin' ? 'active' : ''}>
            <Link to="/admin">
              <FaCog /> <span className="nav-label m-l-xs">Admin</span>{' '}
            </Link>
            {adminOptions}
          </li>
          <li style={{ paddingTop: '40px' }}>
            <a onClick={() => loggingOut()}>
              <FaSignOut /> {t('nav.LogOut')}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
    nav: state.nav,
    users: state.users,
  }))
)(LeftNavComponent);
