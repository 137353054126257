import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { FaTag } from 'react-icons/fa';
import { BsXCircleFill } from 'react-icons/bs';

function ProfileTags(props) {
  const { handleParentValues, chosenTags, tagOptions } = props;
  const [startupTags, setStartupTags] = useState(chosenTags || []); // byttes med userTags
  const [tagLabels, setTagLabels] = useState([]);

  useEffect(() => {
    if (chosenTags) {
      // If user has set tags as object instead of as list of objects {label, value}
      if (Object.keys(chosenTags).includes('label')) {
        setStartupTags([chosenTags]);
      } else {
        setStartupTags(chosenTags);
      }
    }
  }, [chosenTags]);

  function makeTagLabels() {
    const newTagLabels = [];
    Object.keys(startupTags).map((key) =>
      newTagLabels.push(
        <li key={key}>
          <a disabled onClick={handleTagDelete}>
            <FaTag /> {startupTags[key].label} <BsXCircleFill />
          </a>
        </li>
      )
    );
    return newTagLabels;
  }

  useEffect(() => {
    setTagLabels(makeTagLabels());
  }, [startupTags]);

  function handleTagDelete(event) {
    if (startupTags.length === 1) {
      return null;
    }
    const tagText = event.target.textContent.trim();
    const newTagList = startupTags
      .filter((tag) => tag.label !== tagText)
      .map((tag) => tag);
    setStartupTags(newTagList);
    handleParentValues(newTagList);
  }

  function handleTagChange(t) {
    if (startupTags.some((tag) => tag.label === t.label)) {
      return null;
    }
    const newTagList = startupTags.map((tag) => tag);
    newTagList[Object.keys(newTagList).length] = t;
    setStartupTags(newTagList);
    handleParentValues(newTagList);
  }

  return (
    <div className="my-1">
      <Select
        name="tag-input"
        value={startupTags.tags}
        options={tagOptions}
        multi
        onChange={handleTagChange}
      />
      <div className="col-sm-12" style={{ paddingTop: '10px' }}>
        <ul className="tag-list" style={{ padding: 0 }}>
          {tagLabels}
        </ul>
      </div>
    </div>
  );
}

export default ProfileTags;
