import Papa from 'papaparse';

function exportToCsv(data, delimiter, setDownloading) {
  setDownloading(true);
  const csv = Papa.unparse(data, {
    encoding: 'UTF-8',
    delimiter,
  });
  const a = document.createElement('a');
  a.href = `data:attachment/csv,${encodeURIComponent(csv)}`;
  a.target = '_blank';
  a.download = 'spark.csv';
  document.body.appendChild(a);

  a.click();
  setTimeout(() => {
    setDownloading(false);
  }, 1000);
}

export default exportToCsv;
