/* 

  Defines the table structure

  Important:
    - "Cell"-property defines the costum styling shown in the table cells
    - disableFilters: true removes filter UI components from the <th> itself

*/

// Cell styling
import NameCellStyling from './cell/styling/Name';
import PhaseCellStyling from './cell/styling/Phase';
import StatusCellStyling from './cell/styling/Status';
import MentorCellStyling from './cell/styling/Mentor';
import Tags from './cell/styling/Tags';
import DateCellStyling from './cell/styling/Date';

// Filters
import HeaderSelectFilter from './tableHeaderFilter/HeaderSelectFilter';
import HeaderInputFilter from './tableHeaderFilter/HeaderInputFilter';
import TagsSelectFilter from './filter/filters/TagsSelectFilter';
import subArrayExistsInArray from '../../utils/contains';
import DateFilter from './filter/filters/DateFilter';

export const columnsStructure = [
  {
    Header: 'Status',
    Cell: StatusCellStyling,
    accessor: (row: any) => row.status.statusLabel,
    id: 'statusLabel',
    Filter: HeaderSelectFilter,
  },
  {
    Header: 'Navn',
    Cell: NameCellStyling,
    accessor: (row: any) => row.name.name,
    id: 'name',
    Filter: HeaderInputFilter,
  },
  {
    Header: 'Fase',
    Cell: PhaseCellStyling,
    accessor: (row: any) => row.phase.phaseLabel,
    id: 'phaseLabel',
    Filter: HeaderSelectFilter,
  },
  {
    Header: 'Veileder',
    Cell: MentorCellStyling,
    accessor: (row: any) => row.supervisor.supervisorName,
    id: 'supervisorName',
    Filter: HeaderInputFilter,
  },
  {
    Header: 'Bransje',
    accessor: 'field',
    Filter: HeaderSelectFilter,
  },
  {
    Header: 'Opprettet',
    Cell: DateCellStyling,
    accessor: (row: any) => row.date,
    id: 'dateRegistered',
    Filter: DateFilter,
    filter: (rows: any, id: any, filterValue: Date[]) => {
      return rows.filter((row: any) => {
        const dateRegistered = row.values[id];

        // Determine if startup date is between start date and end date specified by date filter
        if (
          filterValue[0] <= new Date(dateRegistered) &&
          filterValue[1] >= new Date(dateRegistered)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    Filter: TagsSelectFilter,
    /* Responsible for returing true if selected filter values is included in row values */
    filter: (rows: any, id: any, filterValue: any) => {
      return rows.filter((row: any) => {
        const rowValue = row.values[id];
        let rowValueTagLabels = [];

        // Skip tags which have incorrect format, such as a number
        try {
          rowValueTagLabels = rowValue.map((r: any) => r.label);
        } catch {}

        // Display row if selected tags appear in table cell
        return rowValue !== undefined
          ? subArrayExistsInArray(filterValue, rowValueTagLabels)
          : true;
      });
    },
    Cell: Tags,
  },
];
