import React from 'react';

interface IWarningComponent {
  title: string;
  content: string;
}

const WarningComponent = ({ title, content }: IWarningComponent) => {
  return (
    <div className="alert alert-danger" role="alert">
      <h3>{title}</h3>
      {content}
    </div>
  );
};

export default WarningComponent;
