import React, { useMemo } from 'react';
import { Column, useTable, useFilters, useSortBy } from 'react-table';
import { ITableData } from '../../../containers/StartupTableContainer';
import TableFilter from './filter/TableFilter';
import { columnsStructure } from './tableStructure';

interface IStartupTable {
  tableData: ITableData[];
  showFilters: boolean;
  title: string;
}

const StartupTable = ({ tableData, showFilters, title }: IStartupTable) => {
  /* Populate table with data from StartupTableContainer */
  const data: ITableData[] = useMemo(() => tableData, []);

  /* Generate columns based on columnsStructure */
  const columns: any[] = useMemo(() => columnsStructure, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'statusLabel',
            desc: false,
          },
          {
            id: 'dateRegistered',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="ibox">
            <div className="ibox-title">
              <h5>{title}</h5>
            </div>

            {showFilters ? (
              <div className="ibox-content">
                <TableFilter
                  activeFilters={state.filters}
                  setFilter={setFilter}
                />
              </div>
            ) : null}

            {/* <ChosenFilters activeFilters={state.filters} setFilter={setFilter} /> */}
            <div className="ibox-content">
              <table {...getTableProps()} className="table table-hover">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupTable;
