import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import feedback from './feedback';
import { feedbackMessages } from '../../constants';

const requestLackingInfoUpdate = () => ({
  type: 'REQUEST_LACKINGINFO_UPDATE',
});

const lackingInfoUpdateSuccess = () => ({
  type: 'UPDATE_LACKINGINFO_SUCCESS',
});

const lackingInfoUpdateError = (error) => ({
  type: 'UPDATE_LACKINGINFO_ERROR',
  error,
});

function lackingInfoUpdate(trackedQuestionsStartup, trackedQuestionsIdehaver) {
  return (dispatch) => {
    dispatch(requestLackingInfoUpdate());
    const updates = {};
    updates['/other/lackinginfo/startup'] = trackedQuestionsStartup;
    updates['/other/lackinginfo/idehaver'] = trackedQuestionsIdehaver;
    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        feedback.success(feedbackMessages.lackingInfoUpdateSuccess);
        dispatch(lackingInfoUpdateSuccess());
      })
      .catch((err) => {
        feedback.error(err);
        dispatch(lackingInfoUpdateError(err.message));
      });
  };
}

export default lackingInfoUpdate;
