import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function TronderEnergiSokereAllYears(props) {
    const { sokere, sokt, years } = props;

    const soktBelopChart = {
        exporting: {
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'center',
                verticalAlign: 'bottom',
                y: 40,
            },
        },
        title: {
            text: `Søkt beløp`,
            crosshair: true
        },
        xAxis: {
            categories: years,
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0
            }
        },
        series: [
            {
                type: 'column',
                name: "Søkt støtte",
                data: sokt
            },
        ],
    };

    const sokereChart = {
        exporting: {
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'center',
                verticalAlign: 'bottom',
                y: 40,
            },
        },
        title: {
            text: `Antall søkere`,
            crosshair: true
        },
        xAxis: {
            categories: years,
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0
            }
        },
        series: [
            {
                type: 'line',
                name: "Søkere",
                data: sokere
            },
        ],
    };

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType="chart"
                options={soktBelopChart}
            />

            <HighchartsReact
                highcharts={Highcharts}
                constructorType="chart"
                options={sokereChart}
            />
        </>
    );
}

export default withTranslation('common')(TronderEnergiSokereAllYears);
