import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function IdehavereKjonn(props) {
  const { idehavere, t } = props;

  const idehaverKjonnChart = {
    chart: {
      type: 'pie',
    },
    title: {
      text: "Idehavere, total for alle år",
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [
      {
        name: 'Brands',
        colorByPoint: true,
        data: [
          {
            name: t('startups.Woman'),
            y: Object.values(idehavere.idehaverList).filter((x) => x.sex === 'f').length
          },
          {
            name: t('startups.Man'),
            y: Object.values(idehavere.idehaverList).filter((x) => x.sex === 'm').length
          },
        ],
      }]
  };

  return (
    <div className="col-lg-6 mt-4 mb-4" >
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        options={idehaverKjonnChart}
      />
    </div >
  );
}

export default withTranslation('common')(IdehavereKjonn);
