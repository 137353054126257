import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import hostname from './hostname';

/* 

NTNU
Used for production

*/
export const sparkntnu_develop = {
  apiKey: 'AIzaSyBobNjhux8IvYyDT_f9zgrlJvvD6fdZLSM',
  authDomain: 'sparkntnu-develop.firebaseapp.com',
  databaseURL: 'https://sparkntnu-develop.firebaseio.com',
  projectId: 'sparkntnu-develop',
  storageBucket: 'sparkntnu-develop.appspot.com',
  messagingSenderId: '259746264346',
  appId: '1:259746264346:web:47cc5da1c483db1da2a8a3',
};

/* 

NTNU
Used for development and testing

*/
export const sparkntnu_prod = {
  apiKey: 'AIzaSyD8Qi3AOPLifeUq-LUraDfFNbitOdvkrLM',
  authDomain: 'sparkntnu-prod.firebaseapp.com',
  databaseURL: 'https://sparkntnu-prod.firebaseio.com',
  storageBucket: 'sparkntnu-prod.appspot.com',
  messagingSenderId: '259746264346',
};

/* 

Nord University
Used for production

*/
export const sparknord_prod = {
  apiKey: 'AIzaSyAmsspfeMyJ9j5ZskOcPpn0bPPBOwHKDg8',
  authDomain: 'sparknord-prod.firebaseapp.com',
  databaseURL: 'https://sparknord-prod.firebaseio.com',
  projectId: 'sparknord-prod',
  storageBucket: 'sparknord-prod.appspot.com',
  messagingSenderId: '328843942488',
  appId: '1:328843942488:web:ee37250a7410dc989c482d',
};

// Used for checking English version is up to date
export const spark_english = {
  apiKey: 'AIzaSyA88ZHfh1lNOhHKyszDK0Hjdx9Hak5oTDY',
  authDomain: 'spark-english.firebaseapp.com',
  databaseURL: 'https://spark-english-default-rtdb.firebaseio.com',
  projectId: 'spark-english',
  storageBucket: 'spark-english.appspot.com',
  messagingSenderId: '331378262461',
  appId: '1:331378262461:web:55879d5a83256700a6d2d4',
};

const host = hostname;

export const assignConfig = (host) => {
  console.log('Hostname:', host);

  if (host === 'sparknord') {
    console.log('Kjører config sparknord_prod');
    return sparknord_prod;
  }

  if (host === 'basen') {
    console.log('Kjører config sparkntnu_develop');
    return sparkntnu_develop;
  }

  if (host === 'localhost') {
    console.log('Kjører config sparkntnu_prod');
    return sparkntnu_prod;
  }

  if (host === 'spark-english') {
    console.log('Kjører config spark_english');
    return spark_english;
  }
};

// In production, use `firebase.initializeApp(config)`.
// For testing purposes, you can change `config` to a specific config for testing, e.g. `sparkntnu_prod`.
firebase.initializeApp(assignConfig('basen'));
