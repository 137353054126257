import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import IUser from '../../types/IUser';

/*

Note, veilederId is not stored on the user object itself

Rather the structure, as seen in Firebase, is as follows

veilederId: {
  user_data1
  user_data2
  ...
}

*/

const updateCapacity = (veilederId: string, user: IUser, ammount: number) => {
  // 1 to increment, and -1 to decrement
  if (!(user.capacity + ammount < 0 || user.capacity + ammount > 35)) {
    firebase
      .database()
      .ref('users')
      .child(veilederId)
      .update({
        capacity: user.capacity + ammount,
      });
  }
};

export default updateCapacity;
