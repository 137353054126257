function msToFormattedDate(dateMS) {
  if (!dateMS) return null;
  const date = new Date(dateMS);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}.${month}.${day}`;
}

export default msToFormattedDate;
