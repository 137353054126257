import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  loginError,
  loginRequest,
  loginSuccess,
} from '../../redux/actions/auth';
import ResetPasswordComponent from './ResetPasswordComponent';
import hostname from '../../config/hostname';

const LoginComponent = ({ dispatch, auth, t }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetPasswordView, setResetPasswordView] = useState(false);
  const [appendedDomain, setAppendedDomain] = useState('@sparkntnu.no');

  useEffect(() => {
    if (hostname === 'sparknord' || hostname === 'localhost') {
      setAppendedDomain('');
    }
  }, []);

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    const fullEmail = email + appendedDomain;
    dispatch(loginRequest());
    firebase
      .auth()
      .signInWithEmailAndPassword(fullEmail, password)
      .then((user) => {
        if (user.user.emailVerified) {
          dispatch(loginSuccess(user.user.uid));
          navigate('/dashboard');
        } else {
          document.getElementById('verifyEmailAlert').style.display = 'block';
          user.user
            .sendEmailVerification()
            .then(() => {
              console.log('Sent verification mail');
            })
            .catch((error) => {
              console.log(
                'Tried sending email verification, but something failed',
                error
              );
            });
        }
      })
      .catch((error) => {
        dispatch(loginError(error.message));
        document.getElementById('wrongLogin').style.display = 'block';
      });
  };

  const changeResetPasswordView = () => {
    setResetPasswordView(!resetPasswordView);
  };

  if (resetPasswordView) {
    return (
      <ResetPasswordComponent
        changeResetPasswordView={changeResetPasswordView}
      />
    );
  }

  return (
    <div className="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <h3>{t('signup.WelcomeToSparkBasen')}.</h3>
        <p>{t('signup.BasenDescription')}</p>
        <p>
          {t('signup.LogInWithYourSparkUser')}. {t('signup.ProblemsAskInSlack')}
        </p>
      </div>
      <form className="m-t" onSubmit={onSubmit}>
        <div className="form-group">
          <div className="input-group m-b">
            <input
              type="text"
              className="form-control"
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('startups.Email')}
              required
            />
            {appendedDomain !== '' && (
              <div className="input-group-prepend">
                <div className="input-group-text">{appendedDomain}</div>
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="*******"
            required
          />
        </div>
        <div className="alert alert-danger" role="alert" id="wrongLogin">
          {t('signup.WrongLogin')}
        </div>
        <div
          className="alert alert-danger"
          role="alert"
          id="verifyEmailAlert"
        >
          {t('signup.EmailNotVerified')}
        </div>
        <button
          className="btn btn-primary block full-width m-b"
          type="submit"
          disabled={auth.loading}
        >
          {t('signup.LogIn')}
        </button>
        <p className="text-muted text-center">
          <small>{t('signup.NoUserYet')}?</small>
        </p>
        <Link to="/auth/signup" className="btn btn-sm btn-white btn-block">
          {t('signup.RegisterHere')}
        </Link>

        <br />
        <p className="text-muted text-center">
          <small>{t('signup.ForgotPassword')}?</small>
          <a href="#reset" className="font-bold" onClick={changeResetPasswordView}>
            {' '}
            {t('signup.ClickHere')}
          </a>
        </p>
      </form>
    </div>
  );
};

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
  }))
)(LoginComponent);
