import React from 'react';
import hasAdminAccess from '../utils/hasAdminAccess';
import IUser from '../../types/IUser';

interface IListAdmins {
  userArray: Array<IUser>;
}

export default function ListAdmins({ userArray }: IListAdmins) {
  const listAdmins = userArray.filter((user) => hasAdminAccess(user));

  return (
    <ul>
      {listAdmins.map((user) => (
        <li key={user.uid}>
          {`${user.firstname} ${user.lastname} : (${user.sparkMail})`}
        </li>
      ))}
    </ul>
  );
}
