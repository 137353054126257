import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useNavigate } from 'react-router';
// const navigate = useNavigate();
export const createUser = (
  fullEmail: string,
  password: string,
  dispatch: any,
  loginError: (error: any) => {
    type: string;
    error: any;
  },
  history: any
) => {
  firebase
    .auth()
    .createUserWithEmailAndPassword(fullEmail, password)
    .then((user: any) => {
      user.user
        .sendEmailVerification()
        .then(() => {
          console.log('Verification email sent.');
        })
        .catch((error: any) => {
          console.log(
            'Tried sending email verification, but something failed',
            error
          );
        });
        //TODO: Fix
      // navigate('/auth/login');
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    })
    .catch((err) => {
      console.log(err.message);
      dispatch(loginError(err.message));
    });
};
