import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FaTag } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import PageHeader from '../components/common/PageHeader';
import { X } from 'react-feather';
import MeetingNoteV2 from './MeetingNoteV2.tsx';
import { updateCurrentView } from '../redux/actions/nav';
import fetchStartupData from '../redux/actions/startups';
import fetchTeamMembersData from '../redux/actions/teamMembers';
import fetchTagsData from '../redux/actions/tags';
import fetchUsersData from '../redux/actions/users';

import LoadingSquare from '../components/common/LoadingSquare';
import StartupProfileWidget from '../components/startup/StartupProfileWidget.tsx';
import StartupMeetingNotes from '../components/startup/StartupMeetingNotes/StartupMeetingNotes';
import { teams } from '../components/dashboard/TeamMeetingNotes.tsx';
import '../css/Main.css';

function StartupProfileContainer(props) {
  const {
    dispatch,
    auth,
    startups,
    users,
    idehavere,
    teammembers,
    startupId,
    t,
  } = props;

  const [addMeetingNote, setAddMeetingNote] = useState(false);

  useEffect(() => {
    /*
    Sjekker om startup i dette tilfellet brukes for å lagre
    møtenotater for en av undergruppene i Spark*
    */
    /* if (teams.find((team) => team.address.includes(startupId))) {
      setIsASubGroupInSpark(true);
    } */

    dispatch(updateCurrentView('startups'));
    dispatch(fetchStartupData());
    dispatch(fetchUsersData());
    dispatch(fetchTeamMembersData());
    dispatch(fetchTagsData());
  }, []);

  if (
    !startups.startupList ||
    !users.userList ||
    !idehavere.idehaverList ||
    !teammembers.teamList
  ) {
    return (
      <div>
        <PageHeader view="Startups" subview={t('startups.Error')} />
        <LoadingSquare />
      </div>
    );
  }
  const startup = startups.startupList[startupId];
  const idehaver = idehavere.idehaverList[startup.idehaver];
  const tagLabels = [];
  for (const key in startup.tags) {
    tagLabels.push(
      <li key={key}>
        <a disabled>
          <FaTag /> {startup.tags[key].label}
        </a>
      </li>
    );
  }

  const teamMemberIDs = startup.teammedlemmer || {};
  const { teamList } = teammembers;

  const teamMembers = Object.values(teamMemberIDs).map((value) => {
    const teamMember = teamList[value];
    teamMember.uid = value;
    return teamMember;
  });

  let percent = startup.fase.value.toString();
  percent = `${percent}%`;
  const progressBarStyle = { width: percent };
  let meetingNotes = [];
  if (startup.meetingNotes) {
    meetingNotes = Object.keys(startup.meetingNotes).map((key) => {
      const note = startup.meetingNotes[key];
      note.id = key;
      return note;
    });
  } else {
    meetingNotes = ['No notes'];
  }

  let projectStatus = [];
  if (startup.status.label === 'Død') {
    projectStatus = [
      <li>
        <a style={{ fontSize: '25px' }} className="active-tag" disabled>
          Død
        </a>
      </li>,
    ];
  } else if (startup.status.label === 'Aktiv') {
    projectStatus = [
      <li>
        <a style={{ fontSize: '25px' }} className="active-tag" disabled>
          Aktiv
        </a>
      </li>,
    ];
  } else if (startup.status.label === 'Alumni') {
    projectStatus = [
      <li>
        <a style={{ fontSize: '25px' }} className="active-tag" disabled>
          Alumni
        </a>
      </li>,
    ];
  } else {
    projectStatus = [
      <li>
        <a style={{ fontSize: '25px' }} className="active-tag" disabled>
          Dvale
        </a>
      </li>,
    ];
  }

  return (
    <div>
      <PageHeader view="Startups" subview={startup.ideNavn} />

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          {addMeetingNote ? (
            <>
              <div className="col-xs-11">
                <MeetingNoteV2
                  startupID={startupId}
                  setAddMeetingNote={setAddMeetingNote}
                />
              </div>
              <div className="col-xs-1">
                <button
                  className="cancelMeetingNoteDraftButton"
                  onClick={() => setAddMeetingNote(false)}
                >
                  <X />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-7">
                <StartupMeetingNotes
                  currentUser={auth.uid}
                  startupId={startupId}
                  usersObj={users.userList}
                  meetingNotes={meetingNotes}
                  setAddMeetingNote={setAddMeetingNote}
                />
              </div>
              <div className="col-lg-5">
                <StartupProfileWidget
                  key={startupId}
                  projectStatus={projectStatus}
                  tagLabels={tagLabels}
                  teamMembers={teamMembers}
                  progressBarStyle={progressBarStyle}
                  startupId={startupId}
                  startup={startup}
                  usersObj={users.userList}
                  idehaver={idehaver}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    nav: state.nav,
    startups: state.startups,
    idehavere: state.idehavere,
    users: state.users,
    auth: state.auth,
    teammembers: state.teammembers,
    tags: state.tags,
  }))
)(StartupProfileContainer);
