import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const requestTeamMembersData = () => ({
  type: 'REQUEST_TEAMMEMBERS_DATA',
});

const teamMembersDataFetchedSuccessfully = (payload) => ({
  type: 'TEAMMEMBERS_DATA_FETCHED_SUCCESSFULLY',
  payload,
});

const teamMembersFetchError = (error) => ({
  type: 'TEAMMEMBERS_DATA_FETCH_ERROR',
  error,
});

function fetchTeamMembersData() {
  return (dispatch) => {
    dispatch(requestTeamMembersData());
    firebase
      .database()
      .ref('teammembers')
      .on('value', (snapshot) => {
        dispatch(teamMembersDataFetchedSuccessfully(snapshot.val()));
      });
    // .catch((err) => {
    //   console.log(err.message)
    //   dispatch(teamMembersFetchError(err.message))
    // })
  };
}

export default fetchTeamMembersData;
