import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhaseComponent from '../PhaseComponent';
import NewStartupLabel from './NewStartupLabel';
import { IStartup } from '../../../types/IStartup';

interface IStartupMiniComponent {
  startup: IStartup;
}

function StartupMiniComponent({ startup }: IStartupMiniComponent) {
  const { t } = useTranslation('common');

  const tagLabels = Object.values(startup.tags).map((value) => (
    <li key={value.label}>
      <a>
        <i className="fa fa-tag" />
        {value.label}
      </a>
    </li>
  ));

  const [longText, setLongText] = useState(startup.ideBeskrivelse.length > 280);
  let shownStartupInfo;
  let showMoreButton;

  if (!longText) {
    shownStartupInfo = startup.ideBeskrivelse;
    showMoreButton = '';
  } else {
    shownStartupInfo = `${startup.ideBeskrivelse.substr(0, 267)} ... `;
    showMoreButton = (
      <a className="font-weight-bold" onClick={() => setLongText(false)}>
        {t('startups.ShowMore')}
      </a>
    );
  }

  return (
    <div className="col-sm-4">
      <div className="ibox">
        <div className="ibox-title">
          <NewStartupLabel registreringsDato={startup.registreringsDato} />
          <h5>
            <Link to={`/startups/${startup.id}`}>{startup.ideNavn}</Link>{' '}
          </h5>
        </div>
        <div className="ibox-content">
          <div className="team-members">
            <div className="logo-center-small img-responsive">
              <Link to={`/startups/${startup.id}`}>
                <img alt="startup logo" src={startup.logoUrl} />
              </Link>
            </div>
          </div>
          <h4>{t('startups.About')}:</h4>
          <p>
            {shownStartupInfo}
            {showMoreButton}
          </p>
          <div className="p-sm">
            <PhaseComponent phase={startup.fase} />
          </div>
          <div className="row">
            <div className="text-center">
              <div className="font-bold m-t-sm">{t('startups.Industry')}:</div>
              {startup.bransje.label}
            </div>
          </div>
          <div className="row project-manager">
            <ul className="tag-list" style={{ padding: 0 }}>
              {tagLabels}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartupMiniComponent;
