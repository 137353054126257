import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import MeetingNotesByYear from './MeetingNotesByYear';

function MeetingNotesAllYears(props) {
  const years = {};
  const { startups, t } = props;

  const [selectedYear, setSelectedYear] = useState();
  const [clicked, setClicked] = useState(false);

  const changeView = () => {
    setClicked(!clicked);
  }

  function isObject(variable) {
    return typeof variable === 'object' && variable !== null;
  }

  Object.values(startups).map((startup) => {
    if (isObject(startup.meetingNotes)) {
      Object.values(startup.meetingNotes).map((note) => {
        const date = new Date(note.postedOn);
        if (date.getFullYear()) {
          if (years[date.getFullYear()] == null) {
            years[date.getFullYear()] = 1;
          } else {
            years[date.getFullYear()] += 1;
          }
        }
      })
    }
  });

  const series = [];
  const categories = [];
  Object.keys(years).map((year) => {
    // Add each year to category and number of startups to series to use in chart
    categories.push(year)
    series.push(years[year])
  });

  const meetingNotesChart = {
    chart: {
      type: 'line',
    },
    title: {
      text: t('startups.MeetingNotes'),
    },
    xAxis: {
      categories: categories,
    },
    series: [{
      name: "Antall notater",
      data: series
    }],
    plotOptions: {
      series: {
        point: {
          events: {
            click: e => {
              setSelectedYear(parseInt(e.point.category));
              changeView();
            }
          }
        }
      }
    },
  };

  return (
    <>
      {clicked ?
        <>
          <MeetingNotesByYear startups={startups} year={selectedYear} />
          <button
            onClick={() => changeView()}
            className="btn btn-primary block m-b mt-2"
          >
            Tilbake
          </button>
        </>
        :
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="chart"
          options={meetingNotesChart}
        />
      }
    </>
  );
}

export default withTranslation('common')(MeetingNotesAllYears);
