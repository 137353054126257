import React from 'react';
import { FieldErrors } from 'react-hook-form';

interface IProps {
  errors: FieldErrors;
  inputName: string;
}

const InputErrorMessage = ({ errors, inputName }: IProps) => {
  // If there are no errors for the certain input, don't show the component.
  if (!errors[inputName]) {
    return null;
  }
  return (
    <div className="alert alert-warning" role="alert">
      {errors[inputName]?.message}
    </div>
  );
};

export default InputErrorMessage;
