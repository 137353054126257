import * as Sentry from '@sentry/browser';
import { configureStore } from '@reduxjs/toolkit';
import reducers from './redux/reducers';
import {thunk} from 'redux-thunk';  // Ensure correct import without braces
import { Integrations } from '@sentry/tracing';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import i18next from 'i18next';
import React from 'react';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import 'regenerator-runtime/runtime';
import './config';
import hostname from './config/hostname';
import Root from './containers/Root';
import { loginSuccess, logout } from './redux/actions/auth';

import common_en from './translations/en/common.json';
import common_no from './translations/no/common.json';

require('./theme/css/bootstrap.min.css');
require('./theme/css/animate.css');
require('./theme/css/style.css');
require('./css/Main.css');

Sentry.init({
  dsn: 'https://ba09e095c7204fcdbe16dea60269818f@o114411.ingest.sentry.io/5918766',
  integrations: [new Integrations.BrowserTracing()],
  release: 'nyebasen@1.0.0',
  tracesSampleRate: 0.5,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

let lang = 'no';
if (hostname === 'spark-english') {
  lang = 'en';
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: lang,
  resources: {
    en: { common: common_en },
    no: { common: common_no },
  },
});

const unsub = firebase.auth().onAuthStateChanged(
  (user) => {
    if (user) {
      if (user.emailVerified) {
        store.dispatch(loginSuccess(user.uid));
      } else {
        store.dispatch(logout());
        firebase.auth().signOut();
      }
    }
    unsub();
    render(
      <I18nextProvider i18n={i18next}>
        <Root store={store} />
      </I18nextProvider>,
      document.getElementById('root')
    );
  },
  (error) => console.log(error)
);
