import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { withTranslation } from 'react-i18next';

function ResetPasswordComponent(props) {
  const [email, setEmail] = useState('');
  const [sentEmail, setSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { changeResetPasswordView, t } = props;

  function onSubmitResetPassword(e) {
    e.preventDefault();
    const auth = firebase.auth();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log('Email-sent');
        setSent(true);
      })
      .catch((error) => {
        console.log('Reset password error');
        console.log(error);
        setErrorMessage(error.message);
      });
  }

  if (sentEmail) {
    return (
      <div className="middle-box text-center loginscreen animated fadeInRight">
        <div>
          <h3>{t('signup.ResetPassword')}</h3>
          <p>{t('signup.ResetEmailSent')}!</p>
        </div>
        <button
          type="button"
          className="btn btn-white block full-width m-b"
          onClick={changeResetPasswordView}
        >
          {t('signup.Back')}
        </button>
      </div>
    );
  }
  if (errorMessage != null) {
    return (
      <div className="middle-box text-center loginscreen animated fadeInRight">
        <div>
          <h3>{t('signup.ResetPassword')}</h3>
          <hp>{t('signup.AnErrorOccured')}!</hp>
          <br />
          <p className="btn-outline btn-danger ">{errorMessage}</p>
        </div>
        <button
          type="button"
          className="btn btn-white block full-width m-b"
          onClick={changeResetPasswordView}
        >
          {t('signup.Back')}
        </button>
      </div>
    );
  }
  return (
    <div className="middle-box text-center loginscreen animated fadeInRight">
      <div>
        <h3>{t('signup.ResetPassword')}</h3>
        <p>{t('signup.ResetDescription')}.</p>
      </div>
      <form className="m-t" onSubmit={onSubmitResetPassword}>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Epost"
            required
          />
        </div>
        <button
          className="btn btn-primary block full-width m-b"
          type="submit"
          disabled={sentEmail}
        >
          {t('signup.ResetPassword')}
        </button>
      </form>
      <button
        type="button"
        className="btn btn-white block full-width m-b"
        onClick={changeResetPasswordView}
      >
        {t('signup.Back')}
      </button>
    </div>
  );
}

export default withTranslation('common')(ResetPasswordComponent);
