import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import request from 'superagent';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import hostname from '../config/hostname';
import updateProfileInfo from '../redux/actions/profile';
import ProfileTags from '../components/tags/ProfileTags';
import LoadingSquare from '../components/common/LoadingSquare';
import ProfilePictureDropzone from '../components/profile/ProfilePictureDropzone';
import { useParams, useNavigate } from 'react-router-dom';
require('../css/Dropzone.css');

let CLOUDINARY_UPLOAD_FINAL = 'vsoglafv'; // folder: profilePictures
if (
  hostname === 'localhost' ||
  hostname === 'sparknord' ||
  hostname === 'spark-english' ||
  hostname === 'kopibasenntnu'
) {
  CLOUDINARY_UPLOAD_FINAL = 'cz6zmrdn'; // folder: profilePicturessparknord
}
const CLOUDINARY_UPLOAD_PREVIEW = 'fh4ticsa';

const CLOUDINARY_UPLOAD_URL =
  'https://api.cloudinary.com/v1_1/sparkntnu/upload';
const CLOUDINARY_DELETE_ENDPOINT =
  'https://api.cloudinary.com/v1_1/sparkntnu/delete_by_token';

function EditProfileContainer(props) {
  const { veilederId } = useParams();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [about, setAbout] = useState('');
  const [position, setPosition] = useState('');
  const [sparkMail, setSparkMail] = useState('');
  const [alternativeMail, setAlternativeMail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [uid, setUid] = useState('');
  const [tagOptions, setTagOptions] = useState('');
  const [chosenTags, setChosenTags] = useState([]);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileCloudinaryUrl, setUploadedFileCloudinaryUrl] =
    useState('');
  const [token, setToken] = useState('');
  const [croppingClosed, setCroppingClosed] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
  };

  function cleanUp() {
    // Deletes the preview file
    const deleteMe = request
      .post(CLOUDINARY_DELETE_ENDPOINT)
      .field('token', token);

    setCroppingClosed(true);

    deleteMe.end((err, response) => {
      if (err) {
        console.error(err);
      } else {
        console.log('Deleted uploaded preview file.');
      }
    });
  }

  function cancelCrop(e) {
    e.preventDefault();
    setUploadedFile(null);
    cleanUp();
  }

  function confirmedCrop(e) {
    e.preventDefault();
    cleanUp();

    const cropImg = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL('image/jpeg');
    handleImageUpload(cropImg);
  }

  function onImageDrop(files) {
    setUploadedFile(files[0]);
    handleImageUpload(files[0]);
  }

  function handleImageUpload(file) {
    setUploadInProgress(true);

    let preset = CLOUDINARY_UPLOAD_PREVIEW;
    if (uploadedFileCloudinaryUrl) {
      preset = CLOUDINARY_UPLOAD_FINAL;
    }

    const upload = request
      .post(CLOUDINARY_UPLOAD_URL)
      .field('upload_preset', preset)
      .field('file', file)
      .field('image_id', { return_delete_token: 1 });

    // Getting feedback from Cloudinary
    upload.end((err, response) => {
      setUploadInProgress(false);

      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== '') {
        setUploadedFileCloudinaryUrl(response.body.secure_url);
        setProfilePicUrl(response.body.secure_url);
        setToken(response.body.delete_token);
      }
    });
  }

  useEffect(() => {
   console.log('EditProfileContainer useEffect');
   console.log('props.auth.uid', props.auth.uid);
   //props.params.veilederId is outdated, use useParams instead:
  
    console.log('veilederId', veilederId);
    if (props.auth.uid !== veilederId) {
      // This avoids users editing others' profiles.
      // Users can only edit their own profiles.
     // Old: props.history.push('/dashboard');
      const navigate = useNavigate();
      navigate('/dashboard');
    } else {
      if (!props.users.userList) {
        // Not fetched data, so redirected away from edit to user profile.
        // ... This avoids crash on refresh.
        //Rewrite this to use useNavigate instead of props.history.push
        //props.history.push(`/veiledere/${props.auth.uid}`);
        const navigate = useNavigate();
        navigate(`/veiledere/${props.auth.uid}`);
      } else {
        const { uid } = props.auth;
        setFirstname(props.users.userList[uid].firstname);
        setLastname(props.users.userList[uid].lastname);
        setAbout(props.users.userList[uid].about);
        setPosition(props.users.userList[uid].position);
        setSparkMail(props.users.userList[uid].sparkMail);
        setAlternativeMail(props.users.userList[uid].alternativeMail);
        setPhoneNumber(props.users.userList[uid].phoneNumber);
        setChosenTags(props.users.userList[uid].tags);
        setUid(uid);
        setProfilePicUrl(props.users.userList[uid].profilePicUrl);
        setTagOptions(props.tags.tagsList.tagOptions);
      }
    }
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    const { dispatch } = props;
    dispatch(
      updateProfileInfo(
        firstname,
        lastname,
        about,
        position,
        sparkMail,
        alternativeMail,
        phoneNumber,
        chosenTags,
        uid,
        profilePicUrl
      )
    );

    props.history.push(`/veiledere/${uid}`);
  }

  function onCancel(e) {
    e.preventDefault();
    props.history.push(`/veiledere/${props.match.params.veilederId}`);
  }

  function handleTagChange(val) {
    setChosenTags(val);
  }

  const { t } = props;
  return (
    <div className="wrapper wrapper-content animated fadeInRight">
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>{t('profile.EditProfile')}</h5>
            </div>

            <ToastContainer />

            <div className="ibox-content">
              <form className="form-horizontal" onSubmit={onSubmit}>
                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    {t('profile.FirstName')}
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <label className="col-sm-2 control-label">
                    {t('profile.LastName')}
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                </div>

                <div className="hr-line-dashed" />
                <div className="form-group">
                  <label htmlFor="aboutyou" className="col-sm-2 control-label">
                    {t('profile.AboutYou')}
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      type="text"
                      id="aboutyou"
                      name="aboutyou"
                      className="form-control"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    />
                  </div>
                </div>

                <div className="hr-line-dashed" />
                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    {t('profile.Position')}
                  </label>
                  <div className="col-sm-10">
                    <p className="form-control-static">{position}</p>
                  </div>
                </div>

                <div className="hr-line-dashed" />
                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    {t('profile.Email')}
                  </label>
                  <div className="col-sm-10">
                    <p className="form-control-static">{sparkMail}</p>
                  </div>
                </div>

                <div className="hr-line-dashed" />
                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    {t('profile.AlternativeEmail')}
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      value={alternativeMail}
                      onChange={(e) => setAlternativeMail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="hr-line-dashed" />
                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    {t('profile.PhoneNumber')}
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-sm-2 control-label">Tags</label>
                  <div className="col-sm-8">
                    <ProfileTags
                      handleParentValues={handleTagChange}
                      tagOptions={tagOptions}
                      chosenTags={chosenTags}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    {t('profile.ProfilePicture')}
                  </label>
                  <div className="col-sm-3">
                    <img
                      alt="image"
                      className="img-circle"
                      src={profilePicUrl}
                      style={{ width: '120px' }}
                    />
                  </div>
                  <br />
                  <div className="col-sm-3">
                    <ProfilePictureDropzone onImageDrop={onImageDrop} />
                    {uploadInProgress ? <LoadingSquare /> : null}
                  </div>

                  <div>
                    {uploadedFileCloudinaryUrl === '' ||
                    croppingClosed === true ? null : (
                      <div>
                        <Cropper
                          ref={cropperRef}
                          src={uploadedFileCloudinaryUrl}
                          style={{ height: 400, width: '100%' }}
                          // Cropper.js options
                          aspectRatio={1 / 1}
                          guides
                          crop={onCrop}
                          minCropBoxHeight={150}
                          minCropBoxWidth={150}
                          viewMode={2}
                        />

                        <div className="dropzonebtns">
                          <button
                            type="button"
                            className="btn btn-white"
                            onClick={cancelCrop}
                          >
                            {t('profile.Remove')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={confirmedCrop}
                          >
                            {t('profile.SelectImage')}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="hr-line-dashed" />
                <div className="form-group">
                  <div className="col-sm-4 col-sm-offset-2">
                    <button className="btn btn-white" onClick={onCancel}>
                      {t('profile.Cancel')}
                    </button>
                    <button
                      className="btn btn-primary"
                      disabled={props.profile.updating}
                      type="submit"
                    >
                      {t('profile.Save')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
    users: state.users,
    profile: state.profile,
    uploadedFile: state.uploadedFile,
    tags: state.tags,
  }))
)(EditProfileContainer);
