import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';

const initialState = {
  author: '',
  text: '',
  meetingPlace: '',
  postedOn: firebase.database.ServerValue.TIMESTAMP,
  meetingStart: '',
  meetingEnd: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEETING_NOTE_TEXT':
      return {
        ...state,
        text: action.payload,
      };

    case 'SET_MEETING_NOTE_AUTHOR':
      return {
        ...state,
        author: action.payload,
      };

    default:
      return state;
  }
};
