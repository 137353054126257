import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { updateCurrentTagsTab } from '../../../redux/actions/nav';
import TagsTabElement from './TagsTabElement';
import TagMenuItem from './TagMenuItem';
import LoadingSquare from '../../common/LoadingSquare';

function TagsTabComp(props) {
  const { nav, tags, t, startups, users, teammembers, idehavere } = props;
  const dispatch = useDispatch();
  const { currentTagsTab } = nav;
  const { startupList } = startups;
  const { userList } = users;
  const { idehaverList } = idehavere;
  const { teamList } = teammembers;
  const { tagsList } = tags;
  const [currentEditId, setCurrentEditId] = useState('');
  const [tagList, setTagList] = useState([]);
  const newTagValuePlaceholder = t('admin.newTagHere');
  const newTagLabelPlaceholder = t('admin.Add');
  const tagTabs = [];

  function startEdit(id) {
    setCurrentEditId(id);
  }

  function handleChangeTab(tagListId) {
    startEdit('');
    dispatch(updateCurrentTagsTab(tagListId));
  }

  Object.keys(tagsList).map((key) => {
    tagTabs.push(
      <TagMenuItem
        key={key}
        handleChangeTab={handleChangeTab}
        tagListName={tagsList[key].id}
        currentTagsTab={currentTagsTab}
      />
    );
  });

  const emptyObject = {
    label: newTagLabelPlaceholder,
    value: newTagValuePlaceholder,
  };

  useEffect(() => {
    let currentList = tagsList[currentTagsTab];
    // studieOptions are objects with 1,2,3... as index, instead of array like the others.
    // Making studieOptions an array.
    if (currentList.id === 'studieOptions') {
      currentList = Object.keys(currentList).map(
        (studyKey) => currentList[studyKey]
      );
      currentList.id = currentList.pop();
    }

    const tempList = [];
    Object.keys(currentList).map((key) => {
      let tagId = key;
      // if key is id, it's last item.
      // Using length as object key to be able to create new tag.
      if (key === 'id') {
        tagId = currentList.length;
        // If new tag already exists, tag 'skip' to skip it later.
        if (currentList[tagId - 1].value === newTagValuePlaceholder) {
          tagId = 'skip';
        }
        currentList[tagId] = emptyObject;
      }
      if (tagId !== 'skip') {
        tempList.push(
          <TagsTabElement
            key={key}
            id={tagId}
            currentList={currentList}
            isEditing={tagId === currentEditId}
            startEdit={startEdit}
            newTagValuePlaceholder={newTagValuePlaceholder}
            newTagLabelPlaceholder={newTagLabelPlaceholder}
            teamList={teamList}
            userList={userList}
            idehaverList={idehaverList}
            startupList={startupList}
            currentTagsTab={currentTagsTab}
          />
        );
      }
    });
    setTagList(tempList);
  }, [
    currentTagsTab,
    currentEditId,
    tagsList,
    startupList,
    teamList,
    idehaverList,
    userList,
  ]);

  if (!startupList || !userList || !teamList || !tagsList || !idehaverList) {
    return <LoadingSquare />;
  }

  return (
    <div className="col-sm-12">
      <div className="ibox">
        <div className="ibox-content">
          <h2>{t('admin.TagAdmin')}</h2>
          <div>
            <ul>
              <li>{t('admin.TagsAreAddedByEditingLastTag')}</li>
              <li>{t('admin.TheNumberIndicatesHowOftenItsUsed')}</li>
              <li>{t('admin.TagsAreDeletedByRemovingLabelAndValueText')}</li>
            </ul>
          </div>
          <div className="clients-list">
            <ul className="nav nav-tabs">{tagTabs}</ul>
          </div>
          <div className="tab-content">
            <div id="tab-1" className="tab-pane active">
              <div className="full-height-scroll">
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <tbody>{tagList}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation('common')(TagsTabComp);
