import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FaDotCircle as FaDotCircleO } from 'react-icons/fa';
import tagsUpdate from '../../../redux/actions/tagsUpdate';
import checkTag from './checkTag';
import checkTagArray from './checkTagArray';
import WhiteButton from './WhiteButton';

function TagsTabElement(props) {
  const {
    id,
    currentList,
    currentTagsTab,
    isEditing,
    startEdit,
    newTagValuePlaceholder,
    newTagLabelPlaceholder,
    t,
    teamList,
    userList,
    idehaverList,
    startupList,
  } = props;

  const [tagFrequency, setTagFrequency] = useState(0);
  const [tagsUsedBy, setTagsUsedBy] = useState({
    teamList: [],
    userList: [],
    idehaverList: [],
    startupList: [],
  });
  const [labelText, setLabelText] = useState(currentList[id].label || '');
  const [valueText, setValueText] = useState(currentList[id].value || '');
  const [oldObject, setOldObject] = useState({
    label: currentList[id].label || '',
    value: currentList[id].value || '',
  });

  useEffect(() => {
    if (currentList[id]) {
      setOldObject({
        label: currentList[id].label,
        value: currentList[id].value,
      });
    }
  }, [currentList, id]);

  useEffect(() => {
    if (currentList[id]) {
      setLabelText(currentList[id].label);
      setValueText(currentList[id].value);
    } else {
      setLabelText('');
      setValueText('');
    }
    setTagFrequency(countTagsUsed());
  }, [currentTagsTab, currentList]);

  useEffect(() => {
    setTagFrequency(countTagsUsed());
  }, [teamList, idehaverList, startupList, userList, oldObject]);

  const dispatch = useDispatch();

  function countTagsUsed() {
    let count = 0;
    const tempObject = {
      teamList: [],
      userList: [],
      idehaverList: [],
      startupList: [],
    };

    function pushToObject(listName, key) {
      count += 1;
      tempObject[listName].push(key);
      setTagsUsedBy(tempObject);
    }

    // Using different functions to count the different tag lists.
    if (currentTagsTab === 'tagOptions') {
      checkTagArray(userList, 'userList', oldObject, pushToObject);
      checkTagArray(startupList, 'startupList', oldObject, pushToObject);
    }
    if (currentTagsTab === 'horteomsparkOptions') {
      checkTag(
        idehaverList,
        'horteomspark',
        'idehaverList',
        oldObject,
        pushToObject
      );
      checkTag(teamList, 'horteomspark', 'teamList', oldObject, pushToObject);
    }
    if (currentTagsTab === 'bransjeOptions') {
      checkTag(startupList, 'bransje', 'startupList', oldObject, pushToObject);
    }
    if (currentTagsTab === 'studieOptions') {
      checkTag(
        idehaverList,
        'studieSted',
        'idehaverList',
        oldObject,
        pushToObject
      );
      checkTag(teamList, 'studieretning', 'teamList', oldObject, pushToObject);
    }
    if (currentTagsTab === 'roleOptions') {
      checkTag(
        idehaverList,
        'startupRole',
        'idehaverList',
        oldObject,
        pushToObject
      );
      checkTag(teamList, 'startupRole', 'teamList', oldObject, pushToObject);
    }

    return count;
  }

  function saveChange() {
    startEdit('');
    function resetValues() {
      setLabelText(oldObject.label);
      setValueText(oldObject.value);
    }
    // List tags other than the one old object you're editing
    const otherTags = currentList.filter(
      (tag) => oldObject.label !== tag.label
    );
    // Can't edit tags to existing tags
    if (otherTags.some((tag) => tag.label === labelText)) {
      alert('Tagen eksisterer allerede');
      resetValues();
      return null;
    }
    // Delete tag if inserted empty string. Can't delete tags in use.
    if (labelText === '' && valueText === '') {
      if (tagFrequency > 0) {
        alert('Tags som allerede benyttes kan ikke slettes.');
        resetValues();
        return null;
      }
      dispatch(tagsUpdate(currentList.id, id, null));
      return null;
    }
    // Reset if one value is lacking or new values are same as old values
    if (
      labelText === '' ||
      valueText === '' ||
      (labelText === oldObject.label && valueText === oldObject.value)
    ) {
      resetValues();
      return null;
    }
    // Add / update tag
    if (
      labelText !== '' &&
      valueText !== '' &&
      labelText !== newTagLabelPlaceholder
    ) {
      const newValue = {
        label: labelText,
        value: valueText,
      };
      // Editing only label, so seting value same value as label
      if (newValue.value === newTagValuePlaceholder) newValue.value = labelText;
      dispatch(
        tagsUpdate(
          currentList.id,
          id,
          newValue,
          tagsUsedBy,
          oldObject,
          userList,
          startupList
        )
      );
      return null;
    }
    return null;
  }

  return (
    <tr id={id}>
      <td className="contact-type">
        <FaDotCircleO />
      </td>
      <td>
        <span className="client-link">
          {isEditing ? (
            <input
              type="text"
              className="form-control"
              value={labelText}
              onChange={(e) => setLabelText(e.target.value)}
            />
          ) : (
            labelText
          )}
        </span>
      </td>
      <td className="contact-type" />
      <td>
        {' '}
        {isEditing ? (
          <input
            type="text"
            className="form-control"
            value={valueText}
            disabled={valueText === newTagValuePlaceholder}
            onChange={(e) => setValueText(e.target.value)}
          />
        ) : (
          valueText
        )}
      </td>
      <td>
        <span>{tagFrequency}</span>
      </td>
      <td className="client-status">
        <span>
          {isEditing ? (
            <WhiteButton onClick={saveChange} buttonText={t('admin.Save')} />
          ) : (
            <WhiteButton
              onClick={() => startEdit(id)}
              buttonText={t('admin.Edit')}
            />
          )}
        </span>
      </td>
    </tr>
  );
}

export default withTranslation('common')(TagsTabElement);
