import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { updateCurrentView } from '../redux/actions/nav';
import PageHeader from '../components/common/PageHeader';
import LoadingSquare from '../components/common/LoadingSquare';
import IdehavereKjonn from '../components/statistics/Gender/IdehavereKjonn';
import IdeerFakultet from '../components/statistics/Faculty/IdeerFakultet';
import TeammembersGender from '../components/statistics/Gender/TeammembersGender';
import TronderEnergiBidraget from '../components/statistics/TronderEnergiBidraget/TronderEnergiBidraget.jsx';
import GendersAllYears from '../components/statistics/Gender/GendersAllYears';
import StartupsAllYears from '../components/statistics/Startups/StartupsAllYears';
import { StatisticsPicker } from '../components/statistics/StatisticsPicker';
import MeetingNotesAllYears from '../components/statistics/MeetingNotes/MeetingNotesAllYears';
import CurrentlyActiveGenders from '../components/statistics/Gender/CurrentlyActiveGenders';

function StatisticsContainer(props) {
  const { dispatch, idehavere, startups, tags, teammembers, TEBNumbers, t } = props;

  const [view, setView] = useState("startups");

  useEffect(() => {
    dispatch(updateCurrentView('statistikk'));
  }, []);

  if (idehavere.fetchingData || startups.fetchingData || tags.fetchingData) {
    return (
      <div>
        <PageHeader view="Admin" subview={null} />
        <LoadingSquare />
      </div>
    );
  }
  return (
    <div>
      <PageHeader view={t('nav.Statistics')} subview={null} />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">

          <div className="col-lg-12">

            <StatisticsPicker currentView={view} changeViewCallback={setView} />

            {view === 'startups' ? <StartupsAllYears startups={startups.startupList} /> : null}

            {view === 'teb' ? <TronderEnergiBidraget TEBNumbers={TEBNumbers.latestEntries} /> : null}

            {view === 'meetingnotes' ? <MeetingNotesAllYears startups={startups.startupList} /> : null}

            {view === 'gender' ?
              <>
                <CurrentlyActiveGenders
                  idehavere={idehavere.idehaverList}
                  startups={startups.startupList}
                  teammembers={teammembers.teamList} />
                <GendersAllYears
                  startups={startups.startupList}
                  teamList={teammembers.teamList}
                  idehaverList={idehavere.idehaverList}
                />
                <IdehavereKjonn idehavere={idehavere} />
                <TeammembersGender teammembers={teammembers} />
              </> : null}

            {view === 'faculty' ?
              <>
                <IdeerFakultet idehavere={idehavere} />
                {/*<IdeerInstitutt idehavere={idehavere} />*/}
              </> : null}

          </div>
        </div>
      </div >
    </div >
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    nav: state.nav,
    idehavere: state.idehavere,
    users: state.users,
    startups: state.startups,
    teammembers: state.teammembers,
    tags: state.tags,
    TEBNumbers: state.TEBNumbers,
  }))
)(StatisticsContainer);
