// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.controls {
  display: flex;
  justify-content: center;
}

.buttonGroup {
  display: flex;
  align-self: center;
  justify-content: center;

  margin-top: 2rem;
}

.buttonGroup button {
  margin: 0 1rem 0 1rem;
}

.meetingNotesDataForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.markdownEditor h2 {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/css/MarkdownMeetingNotes.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;;EAEvB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".controls {\n  display: flex;\n  justify-content: center;\n}\n\n.buttonGroup {\n  display: flex;\n  align-self: center;\n  justify-content: center;\n\n  margin-top: 2rem;\n}\n\n.buttonGroup button {\n  margin: 0 1rem 0 1rem;\n}\n\n.meetingNotesDataForm {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n}\n\n.markdownEditor h2 {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
