// (list of values, where tag is used, checking old value, counting frequency)

function checkTagArray(objectList, listName, oldObject, pushToObject) {
  if (objectList && oldObject) {
    Object.keys(objectList).map((key) => {
      if (objectList[key].tags) {
        const chosenTags = objectList[key].tags;
        const tagKeys = Object.keys(chosenTags);
        if (tagKeys.includes('label')) {
          if (chosenTags.label === oldObject.label) pushToObject(listName, key);
        } else if (
          tagKeys.some((tagKey) => chosenTags[tagKey].label === oldObject.label)
        )
          pushToObject(listName, key);
      }
    });
  }
}

export default checkTagArray;
