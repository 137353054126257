import { leaderGroup } from '../../constants';
import { IPosition } from '../../types/IPosition';
import { IStatusTypesLabel } from '../../types/IStatusTypes';

function isLeader(position: IPosition, status: IStatusTypesLabel): boolean {
  const leaderList = leaderGroup.map((obj) => obj.value);
  return leaderList.includes(position) && status === IStatusTypesLabel.Aktiv;
}

export default isLeader;
