import React, { useState } from 'react';
import ReactMde from 'react-mde';
import showdownConverter from './showdownConverter';
import WelcomeAlert from './WelcomeAlert';
import { useDispatch, useStore } from 'react-redux';
import { updateMeetingNoteText } from '../../../redux/actions/meetingNote';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import '../../../css/MarkdownMeetingNotes.css';
import 'react-mde/lib/styles/css/react-mde-all.css';

const MarkdownEditor = () => {
  const store = useStore();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  const [showWelcomeAlert, setShowWelcomeAlert] = useLocalStorage<boolean>(
    'showWelcomeAlert',
    true
  );

  // useState used only in this component to show text in editor view
  const [meetingNoteText, setMeetingNoteText] = useState(
    store.getState().meetingNote.text
  );

  const onTextFieldChange = (text: string) => {
    // Save text to local useState
    setMeetingNoteText(text);

    // Save meeting note text to redux state
    dispatch(updateMeetingNoteText(text));
  };

  return (
    <div className="markdownEditor">
      {showWelcomeAlert && (
        <WelcomeAlert
          showWelcomeAlert={showWelcomeAlert}
          setShowWelcomeAlert={setShowWelcomeAlert}
        />
      )}
   

      <ReactMde
        value={meetingNoteText}
        minEditorHeight={400}
        onChange={(text) => onTextFieldChange(text)}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(showdownConverter.makeHtml(markdown))
        }
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
      />
    </div>
  );
};

export default MarkdownEditor;
