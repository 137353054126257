/* 

  Pre-processes startup data to be on a react-table friendly format

*/

import { IIdehaverList } from '../../../../types/IIdehaver';
import { IStartupList } from '../../../../types/IStartup';

const prepareStartupData = (
  startupsList: IStartupList,
  idehaverList: IIdehaverList
) => {
  return Object.keys(startupsList).map((startup) => {
    return {
      status: {
        statusLabel: startupsList[startup].status.label,
        statusValue: startupsList[startup].status.value,
      },
      name: {
        id: startup,
        name: startupsList[startup].ideNavn,
        originator: idehaverList[startupsList[startup].idehaver].navn,
      },
      phase: {
        phaseLabel: startupsList[startup].fase.label,
        phaseValue: startupsList[startup].fase.value,
      },
      supervisor: {
        supervisorName: startupsList[startup].veileder.label,
        value: startupsList[startup].veileder.value,
      },
      field: startupsList[startup].bransje.label,
      tags: startupsList[startup].tags,
      date: startupsList[startup].registreringsDato,
    };
  });
};

export default prepareStartupData;
