/* 

  Responsible for

  1. Fetching of data from the redux store
  2. Parsing of fetched data to be understood by StartupTable
  3. Rendering StartupTable with parsed data

*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSquare from '../components/common/LoadingSquare';
import StartupTable from '../components/startup/StartupTable/StartupTable';
import prepareStartupData from '../components/startup/StartupTable/utils/prepareStartupData';
import { IIdehaver } from '../types/IIdehaver';
import { IStartup } from '../types/IStartup';

export interface ITableData {
  status: { statusLabel: string; };
  name: { name: string; };
  phase: { phaseLabel: string; };
  supervisor: { supervisorName: string; };
  field: string;
  tags: { label: string }[];
  date?: number;  // Assuming date is a string; adjust as necessary based on actual data format
}

const StartupTableContainer = () => {
  /* Get list of startups from redux store */
  const startups: { [startupID: string]: IStartup } = useSelector(
    (state: any) => state.startups.startupList
  );

  /* Get list of idehavere from redux store */
  const idehavere: { [idehaverID: string]: IIdehaver } = useSelector(
    (state: any) => state.idehavere.idehaverList
  );

  const [tableData, setTableData] = useState<ITableData[]>([]);

  /* Parse list of startups so it can be injected in the table in StartupTable.tsx */
  useEffect(() => {
    if (startups && idehavere) {
      setTableData(prepareStartupData(startups, idehavere));
    }
  }, [startups, idehavere]);

  return tableData.length ? (
    <>
      <StartupTable
        tableData={tableData}
        showFilters={true}
        title={'Startups'}
      />
    </>
  ) : (
    <LoadingSquare />
  );
};

export default StartupTableContainer;
