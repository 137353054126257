import React, { useState, useEffect } from 'react';
import { FaPen as FaPencil, FaTrash } from 'react-icons/fa';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { confirmAlert, deleteAlert } from './Alerts';
import { sincePosted } from '../../../redux/actions/utils';
import onDelete from './onDelete';
import WhiteButton from './WhiteButton';
import editModalStyle from './editModalStyle';
import EditMeetingNote from '../EditMeetingNote/EditMeetingNote';
import DisplayText from './DisplayText';
import msToFormattedDate from '../../utils/msToFormattedDate';
import ReactMarkdown from 'react-markdown';

export default function MeetingNote({ note, currentUser, author, startupId }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [shownAlert, setShownAlert] = useState(null);
  const [isAuthor, setIsAuthor] = useState(false);

  useEffect(() => {
    setIsAuthor(currentUser === author.uid);
  }, [currentUser, author.uid]);

  function closeAllModals() {
    setShowEditModal(false);
    setShownAlert(null);
  }

  function confirmDelete() {
    onDelete(startupId, note.id);
    setShownAlert(confirmAlert(closeAllModals));
  }

  function requestDeleteThisPost() {
    setShownAlert(deleteAlert(closeAllModals, confirmDelete));
  }

  function openEditModal() {
    setShowEditModal(true);
  }

  if (!note) {
    return null;
  }

  return (
    <div className="feed-element">
      <div>
        {shownAlert}
        {isAuthor ? (
          <WhiteButton
            onClick={requestDeleteThisPost}
            icon={<FaTrash />}
            text="Slett"
          />
        ) : null}
        <WhiteButton
          onClick={openEditModal}
          icon={<FaPencil />}
          text="Rediger"
        />

        <Modal
          contentLabel="Modal Editor"
          isOpen={showEditModal}
          onRequestClose={closeAllModals}
          style={editModalStyle}
          ariaHideApp={false}
        >
          <EditMeetingNote
            noteId={note.id}
            currentNote={note}
            closeEditModal={closeAllModals}
            startupId={startupId}
            currentUser={currentUser}
            postedDate={note.postedOn}
            newNote={false}
          />
        </Modal>
      </div>

      <a href="#" className="pull-left">
        <img
          alt="profile"
          className="rounded-circle"
          src={author.profilePicUrl}
        />
      </a>
      <div className="media-body">
        <small className="pull-right text-navy">
          {sincePosted(note.postedOn)} siden
        </small>
        <strong>
          <Link
            to={`/veiledere/${author.uid}`}
          >{`${author.firstname} ${author.lastname}`}</Link>
        </strong>{' '}
        postet et møtenotat.
        <br />
        <small className="text-muted">{msToFormattedDate(note.postedOn)}</small>
        <ReactMarkdown className="well">{note.text}</ReactMarkdown>
      </div>
    </div>
  );
}
