import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const requestTEBNumbersData = () => ({
  type: 'REQUEST_TEBNUMBERS_DATA',
});

const TEBNumbersDataFetchedSuccessfully = (payload) => ({
  type: 'TEBNUMBERS_DATA_FETCHED_SUCCESSFULLY',
  payload,
});

const TEBNumbersFetchError = (error) => ({
  type: 'TEBNUMBERS_DATA_FETCH_ERROR',
  error,
});

function fetchTEBNumbers() {
  return (dispatch) => {
    dispatch(requestTEBNumbersData());
    firebase
      .database()
      .ref('statistics')
      .on('value', (snapshot) => {
        dispatch(TEBNumbersDataFetchedSuccessfully(snapshot.val()));
      });
  };
}

export default fetchTEBNumbers;
