import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function StartupsByYear(props) {
  const { startups, year, t } = props;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const series = [];
  const counter = {};

  months.forEach(month => {
    counter[month] = 0;
  });

  Object.values(startups).forEach(startup => {
    const date = new Date(startup.registreringsDato);
    if (date.getFullYear() === year) {
      counter[months[date.getMonth()]] += 1;
    }
  });

  for (let i = 0; i < 12; i++) {
    series.push(counter[months[i]]);
  };

  const startupByYearChart = {
    chart: {
      type: 'line',
    },
    exporting: {
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        align: 'center',
        verticalAlign: 'bottom',
        y: 40,
      },
    },
    title: {
      text: year,
    },
    xAxis: {
      categories: months,
    },
    series: [{
      name: "Registrerte",
      data: series
    }],
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        options={startupByYearChart}
      />
    </>
  );
}

export default withTranslation('common')(StartupsByYear);
