import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function StartupsByIndustry(props) {
  const { startups, t } = props;
  const industries = {};

  Object.values(startups).forEach(startup => {
    let key = startup.bransje.label;
    if (industries[key]) {
      industries[key] += 1;
    } else {
      industries[key] = 1;
    }
  });

  //console.log(industries);

  const categories = [];
  const series = [];
  Object.keys(industries).map((industry) => {
    if (industry != "n/a") {
      categories.push(industry)
      series.push(industries[industry])
    }
  });

  const allStartupChart = {
    chart: {
      type: 'column',
    },
    exporting: {
      enabled: true,
    },
    navigation: {
      buttonOptions: {
        align: 'center',
        verticalAlign: 'bottom',
        y: 40,
      },
    },
    title: {
      text: "Bransje",
    },
    xAxis: {
      categories: categories,
    },
    series: [{
      name: "Bransje",
      data: series
    }],
  }

  return (
    <>
      <div className="jumbotron jumbotron-fluid mt-4">
        <div className="container text-center">
          <h1 className="display-4">
            {industries["n/a"]}
          </h1>
          <p className="lead">
            har ikke registrert bransje
          </p>
        </div>
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        options={allStartupChart}
      />
    </>

  );
}

export default withTranslation('common')(StartupsByIndustry);
