import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import feedback from '../../../redux/actions/feedback';
import { feedbackMessages } from '../../../constants/index';
import IMeetingNote from '../../../types/IMeetingNote';

export function saveNote(
  startupId: string,
  newNote: boolean,
  noteId: string,
  noteText: string,
  currentUser: string,
  postedDate: Date
) {
  const meetingNote: IMeetingNote = {
    text: noteText,
    author: currentUser,
    postedOn: postedDate,
    lastUpdated: {},
  };

  let chosenNoteId: string | null = noteId;
  if (!newNote) {
    meetingNote.lastUpdated = firebase.database.ServerValue.TIMESTAMP;
    meetingNote.postedOn = postedDate;
  } else {
    chosenNoteId = firebase
      .database()
      .ref()
      .child(`startups/${startupId}/meetingNotes`)
      .push().key;
  }

  firebase
    .database()
    .ref(`startups/${startupId}/meetingNotes/${chosenNoteId}`)
    .update(meetingNote)
    .then(() => {
      feedback.success(feedbackMessages.meetingNoteChangedSuccess);
    })

    .catch((err) => {
      feedback.error(err);
    });
}
