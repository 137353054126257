export const loginRequest = () => ({
  type: 'LOGIN_REQUEST',
});

export const loginSuccess = (uid) => ({
  type: 'LOGIN_SUCCESS',
  uid,
});

export const loginError = (error) => ({
  type: 'LOGIN_ERROR',
  error,
});

export const logout = () => ({
  type: 'LOG_OUT',
});

export const setCurrentUserInfo = (user) => ({
  type: 'SET_CURRENT_USER_INFO',
  user,
});
