import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function TeammembersGender(props) {
  const { teammembers, t } = props;

  let second = 0;
  Object.values(teammembers.teamList).forEach(member => {
    second += 1;
  });

  const teammemberGenderChart = {
    chart: {
      type: 'pie',
    },
    title: {
      text: "Teammedlemmer, total for alle år",
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [
      {
        name: 'Brands',
        colorByPoint: true,
        data: [
          {
            name: t('startups.Woman'),
            y: Object.values(teammembers.teamList).filter((member) => member.sex === 'f').length
          },
          {
            name: t('startups.Man'),
            y: Object.values(teammembers.teamList).filter((member) => member.sex === 'm').length
          },
        ],
      }]
  };

  return (
    <div className="col-lg-6 mt-4 mb-4">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        options={teammemberGenderChart}
      />
    </div>
  );
}

export default withTranslation('common')(TeammembersGender);
