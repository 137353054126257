import React from 'react';
import ITag from '../../../../../types/ITag';

// Iterate over tags stored in a startup, and return components to be displayed
const tagLabels = (tags: ITag[]) => {
  let tagLabels: {} = [];
  let i = 0;

  try {
    tagLabels = tags.map((tag) => {
      i++;

      return (
        <p key={i} className="btn btn-sm btn-white tag-btn m-t-xs">
          {' '}
          {tag.label}{' '}
        </p>
      );
    });
  } catch {
    //console.log('Invalid tag format');
  }

  return tagLabels;
};

interface INameCellStyling {
  row: any;
}

const Tags = ({ row }: INameCellStyling) => {
  return <>{tagLabels(row.original.tags)}</>;
};

export default Tags;
