import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../redux/actions/auth';
import firebase from 'firebase/compat/app';

const AuthHandler = ({ dispatch }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
	const unsub = firebase.auth().onAuthStateChanged((user) => {
	  if (!user && location.pathname !== '/auth/signup' && location.pathname !== '/auth/login') {
      //Added the above condition to prevent the user from being redirected when they are on the signup page
		dispatch(logout());
		console.log('You are not authorized! Redirecting you.');
		navigate('/auth/login');
	  }
	});
	return () => unsub();
  }, [dispatch, navigate, location]);

  return null;
};

export default connect()(AuthHandler);