import { toast } from 'react-toastify';

const toastOptions = {
  autoClose: 3000,
  position: toast.POSITION.TOP_RIGHT,
};

const feedback = {
  success: (info) => toast.success(info, toastOptions),
  warning: (info) => toast.warning(info, toastOptions),
  error: (info) =>
    toast.error(info, {
      autoClose: false,
      position: toast.POSITION.TOP_RIGHT,
    }),
};

export default feedback;
