import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  bransjeOptions,
  filterIdePhase,
  filterIdeStatus,
} from '../../../../constants';
import { IStartup } from '../../../../types/IStartup';
import IUser from '../../../../types/IUser';
import DateFilter from './filters/DateFilter';
import SelectFilter, { SelectFilterTypes } from './filters/SelectFilter';
import TagsSelectFilter from './filters/TagsSelectFilter';

interface ITableFilter {
  setFilter: (columnId: string, updater: any) => void;
  activeFilters: { id: string; value: string | number }[];
}

const TableFilter = ({ setFilter }: ITableFilter) => {
  /* Get list of startups from redux store */
  const startups: { [startupID: string]: IStartup } = useSelector(
    (state: any) => state.startups.startupList
  );

  /* Get list of veiledere from redux store */
  const veiledere: { [startupID: string]: IUser } = useSelector(
    (state: any) => state.users.userList
  );

  const [startupSelectOptions, setStartupSelectOptions] = useState<{}[]>([]);
  const [veilederSelectOptions, setVeilederSelectOptions] = useState<{}[]>([]);

  /* Parse startup-data so it can be used as options in SelectFilter */
  useEffect(() => {
    if (startups) {
      setStartupSelectOptions(
        Object.keys(startups).map((key) => ({
          value: key,
          label: startups[key].ideNavn,
        }))
      );
    }
  }, [startups]);

  /* Parse veiledere-data so it can be used as options in SelectFilter */
  useEffect(() => {
    if (veiledere) {
      setVeilederSelectOptions(
        Object.keys(veiledere).map((key) => ({
          value: key,
          label: veiledere[key].firstname + ' ' + veiledere[key].lastname,
        }))
      );
    }
  }, [veiledere]);

  return (
    <div className="d-flex flex-wrap">
      <div className="col-md-6 startup-filter">
        <h4>Startup navn</h4>
        <SelectFilter
          column={SelectFilterTypes.Name}
          options={startupSelectOptions}
          setFilter={setFilter}
        />
      </div>

      <div className="col-md-6 startup-filter">
        <h4>Veileder</h4>
        <SelectFilter
          column={SelectFilterTypes.Supervisor}
          options={veilederSelectOptions}
          setFilter={setFilter}
        />
      </div>

      <div className="col-md-3 col-sm-6 startup-filter">
        <h4>Status</h4>
        <SelectFilter
          column={SelectFilterTypes.Status}
          options={filterIdeStatus}
          setFilter={setFilter}
        />
      </div>

      <div className="col-md-3 col-sm-6 startup-filter">
        <h4>Fase</h4>
        <SelectFilter
          column={SelectFilterTypes.Phase}
          options={filterIdePhase}
          setFilter={setFilter}
        />
      </div>

      <div className="col-md-3 col-sm-6 startup-filter">
        <h4>Bransje</h4>
        <SelectFilter
          column={SelectFilterTypes.Field}
          options={bransjeOptions}
          setFilter={setFilter}
        />
      </div>

      <div className="col-md-3 col-sm-6 startup-filter">
        <h4>Tags</h4>
        <TagsSelectFilter options={filterIdePhase} setFilter={setFilter} />
      </div>

      <div className="col-md-3 col-sm-6 startup-filter">
        <h4>Dato opprettet</h4>
        <DateFilter setFilter={setFilter} />
      </div>
    </div>
  );
};

export default TableFilter;
