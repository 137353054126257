import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  FaBan,
  FaCheck,
  FaEnvelope,
  FaExclamation,
  FaPhone,
} from 'react-icons/fa';
import countActiveStartups from './countActiveStartups';

function ClientInfo({ handleClick, userArray, userIndex, startupList, t }) {
  const {
    firstname,
    lastname,
    phoneNumber,
    sparkMail,
    startups,
    capacity,
    profilePicUrl,
    uid,
  } = userArray[userIndex];

  //Set first letter of firstname and lastname to uppercase
  const firstName = firstname.charAt(0).toUpperCase() + firstname.slice(1);
  const lastName = lastname.charAt(0).toUpperCase() + lastname.slice(1);


  const numberOfStartups = countActiveStartups(startups, startupList);

  let statusText = '';
  let statusIcon = [];
  if (numberOfStartups > capacity) {
    statusText = t('sparks.AboveCapacity');
    statusIcon = <FaExclamation />;
  } else if (numberOfStartups < capacity) {
    statusText = t('sparks.GoodCapacity');
    statusIcon = <FaCheck />;
  } else {
    statusText = t('sparks.FullCapacity');
    statusIcon = <FaBan />;
  }

  return (
    <tr onClick={() => handleClick(userIndex)}>
      <td className="client-avatar">
        <img src={profilePicUrl} alt="profile" />{' '}
      </td>
      <td>
        <Link to={`/veiledere/${uid}`}>
          <span className="client-link">{`${firstName} ${lastName}`}</span>
        </Link>
      </td>
      <td className="contact-type">
        <FaPhone />
      </td>
      <td className="text-nowrap"> {phoneNumber}</td>
      <td className="contact-type">
        <FaEnvelope />
      </td>
      <td> {sparkMail}</td>
      <td className="client-status">
        <span
          className={
            capacity > numberOfStartups
              ? 'label label-success'
              : capacity < numberOfStartups
              ? 'label label-danger'
              : 'label label-warning'
          }
        >
          {statusIcon}
          {` ${statusText} ${numberOfStartups}/${capacity}`}
        </span>
      </td>
    </tr>
  );
}

export default withTranslation('common')(ClientInfo);
