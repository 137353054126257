/* select-filter for multiple values. Used when selecting tags */

import React from 'react';
import Select from 'react-select';
import { tagOptions } from '../../../../../constants';

interface ITagsSelectFilter {
  setFilter: (columnId: string, updater: any) => void;
  options: {}[];
}

const TagsSelectFilter = ({ setFilter, options }: ITagsSelectFilter) => {
  return (
    <Select
      isClearable
      isMulti
      placeholder={'Velg...'}
      onChange={(e) => {
        // Collect all tags in an array
        const allTags = e.map((o) => o!.label);
        // Set whole array of tags as filter value
        setFilter('tags', allTags && allTags.length ? allTags : undefined);
      }}
      defaultValue={null}
      options={tagOptions}
    />
  );
};

export default TagsSelectFilter;
