import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import hostname from '../../../config/hostname';
import { IMailchimpData } from '../../../types/IMailchimpData';
import IUser from '../../../types/IUser';
import { signUpToMailchimp } from '../../admin/MailSignup/signUpToMailchimp';
import createStartupTS from './createStartup';
import InputErrorMessage from './InputErrorMessage';
import InputTypes from './NewStartupTypes';

interface INewStartupComponent {
  users: IUser[];
}

const NewStartupForm = ({ users }: INewStartupComponent) => {
  const { t } = useTranslation('common');
  const [signupToNewsletter, setSignupToNewsletter] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get tags from redux store
  const tagsList = useSelector((state: any) => state.tags.tagsList);

  const goToStartup = (startupID: string) => {
    navigate(`/startups/${startupID}`);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<InputTypes>({});

  const onSubmit = async (data: InputTypes) => {
    // Used for disabling submitbutton, giving feedback to user
    // ... that processes are on-going. Signup takes 2 seconds.
    setIsSubmitting(true);
    // Signup to mailchimp is only for NTNU
    // ... Not abling it on localhost, to avoid multiple signups
    if (hostname === 'basen') {
      const signupEmailData: IMailchimpData = {
        email_address: data.email,
        signupToNewsletter,
      };
      const response = signUpToMailchimp(signupEmailData);
      if ((await response).status !== 200) {
        alert(
          'En feil skjedde da epost skulle bli lagt til i mailliste. Prøv igjen senere.'
        );
        setIsSubmitting(false);
        console.error(response);
        // Return, so startup is only created if
        // ... idehaver is also added to maillist
        return;
      }
    }
    // Create startup and redirect user to it
    dispatch(createStartupTS({ goToStartup, data }));
    setIsSubmitting(false);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            <h3>{t('startups.AddStartup')}</h3>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col-sm-12 b-r">
                <h4 className="m-t-none m-b">Startup</h4>
                <form className="m-t" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <input
                      type="text"
                      {...register('ideNavn', {
                        /* 
                          Required doesn't work on whitespace.
                          Validation removes whitespace, not allowing empty name:
                        */
                        required: 'Fyll inn idenavn',
                        validate: (value) => {
                          return !!value.trim();
                        },
                      })}
                      className="form-control"
                      placeholder={t('startups.IdeaName')}
                      style={{
                        fontSize: '12px',
                      }}
                    />
                    <InputErrorMessage errors={errors} inputName="ideNavn" />
                  </div>
                  <div className="form-group">
                    <textarea
                      {...register('ideBeskrivelse', {
                        required: 'Fyll inn idebeskrivelse',
                      })}
                      className="form-control"
                      placeholder={t('startups.ShortDescription')}
                      rows={6}
                      style={{
                        fontSize: '12px',
                      }}
                    />
                    <InputErrorMessage
                      errors={errors}
                      inputName="ideBeskrivelse"
                    />
                  </div>
                  <br />
                  <h4>{t('startups.TeamMembers')}</h4>
                  <div className="form-group">
                    <p style={{ fontStyle: 'italic' }}>
                      {t('startups.ChooseMembersAfterRegistering')}
                    </p>
                  </div>
                  <br />
                  <h4>{t('startups.Originator')}</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      {...register('navn', {
                        required: 'Fyll inn idehavernavn',
                      })}
                      className="form-control"
                      placeholder={t('startups.Originator')}
                      style={{
                        fontSize: '12px',
                      }}
                    />
                    <InputErrorMessage errors={errors} inputName="navn" />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      {...register('email', { required: 'Fyll inn epost' })}
                      className="form-control"
                      placeholder={t('startups.Email')}
                      style={{
                        fontSize: '12px',
                      }}
                    />
                    <InputErrorMessage errors={errors} inputName="email" />
                  </div>

                  {hostname === 'basen' ? (
                    <div className="form-group form-check">
                      <input
                        name="signupToNewsletter"
                        id="signupToNewsletter"
                        onChange={(e) =>
                          setSignupToNewsletter(e.target.checked)
                        }
                        type="checkbox"
                        className="form-check-input"
                        checked={signupToNewsletter}
                      />
                      <label
                        htmlFor="signupToNewsletter"
                        className="form-check-label ml-4"
                      >
                        Legg epost også til i nyhetsbrev
                      </label>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="form-group">
                    <input
                      type="text"
                      {...register('mobilnr', { required: false })}
                      className="form-control"
                      placeholder={t('startups.PhoneNumber')}
                      style={{
                        fontSize: '12px',
                      }}
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label className="control-label">
                      <h4>{t('startups.Gender')}</h4>
                    </label>
                    <br />
                    <input
                      type="radio"
                      value="m"
                      {...register('sex', { required: true })}
                    />
                    <label>{t('startups.Man')}</label>
                    <br />
                    <input
                      type="radio"
                      value="f"
                      {...register('sex', { required: true })}
                    />
                    <label>{t('startups.Woman')}</label>
                    <br />
                    <input
                      type="radio"
                      value="other"
                      {...register('sex', { required: 'Fyll inn kjønn' })}
                    />
                    <label>{t('startups.Other')}</label>
                    <InputErrorMessage errors={errors} inputName="sex" />
                    <br />
                  </div>
                  <br />
                  <div className="form-group">
                    <label className="control-label">
                      {t('startups.WhereDidYouHearOfSpark')}?{' '}
                    </label>
                    <div>
                      <Controller
                        control={control}
                        rules={{ required: 'Fyll inn hørte om Spark' }}
                        name="horteomspark"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            options={tagsList.horteomsparkOptions}
                          />
                        )}
                      />
                    </div>
                    <InputErrorMessage
                      errors={errors}
                      inputName="horteomspark"
                    />
                  </div>
                  <div className="form-group">
                    <h4>{t('startups.Institute')}</h4>
                    <Controller
                      control={control}
                      rules={{ required: 'Fyll inn studiested' }}
                      name="studieSted"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          onChange={onChange}
                          onBlur={onBlur}
                          options={tagsList.studieOptions}
                        />
                      )}
                    />
                    <InputErrorMessage errors={errors} inputName="studieSted" />
                  </div>
                  <div className="form-group">
                    <h4>{t('startups.ChooseMentor')}</h4>
                    <Controller
                      control={control}
                      rules={{ required: 'Fyll inn veileder' }}
                      name="veileder"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          onChange={onChange}
                          onBlur={onBlur}
                          options={users.map((user) => ({
                            value: user.uid,
                            label: `${user.firstname} ${user.lastname}`,
                          }))}
                        />
                      )}
                    />
                    <InputErrorMessage errors={errors} inputName="veileder" />
                  </div>
                  <button
                    className="btn btn-primary block full-width m-b"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t('startups.Register')}
                  </button>
                  <p>{t('startups.FillInMoreInformationAfterRegistering')}.</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewStartupForm;
