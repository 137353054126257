import React from 'react';
import { withTranslation } from 'react-i18next';

function DataDescription(props) {
  const { t, chosenDelimiter, allKeys } = props;

  return (
    <div>
      <p>{t('admin.TheDataGetsTheFollowingFormat')}:</p>
      <p className="text-break">{allKeys}</p>
      <ol>
        <li>
          Last ned og åpne fil i Excel (OBS: Excel viser ikke æøå, men bevarer
          fortsatt informasjon når det åpnes i annet program)
        </li>
        <li>Marker kolonne A</li>
        <li>I meny 'Data', trykk 'Tekst til kolonner'</li>
        <li>Velg 'Data med skilletegn'. Trykk neste</li>
        <li>
          {`Fjern valget 'Tabulator' under 'Skilletegn'. Kopier symbolet ${chosenDelimiter} og lim inn under 'Annet'-skilletegn`}
        </li>
        <li>Fullfør</li>
      </ol>
    </div>
  );
}

export default withTranslation('common')(DataDescription);
