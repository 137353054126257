function filterActiveTeam(startupList, teamList) {
  let activeTeamKeys = [];
  startupList
    .filter((startup) => startup.status.label === 'Aktiv')
    .map((startup) => {
      if (Object.keys(startup).includes('teammedlemmer')) {
        if (startup.teammedlemmer) {
          startup.teammedlemmer.map((member) => activeTeamKeys.push(member));
        }
      }
    });

  let activeTeamEmails = [];
  activeTeamKeys.map((key) => {
    // Simple email validation
    if (teamList[key].epost.includes('@')) {
      activeTeamEmails.push(teamList[key].epost);
    }
  });

  return activeTeamEmails;
}

export default filterActiveTeam;
