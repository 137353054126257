import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

function LackingInfoElement({ questionObj, object, handleOnChange, t }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (object[questionObj.value] !== undefined) {
      setChecked(object[questionObj.value].value);
    }
  }, [object, questionObj]);

  return (
    <div className="form-group col-lg-6">
      <label className="col-lg-6 control-label text-md-left text-lg-right">
        {questionObj.label}
      </label>
      <div className="col-lg-6 m-b pt-lg-2">
        <div className="col-lg-6">
          <label>
            <input
              type="radio"
              checked={checked === true ? true : ''}
              onChange={() => handleOnChange(questionObj, true)}
            />
            {t('admin.Yes')}
          </label>
        </div>
        <div className="col-lg-6">
          <label>
            <input
              type="radio"
              checked={checked === false ? true : ''}
              onChange={() => handleOnChange(questionObj, false)}
            />
            {t('admin.No')}
          </label>
        </div>
      </div>
    </div>
  );
}

export default withTranslation('common')(LackingInfoElement);
