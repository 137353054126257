import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export function deleteAlert(onCancel, onConfirm) {
  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText="Ja, slett den!"
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="default"
      title="Er du sikker?"
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      Slett møtenotatet?
    </SweetAlert>
  );
}

export function confirmAlert(onConfirm) {
  return (
    <SweetAlert success title="Ok!" onConfirm={() => onConfirm()}>
      Notatet har blitt slettet
    </SweetAlert>
  );
}
