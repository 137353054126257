import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  IStatusTypesLabel,
  IStatusTypesValue,
} from '../../../types/IStatusTypes';

// Label and value are the same,
// ... e.g. "{value: "TEB-ansvarlig", label: "TEB-ansvarlig"}"
interface IValgtStilling {
  label: string;
  value: string;
}

// E.g. {value: "1", label: "Alumni"}
interface IValgtStatus {
  label: IStatusTypesLabel;
  value: IStatusTypesValue;
}

interface ISelectVeileder {
  label: string; // Firstname Surname
  value: string; // user-uid
}

function updateUserStatus(
  valgtVeileder: ISelectVeileder,
  valgtStilling: IValgtStilling,
  valgtStatus: IValgtStatus
) {
  firebase
    .database()
    .ref(`users/${valgtVeileder.value}`)
    .update({
      position: valgtStilling.value,
      status: [valgtStatus],
    })
    .then(() => {
      console.log('Profile change success');
    })
    .catch((err) => {
      console.log(`Profile change error, should push out error.${err}`);
    });
}

export default updateUserStatus;
