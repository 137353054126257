import React from 'react';
import { Link } from 'react-router-dom';

interface IMentorStyling {
  row: any;
}

const MentorCellStyling = ({ row }: IMentorStyling) => {
  const supervisorName = row.original.supervisor.supervisorName;
  const supervisorValue = row.original.supervisor.value;

  return (
    <Link to={`/veiledere/${supervisorValue}`}>{<p>{supervisorName}</p>}</Link>
  );
};

export default MentorCellStyling;
