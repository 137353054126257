import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const requestStartupData = () => ({
  type: 'REQUEST_STARTUP_DATA',
});

const startupDataFetchedSuccessfully = (payload) => ({
  type: 'STARTUP_DATA_FETCHED_SUCCESSFULLY',
  payload,
});

const startupDataFetchError = (error) => ({
  type: 'STARTUP_DATA_FETCH_ERROR',
  error,
});

function fetchStartupData() {
  return (dispatch) => {
    dispatch(requestStartupData());
    firebase
      .database()
      .ref('startups')
      .on('value', (snapshot) => {
        const values = snapshot.val();
        Object.keys(values).forEach((key) => {
          if (values[key].idehaver.includes('idehavere')) {
            values[key].idehaver = values[key].idehaver.replace(
              '/idehavere/',
              ''
            );
          }
          if (values[key].meetingNotes === 'n/a') {
            values[key].meetingNotes = '';
          }
          if (values[key].registreringsDato === '0') {
            values[key].registreringsDato = new Date();
          }
        });
        dispatch(startupDataFetchedSuccessfully(values));
      });
  };
}

export default fetchStartupData;
