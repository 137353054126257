import React from 'react';

const FooterComponent = () => {
  return (
    <div className="footer">
      <div className="pull-right">sparkntnu.no ALPHA 1.8.C - Sept 28 11:53 </div>
    </div>
  );
};

export default FooterComponent;
