import { IAxiosResponse } from '../../../types/IAxiosResponse';
import { IMailchimpData } from '../../../types/IMailchimpData';

const axios = require('axios');

// Endpoint expects:
// {"email_address": "epost@epost.no", "signupToNewsletter": boolean}

// Used in production:
const remoteUrl = 'https://quiet-coast-10640.herokuapp.com/mailchimpsignup';
// Used for testing purposes:
const localUrl = 'http://localhost:5000/mailchimpsignup';

export const signUpToMailchimp = async (
  data: IMailchimpData
): Promise<IAxiosResponse> => {
  const response = await axios.post(remoteUrl, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};
