import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { saveNote } from './saveNote';
import DatePicker from 'react-datepicker';

function EditMeetingNote(props) {

  const {
    currentUser,
    closeEditModal,
    startupId,
    currentNote,
    noteId,
    postedDate,
    newNote,
  } = props;

  const [noteText, setNoteText] = useState('');
  const [date, setDate] = useState(postedDate);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (!newNote && currentNote) {
      setNoteText(currentNote.text);
    }
  }, [currentNote, newNote]);

  function editNote() {
    saveNote(startupId, newNote, noteId, noteText, currentUser, date);
    closeEditModal();
  }

  return (
    <div style={{ width: '700px', height: '620px' }}>
      <div className="modal-header">
        <h2 className="modal-title">
          {newNote ? t('startups.NewNote') : t('startups.EditMeetingNote')}
        </h2>
      </div>
      <div className="modal-body">
        <p>
          Endre innhold
        </p>
        <textarea
          style={{ height: '280px' }}
          value={noteText}
          placeholder={noteText}
          className="form-control"
          onChange={(e) => setNoteText(e.target.value)}
        />
        <p>
          Endre dato
        </p>
        <DatePicker
          selected={date}
          onChange={e => {
            const dateFormatted = new Date(e).valueOf();
            setDate(dateFormatted);
          }}
        />
        {/*BRUK DENNE PÅ SIKT: https://mui.com/components/date-picker/*/}
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-white"
          onClick={closeEditModal}
        >
          {t('startups.Close')}
        </button>
        <button type="button" className="btn btn-primary" onClick={editNote}>
          {t('startups.Save')}
        </button>
      </div>
    </div>
  );
};

export default EditMeetingNote;
