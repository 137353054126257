import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import meetingNotesData from './meetingNotesData';
import exportToCsv from './exportToCsv';
import DataDescription from './DataDescription';
import AdminFrame from '../AdminFrame';

function ExportMeetingNotesToCSV(props) {
  const { startupList, t, userList } = props;
  const [meetingDateStart, setMeetingDateStart] = useState(
    new Date().getTime()
  );
  const [meetingDateEnd, setMeetingDateEnd] = useState(new Date().getTime());
  const [numberOfResults, setNumberOfResults] = useState(0);
  const [allMeetingNotes, setAllMeetingNotes] = useState([]);
  const [allKeys, setAllKeys] = useState('');
  const chosenDelimiter = '~';

  useEffect(() => {
    const values = meetingNotesData(
      startupList,
      userList,
      meetingDateStart,
      meetingDateEnd,
      chosenDelimiter
    );
    setAllKeys(values[1]);
    setAllMeetingNotes(values[0]);
  }, [meetingDateStart, meetingDateEnd]);

  useEffect(() => {
    setNumberOfResults(allMeetingNotes.length);
  }, [allMeetingNotes]);

  const [downloading, setDownloading] = useState(false);

  function handleExportToCsv() {
    exportToCsv(allMeetingNotes, chosenDelimiter, setDownloading);
  }

  return (
    <AdminFrame
      title="Eksporter møteinfo til CSV"
      content={
        <div>
          <p>Velg start- og slutt-dato du ønsker å eksportere.</p>
          <div className="form-row">
            <div className="form-group col-sm-12">
              <label className="col-12 pl-0">Filter start</label>
              <DatePicker
                selected={new Date(meetingDateStart)}
                onChange={(e) => setMeetingDateStart(e.getTime())}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="form-group col-sm-12">
              <label className="col-12 pl-0">Filter slutt</label>
              <DatePicker
                selected={new Date(meetingDateEnd)}
                onChange={(e) => setMeetingDateEnd(e.getTime())}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <p>
            <strong>Antall resultater:</strong> {numberOfResults}
          </p>

          <hr />
          <DataDescription
            chosenDelimiter={chosenDelimiter}
            allKeys={allKeys}
          />
          <button
            className="btn btn-primary block full-width m-b"
            type="submit"
            disabled={downloading}
            onClick={() => handleExportToCsv()}
            style={{ background: 'red' }}
          >
            {t('admin.Download')}
          </button>
        </div>
      }
    />
  );
}

export default withTranslation('common')(ExportMeetingNotesToCSV);
