import React from 'react';
import ProjectStatusLabel from '../../../../common/ProjectStatusLabel';

interface IStatusCellStyling {
  row: any;
}

const StatusCellStyling = ({ row }: IStatusCellStyling) => {
  const statusValue = row.original.status.statusValue;

  return <ProjectStatusLabel statusValue={statusValue} />;
};

export default StatusCellStyling;
