import React from 'react';
import TronderEnergiAllYears from './TronderEnergiAllYears';
import TronderEnergiKeyFigures from './TronderEnergiKeyFigures';
import TronderEnergiSokereAllYears from './TronderEnergiSokereAllYears';

const TronderEnergiBidraget = (props) => {

  const { TEBNumbers } = props;

  let rounds = 0;
  let sokere = 0;
  let sokt_stotte = 0;
  let innvilget_stotte = 0;

  Object.values(TEBNumbers).forEach(round => {
    rounds += 1;
    sokere += round["antall_sokere"]
    sokt_stotte += round["sokt_stotte"]
    innvilget_stotte += round["innvilget_stotte"]
  });

  const roundNumbers = [];
  const sokt = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const innvilget = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const years = {};
  const categories = [];
  const sokere_per_year = []

  Object.values(TEBNumbers).forEach(round => {
    const date = new Date(round["dato"] * 1000);
    roundNumbers.push(round["runde"]);
    sokt[date.getMonth()] = round["sokt_stotte"];
    innvilget[date.getMonth()] = round["innvilget_stotte"];

    if (!years[date.getFullYear()]) {
      categories.push(date.getFullYear());
      years[date.getFullYear()] = 0;
    }
    years[date.getFullYear()] += round["antall_sokere"];
  });

  Object.values(years).forEach(year => {
    sokere_per_year.push(year);
  });

  return (
    <>
      <div className="col-lg-12">

        <TronderEnergiKeyFigures rounds={rounds} sokere={sokere} innvilget_stotte={innvilget_stotte} sokt_stotte={sokt_stotte} />

        <TronderEnergiAllYears rounds={TEBNumbers} />

        <TronderEnergiSokereAllYears sokere={sokere_per_year} sokt={sokt} years={categories} />

      </div>

    </>
  );
};

export default TronderEnergiBidraget;