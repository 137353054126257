/* Used only in DeleteStartup.tsx */

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhaseComponent from '../PhaseComponent';
import ProjectStatusLabel from '../../common/ProjectStatusLabel';
import { IIdehaver } from '../../../types/IIdehaver';
import { IStartup } from '../../../types/IStartup';
import IVeileder from '../../../types/IVeileder';
import ITag from '../../../types/ITag';

interface IStartupTableRow {
  ide: IStartup;
  idehaver: IIdehaver;
  veileder: IVeileder;

  superKey: string;
}

// Iterate over tags stored in a startup, and return components to be displayed
const tagLabels = (tags: ITag[]) => {
  let tagLabels: {} = [];
  let i = 0;

  try {
    tagLabels = tags.map((tag) => {
      i++;

      return (
        <p key={i} className="btn btn-sm btn-white tag-btn m-t-xs">
          {' '}
          {tag.label}{' '}
        </p>
      );
    });
  } catch {
    console.log('Invalid tag format');
  }

  return tagLabels;
};

const StartupTableRow = ({
  ide,
  superKey,
  veileder,
  idehaver,
}: IStartupTableRow) => {
  const { t } = useTranslation('common');

  return (
    <tr>
      <ProjectStatusLabel statusValue={ide.status.value} />
      <td className="project-title">
        <Link to={`/startups/${superKey}`}>{ide.ideNavn}</Link>
        <br />
        <small>
          {t('startups.Originator')}
          {': '}
          {idehaver.navn}
        </small>
      </td>
      <td className="project-completion">
        <PhaseComponent phase={ide.fase} />
      </td>
      <td className="project-people">
        <Link to={`/veiledere/${veileder.value}`}>{veileder.label}</Link>
      </td>

      <td className="project-actions">{ide.bransje.label}</td>

      <td className="project-actions">{tagLabels(ide.tags)}</td>
    </tr>
  );
};

export default StartupTableRow;
