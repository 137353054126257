import React from 'react';
import { useTranslation } from 'react-i18next';

type PhaseComponentTypes = {
  statusValue: number;
};

const ProjectStatusLabel = ({ statusValue }: PhaseComponentTypes) => {
  const { t } = useTranslation('common');

  let projectLabel;
  switch (statusValue) {
    case 0:
      projectLabel = <span className="label label-default">Død</span>;
      break;
    case 1:
      projectLabel = (
        <span className="label label-success">{t('startups.Active')}</span>
      );
      break;
    case 2:
      projectLabel = <span className="label label-warning">Alumni</span>;
      break;
    default:
      projectLabel = (
        <span className="label label-info">{t('startups.Inactive')}</span>
      );
      break;
  }
  return <> {projectLabel} </>;
};

export default ProjectStatusLabel;
