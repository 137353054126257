import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { arbStatus, arbStillingValg } from '../../../constants/index';
import AdminFrame from '../AdminFrame';
import { useTranslation } from 'react-i18next';
import updateUserStatus from './updateUserStatus';
import { IPosition } from '../../../types/IPosition';

function AdminUserControl({ users, thisUser }) {
  const { t } = useTranslation('common');

  const [valgtVeileder, setVeileder] = useState(null);
  const [valgtStilling, setValgtStilling] = useState(null);
  const [valgtStatus, setValgtStatus] = useState(null);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [shownUsers, setShownUsers] = useState(users);
  const [filteredPositions, setFilteredPositions] = useState(arbStillingValg);

  // IT has admin rights, so only letting IT
  // ... change positions to IT.
  // If not IT, filtering away IT as an option.
  useEffect(() => {
    let filtered = arbStillingValg;
    if (thisUser.position !== IPosition.IT) {
      filtered = filtered.filter((pos) => pos.value !== IPosition.IT);
    }
    setFilteredPositions(filtered);
  }, [thisUser]);

  // Used for button toggle. Show only active users or show all
  useEffect(() => {
    if (showActiveOnly) {
      setShownUsers(users.filter((user) => user.status[0].label === 'Aktiv'));
    } else {
      setShownUsers(users);
    }
  }, [showActiveOnly]);

  function handleStillingChange(e) {
    setValgtStilling(e);
  }

  function handleStatusChange(e) {
    setValgtStatus(e);
  }

  function handleVeilederChange(e) {
    setVeileder(e);
    const selectedUser = users.find((user) => user.uid === e.value);
    const position = {
      label: selectedUser.position,
      value: selectedUser.position,
    };
    setValgtStilling(position);
    setValgtStatus(selectedUser.status[0]);
  }

  function onSubmit(e) {
    e.preventDefault();
    updateUserStatus(valgtVeileder, valgtStilling, valgtStatus);
    setValgtStatus('');
    setValgtStilling('');
    setVeileder('');
  }

  return (
    <AdminFrame
      title={t('admin.UserControl')}
      content={
        <div>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => setShowActiveOnly(!showActiveOnly)}
          >
            {showActiveOnly
              ? 'Velg blant alle ansatte'
              : 'Velg blant aktive ansatte'}
          </button>
          <form className="m-t" onSubmit={onSubmit}>
            <div className="form-group">
              <h4>{t('admin.ChooseUser')}</h4>
              <Select
                name="veileder-input"
                value={valgtVeileder}
                options={shownUsers.map((user) => ({
                  value: user.uid,
                  label: `${user.firstname} ${user.lastname}`,
                }))}
                onChange={handleVeilederChange}
              />
            </div>
            <div className="form-group">
              <h4>{t('profile.Position')}:</h4>
              <Select
                name="position-input"
                value={valgtStilling}
                options={filteredPositions}
                onChange={handleStillingChange}
              />
            </div>
            <div className="form-group">
              <h4>Status:</h4>
              <Select
                name="status-input"
                value={valgtStatus}
                options={arbStatus}
                onChange={handleStatusChange}
              />
            </div>
            <button
              className="btn btn-primary block full-width m-b"
              type="submit"
              disabled={valgtVeileder === '' || valgtStilling === ''}
            >
              {t('startups.Save')}
            </button>
          </form>
        </div>
      }
    />
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(AdminUserControl);
