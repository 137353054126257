import React from 'react';

type DashboardIconWidgetProps = {
  iconImageUrl: string;
  number: number;
  title: string;
};

const DashboardIconWidget = ({
  iconImageUrl,
  number,
  title,
}: DashboardIconWidgetProps) => {
  return (
    <div className="ibox ibox-content">
      <div style={{ textAlign: 'center' }}>
        <h1 className="font-color-orange">
          <span className="font-bold">{title}</span> startups
        </h1>
        <img
          alt="icon"
          style={{ height: '129px', width: 'auto' }}
          src={iconImageUrl}
        />
        <h1 style={{ fontSize: '4em' }}>{number}</h1>
      </div>
    </div>
  );
};

export default DashboardIconWidget;
