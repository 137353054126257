import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IWelcomeAlert {
  showWelcomeAlert:
    | boolean
    | ((value: boolean | ((val: boolean) => boolean)) => void);
  /* setShowWelcomeAlert:
    | boolean
    | ((value: boolean | ((val: boolean) => boolean)) => void); */
  setShowWelcomeAlert: any;
}

const WelcomeAlert = ({ setShowWelcomeAlert }: IWelcomeAlert) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="alert alert-info" role="alert">
        <p>
          {t('meetingNotes.WelcomeAlert.AboutMarkDown')}{' '}
          <a
            target="_blank"
            href={'https://www.markdownguide.org/cheat-sheet/'}
          >
            {t('meetingNotes.WelcomeAlert.Guide')}
          </a>
        </p>

        <hr />
        <p className="mb-2">{t('meetingNotes.WelcomeAlert.UseOldTool')} </p>
        <Button variant={'primary'} onClick={() => setShowWelcomeAlert(false)}>
          {t('meetingNotes.WelcomeAlert.IUnderstand')}{' '}
        </Button>
      </div>
    </>
  );
};

export default WelcomeAlert;
