export enum IPosition {
  LEDER = 'Leder',
  KPLEDER = 'KP-leder',
  TEBANSVARLIG = 'TEB-ansvarlig',
  PROMOSJEF = 'Promosjef',
  VEILEDERSJEF = 'Veiledersjef',
  NESTLEDER = 'Nestleder',
  ARRANGØRANSVARLIG = 'Arrangøransvarlig',
  VEILEDER = 'Veileder',
  PROMO = 'Promo',
  IT = 'IT',
  KVINNEPROSJEKTET = 'Kvinneprosjektet',
  ANNET = 'Annet',
}
