import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import MainContainer from './MainContainer';
import AuthContainer from './AuthContainer.tsx';
import AuthHandler from './AuthHandler'; // Import the new component

function Root({ store }) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthHandler /> 
        <Routes>
          <Route path="/*" element={<MainContainer />} />
          <Route path="/auth/*" element={<AuthContainer />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default Root;
