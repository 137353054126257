const initialState = {
  fetchingData: true,
  updating: null,
  error: null,
  teamList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_TEAMMEMBERS_DATA':
      return {
        ...state,
        fetchingData: true,
      };
    case 'TEAMMEMBERS_DATA_FETCHED_SUCCESSFULLY':
      return {
        fetchingData: false,
        error: null,
        teamList: action.payload,
      };
    case 'TEAMMEMBERS_DATA_FETCH_ERROR':
      return {
        fetchingData: false,
        error: action.error,
        teamList: null,
      };
    default:
      return state;
  }
};
