import React from 'react';

export const StatisticsPicker = (props) => {

    const { currentView, changeViewCallback } = props;

    return (
        <ul className="nav nav-pills nav-fill mb-5">
            <li className="nav-item">
                <a className={`nav-link ${currentView == 'startups' ? "active" : null}`} onClick={() => changeViewCallback("startups")}>
                    Startups
                </a>
            </li>
            <li className="nav-item">
                <a className={`nav-link ${currentView == 'teb' ? "active" : null}`} onClick={() => changeViewCallback("teb")}>
                    ANEO-Bidraget
                </a>
            </li>
            <li className="nav-item">
                <a className={`nav-link ${currentView == 'meetingnotes' ? "active" : null}`} onClick={() => changeViewCallback("meetingnotes")}>
                    Møtenotater
                </a>
            </li>
            <li className="nav-item">
                <a className={`nav-link ${currentView == 'gender' ? "active" : null}`} onClick={() => changeViewCallback("gender")}>
                    Kjønn
                </a>
            </li>
            <li className="nav-item">
                <a className={`nav-link ${currentView == 'faculty' ? "active" : null}`} onClick={() => changeViewCallback("faculty")}>
                    Studieretning
                </a>
            </li>
        </ul>
    )
}