import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';

import feedback from './feedback';
import { feedbackMessages } from '../../constants';

export const requestStartupCreate = () => ({
  type: 'REQUEST_STARTUP_CREATE',
});

export const startupCreateSuccess = () => ({
  type: 'STARTUP_CREATE_SUCCESS',
});

export const startupCreateError = (error) => ({
  type: 'STARTUP_CREATE_ERROR',
  error,
});

function createStartup(idehaverTeam, startupData, idehaverData, goToStartup) {
  return (dispatch) => {
    dispatch(requestStartupCreate());

    // Assign a new startup key
    const newStartupKey = firebase.database().ref().child('startups').push()
      .key;

    // Assign a new idehaver key
    const newIdehaverKey = firebase.database().ref().child('idehaver').push()
      .key;

    const updates = {};

    const teamKeys = Object.keys(idehaverTeam).map((key) => {
      updates[`/teammembers/${idehaverTeam[key].uid}`] = idehaverTeam[key];
      return idehaverTeam[key].uid;
    });

    startupData.idehaver = newIdehaverKey;
    startupData.teammedlemmer = teamKeys;
    startupData.registreringsDato = firebase.database.ServerValue.TIMESTAMP;

    updates[`/startups/${newStartupKey}`] = startupData;
    updates[
      `/users/${startupData.veileder.value}/startups/${newStartupKey}`
    ] = newStartupKey;
    updates[`/idehavere/${newIdehaverKey}`] = idehaverData;

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        feedback.success(feedbackMessages.startupCreateSuccess);
        dispatch(startupCreateSuccess());
        goToStartup(newStartupKey);
      })
      .catch((err) => {
        feedback.error(err);
        dispatch(startupCreateError(err.message));
      });
  };
}

export default createStartup;
