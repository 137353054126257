import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import feedback from './feedback';
import { feedbackMessages } from '../../constants';

const requestStartupUpdate = () => ({
  type: 'REQUEST_STARTUP_DELETE',
});

const startupUpdateSuccess = () => ({
  type: 'DELETE_STARTUP_SUCCESS',
});

const startupUpdateError = (error) => ({
  type: 'DELETE_STARTUP_ERROR',
  error,
});

function startupDelete(startupKey, idehaverKey, veilederKey, teamKeys) {
  return (dispatch) => {
    dispatch(requestStartupUpdate());
    const updates = {};
    updates[`/startups/${startupKey}`] = null;
    updates[`/idehavere/${idehaverKey}`] = null;
    updates[`/users/${veilederKey}/startups/${startupKey}`] = null;
    if (teamKeys.length > 0)
      teamKeys.map((teamKey) => (updates[`/teammembers/${teamKey}`] = null));

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        feedback.success(feedbackMessages.startupDeleteSuccess);
        dispatch(startupUpdateSuccess());
      })
      .catch((err) => {
        feedback.error(err);
        dispatch(startupUpdateError(err.message));
      });
  };
}

export default startupDelete;
