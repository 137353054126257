import React from 'react';

const LoadingSquare = () => {
  return (
    <div className="spinner-example">
      <div className="sk-spinner sk-spinner-cube-grid">
        <div className="sk-cube" />
        <div className="sk-cube" />
        <div className="sk-cube" />
        <div className="sk-cube" />
        <div className="sk-cube" />
        <div className="sk-cube" />
        <div className="sk-cube" />
        <div className="sk-cube" />
        <div className="sk-cube" />
      </div>
    </div>
  );
};

export default LoadingSquare;
