import hostname from '../config/hostname';

export const teamMemberStatus = [
  { value: 'New', label: 'New' },
  { value: 'Match', label: 'Match' },
  { value: 'In Team', label: 'In Team' },
  { value: 'Archived', label: 'Archived' },
];

export const horteomsparkOpts = [
  { value: 'På stand', label: 'På stand' },
  { value: 'I forelesning', label: 'I forelesning' },
  { value: 'Fra en venn', label: 'Fra en venn' },
  { value: 'Fra ntnu-stab', label: 'Fra NTNU-stab' },
  { value: 'Kvinneprosjektet', label: 'Kvinneprosjektet' },
  { value: 'Sosiale medier', label: 'Sosiale medier' },
  { value: 'Annet', label: 'Annet' },
];

export const arbStatus = [
  { value: '0', label: 'Aktiv' },
  { value: '1', label: 'Alumni' },
];

export const leaderGroup = [
  { value: 'Leder', label: 'Leder' },
  { value: 'KP-leder', label: 'KP-leder' },
  { value: 'TEB-ansvarlig', label: 'TEB-ansvarlig' },
  { value: 'Promosjef', label: 'Promosjef' },
  { value: 'Veiledersjef', label: 'Veiledersjef' },
  { value: 'Nestleder', label: 'Nestleder' },
  { value: 'Arrangøransvarlig', label: 'Arrangøransvarlig' },
];

export const arbStillingValg = [
  ...leaderGroup,
  { value: 'Veileder', label: 'Veileder' },
  { value: 'Promo', label: 'Promo' },
  { value: 'IT', label: 'IT' },
  { value: 'Kvinneprosjektet', label: 'Kvinneprosjektet' },
  { value: 'Annet', label: 'Annet' },
];

export const filterIdeStatus = [
  { value: 0, label: 'Død' },
  { value: 1, label: 'Aktiv' },
  { value: 2, label: 'Alumni' },
  { value: 3, label: 'Dvale' },
  { value: 4, label: 'Ny ide' },
];

export const filterIdePhase = [
  { value: 25, label: 'Idefase' },
  { value: 50, label: 'Konseptfase' },
  { value: 75, label: 'Validering' },
  { value: 100, label: 'Skalering' },
];

export const tagOptions = [
  { value: 'hardware', label: 'Hardware' },
  { value: 'software', label: 'Software' },
  { value: 'b2b', label: 'B2B' },
  { value: 'b2c', label: 'B2C' },
  { value: 'Produktutvikling', label: 'Produktutvikling' },
  { value: 'AI', label: 'AI' },
  { value: 'Web', label: 'Web' },
  { value: 'app', label: 'App' },
  { value: 'IOS', label: 'IOS' },
  { value: 'Android', label: 'Android' },
  { value: 'gadget', label: 'Gadget' },
  { value: 'bigdata', label: 'Big Data' },
  { value: 'cloud', label: 'Cloud' },
  { value: 'hiring', label: 'hiring' },
  { value: 'forskning', label: 'Forskning' },
  { value: 'plattform', label: 'Plattform' },
  { value: 'b2b2c', label: 'B2B2C' },
  { value: 'unicorn', label: 'Unicorn' },
  { value: 'spinoff', label: 'Spin Off' },
  { value: 'markedsføring', label: 'Markedsføring' },
  { value: 'økonomi', label: 'Økonomi' },
  { value: 'produksjon', label: 'Produksjon' },
  { value: 'kickstarter', label: 'Kickstarter' },
  { value: 'public relations', label: 'Public relations' },
  { value: 'sales', label: 'Sales' },
  { value: 'digital marketing', label: 'Digital marketing' },
  { value: 'some', label: 'SOME' },
  { value: 'IoT', label: 'IoT' },
  { value: 'Machine Learning', label: 'Machine Learning' },
  { value: 'Blockchain', label: 'Blockchain' },
];

export const bransjeOptions = [
  { value: 'Fintech', label: 'Fintech' },
  { value: 'Edtech', label: 'Edtech' },
  { value: 'Green Tech', label: 'Green Tech' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Gaming', label: 'Gaming' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Health Tech', label: 'Health Tech' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Cybersecurity', label: 'Cybersecurity' },
  { value: 'IOT', label: 'IOT' },
  { value: 'nonprofit', label: 'Non profit' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Food', label: 'Food' },
  { value: 'travel', label: 'Travel & Tourism' },
  { value: 'ecommerce', label: 'Ecommerce' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Sport', label: 'Sport' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'Logistics', label: 'Logistics' },
  { value: 'Real estate', label: 'Real Estate' },
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Platforms', label: 'Platforms' },
  { value: 'consumer', label: 'Consumers' },
  { value: 'Enterprises', label: 'Enterprises' },
  { value: 'Information Technologies', label: 'Information Tech' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Agriculture', label: 'Agriculture' },
];

const studieOptions = [
  {
    value: 'Institutt for filosofi og religionsvitenskap (HF)',
    label: 'Institutt for filosofi og religionsvitenskap (HF)',
  },
  {
    value: 'Institutt for historiske studier (HF)',
    label: 'Institutt for historiske studier (HF)',
  },
  {
    value: 'Institutt for kunst- og medievitenskap (HF)',
    label: 'Institutt for kunst- og medievitenskap (HF)',
  },
  { value: 'Institutt for musikk (HF)', label: 'Institutt for musikk (HF)' },
  {
    value: 'Institutt for språk og litteratur (HF)',
    label: 'Institutt for språk og litteratur (HF)',
  },
  {
    value: 'Institutt for tverrfaglige kulturstudier (HF)',
    label: 'Institutt for tverrfaglige kulturstudier (HF)',
  },
  {
    value: 'Institutt for arkitektur og planlegging (AD)',
    label: 'Institutt for arkitektur og planlegging (AD)',
  },
  {
    value: 'Institutt for arkitektur og teknologi (AD)',
    label: 'Institutt for arkitektur og teknologi (AD)',
  },
  { value: 'Institutt for design (AD)', label: 'Institutt for design (AD)' },
  {
    value: 'Kunstakademiet i Trondheim (AD)',
    label: 'Kunstakademiet i Trondheim (AD)',
  },
  {
    value: 'Institutt for allmennfag (IE)',
    label: 'Institutt for allmennfag (IE)',
  },
  {
    value: 'Institutt for datateknologi og informatikk (IE)',
    label: 'Institutt for datateknologi og informatikk (IE)',
  },
  {
    value: 'Institutt for elektroniske systemer (IE)',
    label: 'Institutt for elektroniske systemer (IE)',
  },
  {
    value: 'Institutt for elkraftteknikk (IE)',
    label: 'Institutt for elkraftteknikk (IE)',
  },
  {
    value: 'Institutt for IKT og realfag (IE)',
    label: 'Institutt for IKT og realfag (IE)',
  },
  {
    value:
      'Institutt for informasjonssikkerhet og kommunikasjonsteknologi (IE)',
    label:
      'Institutt for informasjonssikkerhet og kommunikasjonsteknologi (IE)',
  },
  {
    value: 'Institutt for matematiske fag (IE)',
    label: 'Institutt for matematiske fag (IE)',
  },
  {
    value: 'Institutt for teknisk kybernetikk (IE)',
    label: 'Institutt for teknisk kybernetikk (IE)',
  },
  {
    value: 'Institutt for bygg- og miljøteknikk (IV)',
    label: 'Institutt for bygg- og miljøteknikk (IV)',
  },
  {
    value: 'Institutt for energi- og prosessteknikk (IV)',
    label: 'Institutt for energi- og prosessteknikk (IV)',
  },
  {
    value: 'Institutt for geovitenskap og petroleum (IV)',
    label: 'Institutt for geovitenskap og petroleum (IV)',
  },
  {
    value: 'Institutt for havromsoperasjoner og byggteknikk (IV)',
    label: 'Institutt for havromsoperasjoner og byggteknikk (IV)',
  },
  {
    value: 'Institutt for konstruksjonsteknikk (IV)',
    label: 'Institutt for konstruksjonsteknikk (IV)',
  },
  {
    value: 'Institutt for marin teknikk (IV)',
    label: 'Institutt for marin teknikk (IV)',
  },
  {
    value: 'Institutt for maskinteknikk og produksjon (IV)',
    label: 'Institutt for maskinteknikk og produksjon (IV)',
  },
  {
    value: 'Institutt for vareproduksjon og byggteknikk (IV)',
    label: 'Institutt for vareproduksjon og byggteknikk (IV)',
  },
  {
    value: 'Institutt for helsevitenskap i Gjøvik (MH)',
    label: 'Institutt for helsevitenskap i Gjøvik (MH)',
  },
  {
    value: 'Institutt for helsevitenskap i Ålesund (MH)',
    label: 'Institutt for helsevitenskap i Ålesund (MH)',
  },
  {
    value: 'Institutt for kreftforskning og molekylær medisin (MH)',
    label: 'Institutt for kreftforskning og molekylær medisin (MH)',
  },
  {
    value: 'Institutt for laboratoriemedisin, barne- og kvinnesykdommer (MH)',
    label: 'Institutt for laboratoriemedisin, barne- og kvinnesykdommer (MH)',
  },
  {
    value: 'Institutt for nevromedisin og bevegelsesvitenskap (MH)',
    label: 'Institutt for nevromedisin og bevegelsesvitenskap (MH)',
  },
  {
    value: 'Institutt for psykisk helse (MH)',
    label: 'Institutt for psykisk helse (MH)',
  },
  {
    value: 'Institutt for samfunnsmedisin og sykepleie (MH)',
    label: 'Institutt for samfunnsmedisin og sykepleie (MH)',
  },
  {
    value: 'Institutt for sirkulasjon og bildediagnostikk (MH)',
    label: 'Institutt for sirkulasjon og bildediagnostikk (MH)',
  },
  {
    value: 'Kavliinstitutt for nevrovitenskap (MH)',
    label: 'Kavliinstitutt for nevrovitenskap (MH)',
  },
  {
    value: 'Institutt for bioingeniørfag (NV)',
    label: 'Institutt for bioingeniørfag (NV)',
  },
  { value: 'Institutt for biologi (NV)', label: 'Institutt for biologi (NV)' },
  {
    value: 'Institutt for biologiske fag Ålesund (NV)',
    label: 'Institutt for biologiske fag Ålesund (NV)',
  },
  {
    value: 'Institutt for bioteknologi og matvitenskap (NV)',
    label: 'Institutt for bioteknologi og matvitenskap (NV)',
  },
  { value: 'Institutt for fysikk (NV)', label: 'Institutt for fysikk (NV)' },
  { value: 'Institutt for kjemi (NV)', label: 'Institutt for kjemi (NV)' },
  {
    value: 'Institutt for kjemisk prosessteknologi (NV)',
    label: 'Institutt for kjemisk prosessteknologi (NV)',
  },
  {
    value: 'Institutt for materialteknologi (NV)',
    label: 'Institutt for materialteknologi (NV)',
  },
  {
    value: 'Institutt for geografi (SU)',
    label: 'Institutt for geografi (SU)',
  },
  {
    value: 'Institutt for lærerutdanning (SU)',
    label: 'Institutt for lærerutdanning (SU)',
  },
  {
    value: 'Institutt for pedagogikk og livslang læring (SU)',
    label: 'Institutt for pedagogikk og livslang læring (SU)',
  },
  {
    value: 'Institutt for psykologi (SU)',
    label: 'Institutt for psykologi (SU)',
  },
  {
    value: 'Institutt for sosialantropologi (SU)',
    label: 'Institutt for sosialantropologi (SU)',
  },
  {
    value: 'Institutt for sosialt arbeid (SU)',
    label: 'Institutt for sosialt arbeid (SU)',
  },
  {
    value: 'Institutt for sosiologi og statsvitenskap (SU)',
    label: 'Institutt for sosiologi og statsvitenskap (SU)',
  },
  {
    value: 'Institutt for industriell økonomi og teknologiledelse (ØK)',
    label: 'Institutt for industriell økonomi og teknologiledelse (ØK)',
  },
  {
    value: 'Institutt for internasjonal forretningsdrift (ØK)',
    label: 'Institutt for internasjonal forretningsdrift (ØK)',
  },
  {
    value: 'Institutt for samfunnsøkonomi (ØK)',
    label: 'Institutt for samfunnsøkonomi (ØK)',
  },
  { value: 'NTNU Handelshøyskolen (ØK)', label: 'NTNU Handelshøyskolen (ØK)' },
  {
    value: 'Institutt for arkeologi og kulturhistorie (VM)',
    label: 'Institutt for arkeologi og kulturhistorie (VM)',
  },
  {
    value: 'Institutt for naturhistorie (VM)',
    label: 'Institutt for naturhistorie (VM)',
  },
];

const englishFeedback = {
  profileSuccess: 'Profile updated',
  genderSuccess: 'Updated gender',
  TEBNumbersSuccess: 'Updated TEB key numbers',
  startupCreateSuccess: 'Startup created',
  startupDeleteSuccess: 'Startup deleted',
  startupEditSuccess: 'Information changed',
  teammemberDeleteSuccess: 'Teammember deleted',
  meetingNoteAddedSuccsess: 'Meeting note added',
  meetingNoteChangedSuccess: 'Meeting note changed',
  tagUpdateSuccess: 'Tag changed',
  lackingInfoUpdateSuccess: 'Updated lacking info',
  lackingMeetingNoteText: 'Cannot publish empty note',
};

const norwegianFeedback = {
  profileSuccess: 'Profil oppdatert',
  genderSuccess: 'Oppdatert kjønn',
  TEBNumbersSuccess: 'Oppdaterte nøkkeltall',
  startupCreateSuccess: 'Startup opprettet',
  startupDeleteSuccess: 'Startup slettet',
  startupEditSuccess: 'Informasjon endret',
  teammemberDeleteSuccess: 'Teammedlem slettet',
  meetingNoteAddedSuccsess: 'Møtenotat publisert',
  meetingNoteChangedSuccess: 'Møtenotat endret',
  tagUpdateSuccess: 'Tag endret',
  lackingInfoUpdateSuccess: 'Varsel om mangler endret',
  lackingMeetingNoteText: 'Kan ikke publisere et tomt møtenotat',
};

export let feedbackMessages;

if (hostname === 'spark-english') {
  feedbackMessages = englishFeedback;
} else {
  feedbackMessages = norwegianFeedback;
}
