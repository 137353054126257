// Makes userobjects to a list and adds uid to userobject.

function makeUserArray(users) {
  const userArray = Object.keys(users.userList).map((key) => {
    const user = users.userList[key];
    user.uid = key;
    if (!Array.isArray(user.tags)) {
      user.tags = [user.tags];
    }
    return user;
  });
  return userArray;
}

export default makeUserArray;
