import React from 'react';
import { FaBullhorn, FaDesktop, FaStar, FaUser } from 'react-icons/fa';

interface IUserIcon {
  position: string;
}

// Return icon based on where the user works
const UserIcon = ({ position }: IUserIcon) => {
  switch (position) {
    case 'Veileder':
      return <FaUser />;
    case 'Promo':
      return <FaBullhorn />;
    case 'IT':
      return <FaDesktop />;
    default:
      return <FaStar />;
  }
};

export default UserIcon;
