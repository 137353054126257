import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';

function MeetingNotesByYear(props) {
    const { startups, year, t } = props;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const series = [];
    const counter = {};

    months.forEach(month => {
        counter[month] = 0;
    });

    function isObject(variable) {
        return typeof variable === 'object' && variable !== null;
    }

    Object.values(startups).map((startup) => {
        if (isObject(startup.meetingNotes)) {
            Object.values(startup.meetingNotes).map((note) => {
                const date = new Date(note.postedOn);
                if (date.getFullYear() === year) {
                    counter[months[date.getMonth()]] += 1;
                }
            })
        }
    });

    for (let i = 0; i < 12; i++) {
        series.push(counter[months[i]]);
    };

    const startupByYearChart = {
        chart: {
            type: 'line',
        },
        exporting: {
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'center',
                verticalAlign: 'bottom',
                y: 40,
            },
        },
        title: {
            text: year,
        },
        xAxis: {
            categories: months,
        },
        series: [{
            name: "Antall notater",
            data: series
        }],
    };

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType="chart"
                options={startupByYearChart}
            />
        </>
    );
}

export default withTranslation('common')(MeetingNotesByYear);