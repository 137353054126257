import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import lackingInfoUpdate from '../../redux/actions/lackingInfoUpdate';
import LackingInfoElement from './LackingInfoElement';
import hostname from '../../config/hostname';
import AdminFrame from './AdminFrame';

function LackingInfoBoxAdmin(props) {
  const { t, dispatch, lackingInfo } = props;

  const startupQ = [
    {
      label: t('startups.Industry'),
      value: 'bransje',
    },
    {
      label: t('startups.WebPage'),
      value: 'nettside',
    },
    {
      label: 'Org.nr.',
      value: 'organisasjonsNr',
    },
    {
      label: t('startups.Support'),
      value: 'stotte',
    },
    {
      label: t('startups.SupportType'),
      value: 'stotteType',
    },
  ];

  const idehaverQ = [
    {
      label: t('startups.Age'),
      value: 'alder',
    },
    {
      label: t('startups.WorkExperience'),
      value: 'arbeidserfaring',
    },
    {
      label: t('startups.ParticipatedCourses'),
      value: 'deltattKurs',
    },
    {
      label: t('startups.Email'),
      value: 'email',
    },
    {
      label: t('startups.ExperiencedEntrepeneur'),
      value: 'erfarenGrunder',
    },
    {
      label: t('startups.Experience'),
      value: 'erfaring',
    },
    {
      label: t('startups.CloseFriendsOrFamilyStartedBusiness'),
      value: 'familieVenner',
    },
    {
      label: t('startups.WhereDidYouHearOfSpark'),
      value: 'horteomspark',
    },
    {
      label: t('startups.ClassYear'),
      value: 'kull',
    },
    {
      label: t('startups.PhoneNumber'),
      value: 'mobilnr',
    },
    {
      label: t('startups.Gender'),
      value: 'sex',
    },
    {
      label: 'Rolle i startup',
      value: 'startupRole',
    },
    {
      label: 'Idehaverkompetanse',
      value: 'competance',
    },
  ];

  function addTrackedQuestion(addToList, label, value) {
    const tempObject = {
      label,
      value,
    };
    addToList.push(tempObject);
  }

  if (hostname === 'basen' || hostname === 'localhost') {
    addTrackedQuestion(startupQ, 'Eksperter i Team idé?', 'eit');
    addTrackedQuestion(
      startupQ,
      'Ide meldt gjennom Kvinneprosjektet?',
      'kvinneprosjektet'
    );
  }

  if (hostname === 'sparknord' || hostname === 'localhost') {
    addTrackedQuestion(
      idehaverQ,
      'Kontaktinfodeling',
      'shareContactInformation'
    );
    addTrackedQuestion(startupQ, 'Motivasjon', 'motivation');
    addTrackedQuestion(startupQ, 'Ambisjon', 'ambitions');
    addTrackedQuestion(startupQ, 'Sparkforventninger', 'sparkExpectations');
    addTrackedQuestion(startupQ, 'Kompetansemangler', 'lackingCompetance');
  }

  const [trackedQuestionsStartup, setTrackedQuestionsStartup] = useState({});
  const [trackedQuestionsIdehaver, setTrackedQuestionsIdehaver] = useState({});
  const [formListStartup, setFormListStartup] = useState([]);
  const [formListIdehaver, setFormListIdehaver] = useState([]);

  useEffect(() => {
    if (lackingInfo !== null) {
      setTrackedQuestionsStartup(lackingInfo.startup);
      setTrackedQuestionsIdehaver(lackingInfo.idehaver);
    }
  }, [lackingInfo]);

  function handleChangeStartup(questionObj, boolean) {
    const tempObject = { ...trackedQuestionsStartup };
    tempObject[questionObj.value] = {
      label: questionObj.label,
      value: boolean,
      id: questionObj.value,
    };
    setTrackedQuestionsStartup(tempObject);
  }

  function handleChangeIdehaver(questionObj, boolean) {
    const tempObject = { ...trackedQuestionsIdehaver };
    tempObject[questionObj.value] = {
      label: questionObj.label,
      value: boolean,
      id: questionObj.value,
    };
    setTrackedQuestionsIdehaver(tempObject);
  }

  useEffect(() => {
    const startupforms = startupQ.map((questionObj) => (
      <LackingInfoElement
        key={questionObj.value}
        questionObj={questionObj}
        object={trackedQuestionsStartup}
        handleOnChange={handleChangeStartup}
      />
    ));
    setFormListStartup(startupforms);
  }, [trackedQuestionsStartup]);

  useEffect(() => {
    const idehaverforms = idehaverQ.map((questionObj) => (
      <LackingInfoElement
        key={questionObj.value}
        questionObj={questionObj}
        object={trackedQuestionsIdehaver}
        handleOnChange={handleChangeIdehaver}
      />
    ));
    setFormListIdehaver(idehaverforms);
  }, [trackedQuestionsIdehaver]);

  function handleSubmit() {
    dispatch(
      lackingInfoUpdate(trackedQuestionsStartup, trackedQuestionsIdehaver)
    );
  }

  return (
    <AdminFrame
      title={t('admin.AlertLackingInfo')}
      content={
        <div>
          <p>{t('admin.LackingInfoDescription')}</p>
          <br />
          <h3 className="col-lg-12">Startup</h3>
          {formListStartup}
          <br />
          <h3 className="col-lg-12">{t('startups.Originator')}</h3>
          {formListIdehaver}
          <button
            onClick={handleSubmit}
            className="btn btn-primary block full-width m-b"
            type="submit"
          >
            {t('admin.Save')}
          </button>
        </div>
      }
    />
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
  }))
)(LackingInfoBoxAdmin);
