import React, { Component } from 'react';
import Modal from 'react-modal';
import { FaUser, FaUserPlus } from 'react-icons/fa';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useNavigate } from 'react-router-dom';

import EditTeamMember from './EditTeamMember';
import feedback from '../../redux/actions/feedback';
import { feedbackMessages } from '../../constants/index';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '50%',
    height: '70%',
    transform: 'translate(-50%, -50%)',
  },
};


// This function component is solely for wrapping the class component
function withNavigation(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class ShowTeamButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      selectedMember: null,
      newUser: null,
      teamMembers: [],
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showButtons = this.showButtons.bind(this);

    this.handleMemberInTeam = this.handleMemberInTeam.bind(this);
  }

  componentDidMount() {
    this.setState({
      teamMembers: this.props.teamMembers,
    });
  }

  openModal(e) {
    e.preventDefault();
    this.setState({
      modalIsOpen: true,
      selectedMember: e.target.id,
    });
  }

  closeModal(e) {
    this.setState({
      modalIsOpen: false,
      selectedMember: null,
      newUser: false,
    });
  }

  handleMemberInTeam(member, id, deleteMe) {
    const { startupId, history } = this.props;
    // Existing member
    if (id <= this.state.teamMembers.length) {
      let teamState = Object.assign([], this.state.teamMembers);

      if (deleteMe === true) {
        teamState.splice(id, 1);

        const updates = {};
        updates[`/teammembers/${member.uid}`] = null;
        updates[`/startups/${startupId}/teammedlemmer/${id}`] = null;

        history.push(`/startups/${startupId}`);

        firebase
          .database()
          .ref()
          .update(updates)
          .then(() => {
            feedback.success(feedbackMessages.teammemberDeleteSuccess);
          })
          .catch((err) => {
            console.log(`An error occured.${err}`);
          });
      } else {
        teamState[id] = member;
      }

      this.state.teamMembers[id] = teamState[id];
    } else {
      // New member
      this.state.teamMembers =
        this.state.teamMembers === '' ? {} : this.state.teamMembers;
      this.state.teamMembers[id] = member;
    }
  }

  showButtons() {
    const { teamMembers } = this.state;
    const memberButtons = [];
    const enableEdit = this.props.showAddButton;

    Object.keys(teamMembers).map((key) => {
      memberButtons.push(
        <li key={key}>
          <button
            type="button"
            className="btn btn-white m-r m-t-xs"
            id={key}
            onClick={this.openModal}
          >
            <FaUser /> {teamMembers[key].navn}
          </button>
        </li>
      );
    });

    if (enableEdit === true) {
      memberButtons.push(
        <li key={this.state.teamMembers.length}>
          <button
            type="button"
            className="btn btn-primary m-r m-t-xs"
            id={this.state.teamMembers.length}
            onClick={this.openModal}
          >
            <FaUserPlus /> Legg til
          </button>
        </li>
      );
    }
    return memberButtons;
  }

  render() {
    return (
      <div>
        <div>{this.showButtons()}</div>
        {this.state.modalIsOpen ? (
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Edit teammember"
            ariaHideApp={false}
          >
            <EditTeamMember
              closeModal={this.closeModal}
              member={this.props.teamMembers[this.state.selectedMember]}
              id={this.state.selectedMember}
              handleMemberInTeam={this.handleMemberInTeam}
              readOnly={!this.props.showAddButton}
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withNavigation(ShowTeamButtons);
