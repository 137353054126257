import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface INameCellStyling {
  value?: number;
  costumProps?: {};
  row: any;
}

const NameCellStyling = ({ row }: INameCellStyling) => {
  const { t } = useTranslation('common');

  const startupID = row.original.name.id;
  const startupName = row.original.name.name;
  const originator = row.original.name.originator;

  return (
    <>
      <Link to={`/startups/${startupID}`}>{startupName}</Link>
      <br />
      <small>
        {t('startups.Originator')}
        {': '}
        {originator}
      </small>
    </>
  );
};

export default NameCellStyling;
