import React from 'react';
import { useTranslation } from 'react-i18next';

type NewStartupLabelProps = {
  registreringsDato: number;
};

/*
Returns a label with the text 'NY' if the startup is younger than one month
*/
const NewStartupLabel = ({ registreringsDato }: NewStartupLabelProps) => {
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const dateEstablished = new Date(registreringsDato);

  const { t } = useTranslation('common');

  return (
    <div>
      {oneMonthAgo < dateEstablished ? (
        <span className="label label-primary pull-right">
          {t('startups.NEW')}
        </span>
      ) : null}
    </div>
  );
};

export default NewStartupLabel;
