import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import feedback from './feedback';
import { feedbackMessages } from '../../constants';

const requestTagsUpdate = () => ({
  type: 'REQUEST_TAGS_UPDATE',
});

const tagsUpdateSuccess = () => ({
  type: 'TAGS_UPDATE_SUCCESS',
});

const tagsUpdateError = (error) => ({
  type: 'TAGS_UPDATE_ERROR',
  error,
});

function tagsUpdate(
  listId,
  id,
  object,
  tagsUsedBy,
  oldObject,
  userList,
  startupList
) {
  return (dispatch) => {
    dispatch(requestTagsUpdate());
    const updates = {};
    updates[`/tags/${listId}/${id}`] = object;

    // Update where tags are used
    if (object !== null) {
      if (listId === 'horteomsparkOptions') {
        tagsUsedBy.teamList.map((key) => {
          updates[`/teammembers/${key}/horteomspark`] = object.label;
        });
        tagsUsedBy.idehaverList.map((key) => {
          updates[`/idehavere/${key}/horteomspark`] = object.label;
        });
      }

      if (listId === 'bransjeOptions') {
        tagsUsedBy.startupList.map((key) => {
          updates[`/startups/${key}/bransje`] = object;
        });
      }

      if (listId === 'studieOptions') {
        tagsUsedBy.idehaverList.map((key) => {
          updates[`/idehavere/${key}/studieSted`] = object;
        });
        tagsUsedBy.teamList.map((key) => {
          updates[`/teammembers/${key}/studieretning`] = object;
        });
      }

      if (listId === 'roleOptions') {
        tagsUsedBy.idehaverList.map((key) => {
          updates[`/idehavere/${key}/startupRole`] = object.label;
        });
        tagsUsedBy.teamList.map((key) => {
          updates[`/teammembers/${key}/startupRole`] = object.label;
        });
      }

      if (listId === 'tagOptions') {
        tagsUsedBy.userList.map((key) => {
          let tagIndex = 0;
          const userTags = userList[key].tags;
          const tagKeys = Object.keys(userTags);
          if (tagKeys.includes('label')) {
            if (
              userTags.label === oldObject.label ||
              userTags.value === oldObject.value
            ) {
              updates[`/users/${key}/tags/label`] = null;
              updates[`/users/${key}/tags/value`] = null;
              updates[`/users/${key}/tags/${tagIndex}`] = object;
            }
          }
          if (!tagKeys.includes('label')) {
            tagKeys.map((tagKey) => {
              if (
                userTags[tagKey].label === oldObject.label ||
                userTags[tagKey].value === oldObject.value
              ) {
                updates[`/users/${key}/tags/${tagIndex}`] = object;
              }
              tagIndex += 1;
            });
          }
        });

        tagsUsedBy.startupList.map((key) => {
          let tagIndex = 0;
          const startupTags = startupList[key].tags;
          const tagKeys = Object.keys(startupTags);
          tagKeys.map((tagKey) => {
            if (
              startupTags[tagKey].label === oldObject.label ||
              startupTags[tagKey].value === oldObject.value
            ) {
              updates[`/startups/${key}/tags/${tagIndex}`] = object;
            }
            tagIndex += 1;
          });
        });
      }
    }

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        console.log(updates);
        feedback.success(feedbackMessages.tagUpdateSuccess);
        dispatch(tagsUpdateSuccess());
      })
      .catch((err) => {
        feedback.error(err);
        dispatch(tagsUpdateError(err.message));
      });
  };
}

export default tagsUpdate;
