import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface IProfilePictureDropzone {
  onImageDrop: (path: File[]) => void;
}

const ProfilePictureDropzone = ({ onImageDrop }: IProfilePictureDropzone) => {
  const { t } = useTranslation('common');

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept:{
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    }
    });

  useEffect(() => {
    acceptedFiles.length ? onImageDrop(acceptedFiles) : null;
  }, [acceptedFiles]);

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <p>{t('profile.EditProfilePicture')}</p>
      </div>
      <aside>
        {fileRejections.length ? (
          <>
            <p>{t('profile.InvalidFileFormat')}</p>
          </>
        ) : null}
      </aside>
    </>
  );
};

export default ProfilePictureDropzone;
