import React from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import IPhase from '../../types/IPhase';

type PhaseComponentTypes = {
  phase: IPhase;
};

type Props = PhaseComponentTypes;

const PhaseComponent: React.FC<Props> = ({ phase }) => {
  const { t, i18n } = useTranslation('common');

  let progressBarStyle = { width: '' };
  const percent = `${phase.value.toString()}%`;
  progressBarStyle = { width: percent };

  return (
    <div>
      <span>
        {t('startups.Phase')}
        {': '}
        {phase.label}
      </span>
      <div className="progress progress-mini">
        <div style={progressBarStyle} className="progress-bar" />
      </div>
    </div>
  );
};

export default PhaseComponent;
