import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

function CurrentlyActiveGenders(props) {
    const { idehavere, startups, teammembers, t } = props;
    const [includeTeammembers, setIncludeTeammembers] = useState(false);
    let counter = { male: 0, female: 0, other: 0 };
    let teammemberCounter = { male: 0, female: 0, other: 0 };

    Object.values(startups).forEach(startup => {
        if (startup.status.value == 1) {
            if (idehavere[startup.idehaver].sex == 'm') {
                counter["male"] += 1;
                teammemberCounter["male"] += 1;
            }
            else if (idehavere[startup.idehaver].sex == 'f') {
                counter["female"] += 1;
                teammemberCounter["female"] += 1;
            }
            else {
                counter["other"] += 1;
                teammemberCounter["other"] += 1;
            }
            if (startup.teammedlemmer) {
                Object.values(startup.teammedlemmer).forEach(person => {
                    if (teammembers[person].sex == 'm') {
                        teammemberCounter["male"] += 1;
                    }
                    else if (teammembers[person].sex == 'f') {
                        teammemberCounter["female"] += 1;
                    }
                    else {
                        teammemberCounter["other"] += 1;
                    }
                });
            }
        }
    });

    return (
        <div className="col-lg-12">
            <div className="row">

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {includeTeammembers ? teammemberCounter["male"] : counter["male"]}
                            </h1>
                            <p className="lead">
                                aktive menn
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {includeTeammembers ? teammemberCounter["female"] : counter["female"]}
                            </h1>
                            <p className="lead">
                                aktive kvinner
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {includeTeammembers ? teammemberCounter["other"] : counter["other"]}
                            </h1>
                            <p className="lead">
                                har ikke spesifisert
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => setIncludeTeammembers(!includeTeammembers)}
                />
                <label className="form-check-label mb-4 ml-4" htmlFor="flexSwitchCheckDefault">
                    Inkluder teammedlemmer
                </label>
            </div>

        </div>
    );
}

export default withTranslation('common')(CurrentlyActiveGenders);
