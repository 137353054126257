import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import fetchIdehavereData from '../redux/actions/idehavere';
import PageHeader from '../components/common/PageHeader';
import LoadingSquare from '../components/common/LoadingSquare';
import { updateCurrentView } from '../redux/actions/nav';
import fetchStartupData from '../redux/actions/startups';
import NewStartupComponent from '../components/startup/NewStartup/NewStartupForm.tsx';
import StartupProfileContainer from './StartupProfileContainer';
import StartupTableContainer from './StartupTableContainer.tsx';
import { useParams } from 'react-router-dom';

function StartupListContainer(props) {
  const { dispatch, startups, idehavere, users, tags, t } = props;
  const [showAddNewStartup, setShowAddNewStartup] = useState(false);
  const { startupId } = useParams(); // Using useParams to access route parameters

  useEffect(() => {
    dispatch(updateCurrentView('startups'));
    if (!startups.startupList) {
      dispatch(fetchStartupData());
    }
    if (!idehavere.idehaverList) {
      dispatch(fetchIdehavereData());
    }
  }, [dispatch, startups.startupList, idehavere.idehaverList]);

  if (startupId) {
    return (
      <div>
        <StartupProfileContainer startupId={startupId} />
      </div>
    );
  }
  if (!startups.startupList || !users.userList || !tags.tagsList) {
    return (
      <div>
        <PageHeader view="Startups" subview={null} />
        <LoadingSquare />
      </div>
    );
  }

  const userArray = Object.keys(users.userList).map((key) => {
    const user = users.userList[key];
    user.uid = key; // Adding the key for further use
    return user;
  });

  return (
    <div>
      <PageHeader view="Startups" subview={null} />
      <div className="text-center mt-4">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => setShowAddNewStartup(!showAddNewStartup)}
        >
          {!showAddNewStartup
            ? t('startups.RegisterNewStartup')
            : t('startups.CloseRegistration')}
        </button>
      </div>
      {showAddNewStartup ? (
        <NewStartupComponent
          users={userArray.filter((user) => user.status[0].label === 'Aktiv')}
        />
      ) : (
        ''
      )}
      {showAddNewStartup ? <hr /> : ''}
      <StartupTableContainer />
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    nav: state.nav,
    startups: state.startups,
    users: state.users,
    idehavere: state.idehavere,
    tags: state.tags,
  }))
)(StartupListContainer);
