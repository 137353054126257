import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import feedback from './feedback';
import { feedbackMessages } from '../../constants';

const requestProfileUpdate = () => ({
  type: 'REQUEST_PROFILE_UPDATE',
});

const profileUpdateSuccess = () => ({
  type: 'PROFILE_UPDATE_SUCCESS',
});

const profileUpdateError = (error) => ({
  type: 'PROFILE_UPDATE_ERROR',
  error,
});

function updateProfileInfo(
  firstname,
  lastname,
  about,
  position,
  sparkMail,
  alternativeMail,
  phoneNumber,
  tags,
  uid,
  profilePicUrl
) {
  return (dispatch) => {
    dispatch(requestProfileUpdate());
    firebase
      .database()
      .ref('users')
      .child(uid)
      .update({
        firstname,
        lastname,
        about,
        position,
        sparkMail,
        alternativeMail,
        phoneNumber,
        tags,
        profilePicUrl,
      })
      .then(() => {
        feedback.success(feedbackMessages.profileSuccess);
        dispatch(profileUpdateSuccess());
      })
      .catch((err) => {
        console.error(err.message);
        feedback.error(err);
        dispatch(profileUpdateError(err.message));
      });
  };
}

export default updateProfileInfo;
