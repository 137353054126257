import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

function LackingInfoBox(props) {
  const { idehavere, auth, startups, t, lackingInfo } = props;
  const history = useNavigate();

  if (!lackingInfo) {
    return null;
  }
  if (!lackingInfo.startup || !lackingInfo.idehaver) {
    return null;
  }

  const includedStartupQuestions = {};
  const includedIdehaverQuestions = {};

  Object.keys(lackingInfo.startup)
    .filter((questionKey) => lackingInfo.startup[questionKey].value === true)
    .map(
      (questionKey) =>
        (includedStartupQuestions[questionKey] =
          lackingInfo.startup[questionKey])
    );

  Object.keys(lackingInfo.idehaver)
    .filter((questionKey) => lackingInfo.idehaver[questionKey].value === true)
    .map(
      (questionKey) =>
        (includedIdehaverQuestions[questionKey] =
          lackingInfo.idehaver[questionKey])
    );

  const includedStartupQuestionsKeys = Object.keys(includedStartupQuestions);
  const includedIdehaverQuestionsKeys = Object.keys(includedIdehaverQuestions);

  let updatedStartups = [];
  let startupKeys = Object.keys(startups.startupList);
  // Tar bare med aktive startups
  startupKeys = startupKeys.filter(
    (key) => startups.startupList[key].status.label === 'Aktiv'
  );

  // Om startupen har manglende info, legg til veileder i liste updatedStartups
  updatedStartups = startupKeys.map((startupKey) => {
    const thisstartup = startups.startupList[startupKey];
    // Feltene som mangler legges til i lacking info. startupKey bevares som id.
    thisstartup.lackingInfo = '';
    thisstartup.id = startupKey;
    // Gjelder startups registrert for over en uke siden
    const weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    if (thisstartup.registreringsDato < weekAgo) {
      const startupQuestions = Object.keys(thisstartup);
      // eslint-disable-next-line no-restricted-syntax
      for (const question of includedStartupQuestionsKeys) {
        if (!startupQuestions.includes(question)) {
          thisstartup.lackingInfo += `${includedStartupQuestions[question].label}, `;
        } else if (thisstartup[question] === '') {
          thisstartup.lackingInfo += `${includedStartupQuestions[question].label}, `;
        }
      }
      // Sjekker objektverdier
      if (includedStartupQuestionsKeys.includes('bransje')) {
        if (
          thisstartup.bransje.value === '' ||
          thisstartup.bransje.value === 'n/a'
        ) {
          thisstartup.lackingInfo += `${includedStartupQuestions.bransje.label}, `;
        }
      }

      // Idehaver
      const idehaverKey = thisstartup.idehaver;
      const idehaver = idehavere.idehaverList[idehaverKey];
      const idehaverQuestions = Object.keys(idehaver);

      // eslint-disable-next-line no-restricted-syntax
      for (const question of includedIdehaverQuestionsKeys) {
        if (!idehaverQuestions.includes(question)) {
          thisstartup.lackingInfo += `${includedIdehaverQuestions[question].label}, `;
        } else if (idehaver[question] === '') {
          thisstartup.lackingInfo += `${includedIdehaverQuestions[question].label}, `;
        }
      }
      // Sjekker objektverdier
      if (includedIdehaverQuestionsKeys.includes('studieSted')) {
        if (
          idehaver.studieSted.value === '' ||
          idehaver.studieSted.value === 'n/a'
        ) {
          thisstartup.lackingInfo += `${includedIdehaverQuestions.studieSted.label}, `;
        }
      }
    }
    return thisstartup;
  });

  let lackingStartups = [];
  lackingStartups = updatedStartups.filter(
    (startup) => startup.lackingInfo.length > 0
  );
  let yourLackingStartups = [];
  yourLackingStartups = lackingStartups.filter(
    (startup) => startup.veileder.value === auth.uid
  );

  const isLackingInfo = yourLackingStartups.length !== 0;

  function handleOnClick(startupid) {
    history(`/startups/${startupid}`);
  }

  const startupItems = yourLackingStartups.map((startup) => (
    <div key={startup.id}>
      <button
        className="btn btn-primary"
        onClick={(e) => handleOnClick(startup.id, e)}
        type="button"
      >
        {startup.ideNavn}
      </button>
      <br />
      <small>{startup.lackingInfo}</small>
      <br />
      <br />
    </div>
  ));

  return (
    <div>
      {isLackingInfo ? (
        <div className="alert alert-danger" role="alert">
          <strong>{t('dashboard.LackingStartupInformation')}!</strong>
          <br />
          <p>{t('dashboard.LackingStartupInformationDescription')}.</p>
          <hr />
          {startupItems}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    idehavere: state.idehavere,
    startups: state.startups,
    auth: state.auth,
  }))
)(LackingInfoBox);
