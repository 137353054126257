import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IUser from '../../types/IUser';
import ITag from '../../types/ITag';
import UserIcon from './UserIcon';
import { IStartup } from '../../types/IStartup';
import updateCapacity from './updateCapacity';

interface IUserProfileHeader {
  user: IUser;
  veilederId: string;
  startupObj: {
    [startupID: string]: IStartup;
  };
  editProfile: {}[];
}

const generateTagLabels = (user: IUser) => {
  const tagLabels: {}[] = [];
  let userTags = user.tags || [];

  try {
    userTags.map((tag: ITag) => {
      tagLabels.push(
        <li key={tag.label}>
          <a>
            <i className="fa fa-tag" />
            {tag.label}
          </a>
        </li>
      );
    });
  } catch {}
  return tagLabels;
};

const UserProfileHeader = ({
  user,
  veilederId,
  startupObj,
  editProfile,
}: IUserProfileHeader) => {
  const [numberOfStartups, setNumberOfStartups] = useState(0);

  const { t } = useTranslation('common');

  // Count number of active startup a user posesses
  useEffect(() => {
    let activeStartups = 0;

    // Check if user has startups
    if (user.startups) {
      // Return number of active startups a user possesses
      Object.keys(user.startups).forEach((s) => {
        // Failsafe if a startup has been deleted, but remains on user.startups
        try {
          if (startupObj[s].status.label === 'Aktiv') {
            activeStartups++;
          }
        } catch {}
      });
    }

    setNumberOfStartups(activeStartups);
  }, []);

  const percent =
    user.capacity === 0
      ? '100%'
      : `${Math.floor((numberOfStartups / user.capacity) * 100)}%`;

  return (
    <div className="row m-b-lg m-t-lg ibox ibox-content">
      <div className="col-md-5">
        <div className="profile-image">
          <img
            style={{ marginBottom: '0px' }}
            src={user.profilePicUrl}
            className="img-circle circle-border m-b-md"
            alt="profile"
          />
          <div style={{ textAlign: 'center', marginRight: '30px' }}>
            {editProfile}
          </div>
        </div>
        <div className="profile-info">
          <div className="">
            <div>
              <h2 className="no-margins">
                {`${user.firstname} ${user.lastname}`}
              </h2>
              <h4>
                <UserIcon position={user.position} /> {user.position}
              </h4>
              <small>{user.about}</small>
              <div className="project-manager">
                <ul className="tag-list" style={{ paddingLeft: '8px' }}>
                  {generateTagLabels(user)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3" style={{ paddingTop: '10px' }}>
        <table className="table small m-b-xs">
          <tbody>
            <tr>
              <td>
                <strong>{t('profile.Email')}</strong> {user.sparkMail}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{t('profile.AlternativeEmail')}</strong>{' '}
                {user.alternativeMail}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{t('profile.PhoneNumber')}</strong> {user.phoneNumber}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-md-3" style={{ paddingTop: '10px' }}>
        <h3 className="font-bold no-margins font-color-orange">
          {t('profile.Capacity')} {percent}
        </h3>
        <div
          style={{ borderRadius: '0', marginTop: '5px' }}
          className="progress progress-striped active m-b-sm"
        >
          <div style={{ width: percent }} className="progress-bar" />
        </div>
        <i className="fa fa-shield fa-4x" />
        <h1
          className="m font-color-orange"
          style={{
            fontSize: '48px',
            marginBottom: '0px',
            display: 'inline',
          }}
        >
          {numberOfStartups}/{user.capacity}
        </h1>
        <button
          type="button"
          className="capacity-button"
          onClick={() => updateCapacity(veilederId, user, 1)}
        >
          +
        </button>
        <button
          type="button"
          className="capacity-button"
          onClick={() => updateCapacity(veilederId, user, -1)}
        >
          -
        </button>
      </div>
    </div>
  );
};

export default UserProfileHeader;
