import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import { Dispatch } from 'react';
import { feedbackMessages } from '../../../constants';
import feedback from '../../../redux/actions/feedback';
import {
  requestStartupCreate,
  startupCreateError,
  startupCreateSuccess,
} from '../../../redux/actions/startupCreate';
import InputTypes, {
  IdehaverDataInputs,
  StartupDataInputs,
} from './NewStartupTypes';

type CreateStartupProps = {
  goToStartup: (startupID: string) => void;
  data: InputTypes;
};

const createStartupTS = ({ goToStartup, data }: CreateStartupProps) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestStartupCreate());

    // Assign a new startup key
    const newStartupKey: string = firebase
      .database()
      .ref()
      .child('startups')
      .push().key!;

    // Assign a new idehaver key
    const newIdehaverKey: string = firebase
      .database()
      .ref()
      .child('idehaver')
      .push().key!;

    /*
    1. Extract startup data from react form
    2. Inject default values
    */
    const startupData: StartupDataInputs = {
      ...data,
      organisasjonsNr: 'n/a',
      registreringsDato: firebase.database.ServerValue.TIMESTAMP,
      bransje: { value: 'n/a', label: 'n/a' },
      tags: [{ value: 0, label: 'Ny ide' }],
      fase: { value: 25, label: 'Idefase' },
      status: { value: 1, label: 'Aktiv' },
      idehaver: newIdehaverKey,
      teammedlemmer: '',
      nettside: '',
      logoUrl: 'https://s31.postimg.cc/b4oa12o1n/Artboard_1.png',
      stotte: '',
      stotteType: '',
    };

    /* 
    1. Extract idehaver data
    2. Inject default values
    */
    const idehaverData: IdehaverDataInputs = {
      ...data,
      alternativeMail: '',
      studieSted: '',
      kull: '',
      erfaring: '',
      familieVenner: '',
      erfarenGrunder: '',
      deltattKurs: '',
      arbeidserfaring: '',
      horteomspark: '',
    };

    // Empty object to be filled with data from form, and added to Firebase
    const updates: {
      [path: string]: StartupDataInputs | IdehaverDataInputs | string;
    } = {};

    // Map startup data to the given startup, using the generated key
    updates[`/startups/${newStartupKey}`] = startupData;

    // Map the generated startup key to a veileder
    updates[`/users/${startupData.veileder.value}/startups/${newStartupKey}`] =
      newStartupKey;

    // Map the idehaver data to a given idehaver, using the generated key
    updates[`/idehavere/${newIdehaverKey}`] = idehaverData;

    firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        feedback.success(feedbackMessages.startupCreateSuccess);
        dispatch(startupCreateSuccess());
        goToStartup(newStartupKey);
      })
      .catch((err) => {
        feedback.error(err);
        dispatch(startupCreateError(err.message));
      });
  };
};

export default createStartupTS;
