import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import startupDelete from '../../redux/actions/startupDelete';
import StartupTableRow from '../startup/StartupTable/StartupTableRow';
import AdminFrame from './AdminFrame';

function DeleteStartup(props) {
  const { auth, startups, t, idehavere, teammembers, dispatch } = props;
  const { startupList } = startups;
  const { idehaverList } = idehavere;
  const { teamList } = teammembers;
  const [alert, setAlert] = useState(null);

  const [startupValg, setStartupValg] = useState([]);
  const [valgtStartup, setValgtStartup] = useState(null);
  const [valgtIdehaver, setValgtIdehaver] = useState(null);
  const [valgtTeam, setValgtTeam] = useState([]);

  useEffect(() => {
    const startupKeys = Object.keys(startups.startupList);
    const tempList = startupKeys.map(
      (startupKey) => (
        (startupList[startupKey].id = startupKey), startupList[startupKey]
      )
    );
    setStartupValg(tempList);
  }, [startups]);

  function handleStartupChange(e) {
    setValgtStartup(e);
    setValgtIdehaver(startupList[e.value].idehaver);
    setValgtTeam(startupList[e.value].teammedlemmer || []);
  }

  function hideAlert() {
    setAlert(null);
  }

  function confirmDelete() {
    dispatch(
      startupDelete(
        valgtStartup.value,
        valgtIdehaver,
        startupList[valgtStartup.value].veileder.value,
        valgtTeam
      )
    );
    setValgtStartup(null);
    const confirmed = () => (
      <SweetAlert success title="Ok!" onConfirm={() => hideAlert()}>
        {t('admin.StartupHasBeenDeleted')}
      </SweetAlert>
    );

    setAlert(confirmed());
  }

  function onDelete(e) {
    e.preventDefault();
    const getAlert = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText={t('admin.YesDeleteIt')}
        cancelBtnText={t('admin.Cancel')}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={t('admin.AreYouSure')}
        onCancel={() => hideAlert()}
        onConfirm={() => confirmDelete()}
      >
        {t('admin.Delete')} {startupList[valgtStartup.value].ideNavn} ?
      </SweetAlert>
    );

    setAlert(getAlert());
  }

  return (
    <AdminFrame
      title={t('admin.DeleteStartup')}
      content={
        <div>
          <form className="m-t" onSubmit={onDelete}>
            <div className="form-group">
              <h4>{t('admin.ChooseStartupToDelete')}</h4>
              <Select
                name="veileder-input"
                value={valgtStartup}
                options={startupValg.map((startup) => ({
                  value: startup.id,
                  label: startup.ideNavn,
                }))}
                onChange={handleStartupChange}
              />
            </div>
            {valgtStartup !== null ? (
              <div>
                <table className="table table-hover">
                  <tbody>
                    <StartupTableRow
                      key={valgtStartup.value}
                      superKey={valgtStartup.value}
                      ide={startupList[valgtStartup.value]}
                      veileder={startupList[valgtStartup.value].veileder}
                      idehaver={idehaverList[valgtIdehaver]}
                    />
                  </tbody>
                </table>
                <h5>{t('startups.TeamMembers')}</h5>
                {valgtTeam.length
                  ? valgtTeam.map((teamKey) => (
                      <p key={teamKey}>{teamList[teamKey].navn}</p>
                    ))
                  : ''}
              </div>
            ) : (
              ''
            )}
            <br />
            <p>{t('admin.DeleteDescription')}</p>
            <button
              className="btn btn-primary block full-width m-b"
              type="submit"
              disabled={valgtStartup === ''}
            >
              {t('admin.DeleteStartup')}
            </button>
          </form>
          {alert}
        </div>
      }
    />
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    auth: state.auth,
    startups: state.startups,
    idehavere: state.idehavere,
    teammembers: state.teammembers,
  }))
)(DeleteStartup);
