import { combineReducers } from 'redux';
import auth from './auth';
import nav from './nav';
import startups from './startups';
import users from './users';
import profile from './profile';
import startupEdit from './startupEdit';
import idehavere from './idehavere';
import teammembers from './teamMembers';
import tags from './tags';
import TEBNumbers from './TEBNumbers';
import lackingInfo from './lackingInfo';
import meetingNote from './meetingNote';

const rootReducer = combineReducers({
  auth,
  nav,
  startups,
  users,
  profile,
  startupEdit,
  idehavere,
  teammembers,
  tags,
  TEBNumbers,
  lackingInfo,
  meetingNote,
});

export default rootReducer;
