function filterActiveOriginators(startupList, idehaverList) {
  const activeOriginatorKeys = startupList
    .filter((startup) => startup.status.label === 'Aktiv')
    .map((startup) => startup.idehaver);

  const activeOriginatorsEmail = activeOriginatorKeys.map((key) => {
    if (idehaverList[key].email) {
      return idehaverList[key].email;
    }
  });

  return activeOriginatorsEmail;
}

export default filterActiveOriginators;
