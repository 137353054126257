import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import TronderEnergiByYear from './TronderEnergiByYear';

function TronderEnergiAllYears(props) {

    const { rounds } = props;

    const [selectedYear, setSelectedYear] = useState();
    const [clicked, setClicked] = useState(false);

    const years = {};
    const series = [];
    const categories = [];

    const changeView = () => {
        setClicked(!clicked);
    }

    Object.values(rounds).forEach(round => {
        const date = new Date(round["dato"] * 1000);
        if (!years[date.getFullYear()]) {
            years[date.getFullYear()] = [];
            categories.push(date.getFullYear());
        }
        years[date.getFullYear()].push(round);
    });

    Object.values(years).forEach(year => {
        series.push(year.length);
    });

    const allStartupChart = {
        chart: {
            type: 'line',
        },
        exporting: {
            enabled: true,
        },
        navigation: {
            buttonOptions: {
                align: 'center',
                verticalAlign: 'bottom',
                y: 40,
            },
        },
        title: {
            text: "Runder per år",
        },
        xAxis: {
            categories: categories,
        },
        series: [{
            name: "Runder",
            data: series
        }],
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: e => {
                            setSelectedYear(parseInt(e.point.category));
                            changeView();
                        }
                    }
                }
            }
        },
    }

    return (
        <>
            {clicked ?
                <>
                    <TronderEnergiByYear data={years[selectedYear]} year={selectedYear} />
                    <button
                        onClick={() => changeView()}
                        className="btn btn-primary block m-b mt-2"
                    >
                        Tilbake
                    </button>
                </>
                :
                <>
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType="chart"
                        options={allStartupChart}
                    />
                </>
            }
        </>
    );
}


export default withTranslation('common')(TronderEnergiAllYears);