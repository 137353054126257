import React from 'react';

export default function AdminFrame(props) {
  const { title, content } = props;
  // Using conditional for textTitle to not enlarge not text-titles, such as buttons.
  // Not destructuring textTitle to make it an optional prop.
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="ibox float-e-margins">
          <div className="ibox-title">
            {props.textTitle === false ? title : <h3>{title}</h3>}
          </div>
          <div className="ibox-content">{content}</div>
        </div>
      </div>
    </div>
  );
}
