import React from 'react';
import { FaPen as FaPencil } from 'react-icons/fa';

export default function WhiteButton({ onClick, buttonText }) {
  return (
    <button
      type="button"
      className="btn btn-sm btn-white btn-block"
      onClick={onClick}
    >
      <FaPencil /> {buttonText}
    </button>
  );
}
