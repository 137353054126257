import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import updateStartupInfo from '../redux/actions/startupEdit';
import ShowTeamButtons from '../components/team/ShowTeamButtons';
import { filterIdeStatus, filterIdePhase } from '../constants/index';
import 'cropperjs/dist/cropper.css';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import hostname from '../config/hostname';
import ProfileTags from '../components/tags/ProfileTags';

function EditStartupContainer(props) {
  const {
    tags,
    users,
    startups,
    teammembers,
    idehavere,
    startupEdit,
    dispatch,
    t,
  } = props;

  const { startupId } = useParams(); // Replacing match.params
  const navigate = useNavigate(); // Replacing history

  const userArray = Object.keys(users.userList).map((key) => {
    const user = {...users.userList[key], uid: key}; // Avoid direct mutation by spreading
    return user;
  });

  const activeUserArray = userArray.filter(
    (user) => user.status[0].label === 'Aktiv'
  );

  const veiledere = activeUserArray.map((user) => ({
    value: user.uid,
    label: `${user.firstname} ${user.lastname}`,
  }));

  const {
    tagOptions,
    horteomsparkOptions,
    bransjeOptions,
    studieOptions,
    roleOptions,
  } = tags.tagsList;

  const startupInfo = startups.startupList[startupId] || {}; // Safe access
  const forrigeVeileder = startupInfo.veileder;

  const [startup, setStartup] = useState(startupInfo);
  const teamMemberIDs = startup.teammedlemmer || {};
  const { teamList } = teammembers;

  // Making teammembers an object
  const teamMembers = Object.values(teamMemberIDs).map((key) => {
    return {...teamList[key], uid: key}; // Avoid direct mutation by spreading
  });

  const [startupTags, setStartupTags] = useState(startupInfo.tags);
  const [idehaver, setIdehaver] = useState(
    idehavere.idehaverList[startup.idehaver] || {}
  );

  if (idehaver.horteomspark && typeof idehaver.horteomspark === 'object') {
    setIdehaver(prev => ({...prev, horteomspark: idehaver.horteomspark.label}));
  }

  const [isShowingStartupInfo, setIsShowingStartupInfo] = useState(false);
  const [isShowingOrigInfo, setIsShowingOrigInfo] = useState(false);

  const [orgnrInfo, setOrgnrInfo] = useState('');

  useEffect(() => {
    if (startup.organisasjonsNr) {
      if (startup.organisasjonsNr.length === 9) {
        fetch(
          `https://data.brreg.no/enhetsregisteret/api/enheter/${startup.organisasjonsNr}`
        )
          .then((res) => res.json())
          .then(
            (result) => {
              setOrgnrInfo(result.organisasjonsform.beskrivelse);
            },
            (error) => {
              console.error(error);
            }
          );
      }
    }
  }, [startup.organisasjonsNr, startupId]);

  function onSubmit(e) {
    e.preventDefault();
    dispatch(
      updateStartupInfo(
        startup,
        startupId,
        idehaver,
        forrigeVeileder,
        teamMembers,
        startupTags
      )
    );
    navigate(`/startups/${startupId}`); // Use navigate for redirection
  }

  function onCancel(e) {
    e.preventDefault();
    navigate(`/startups/${startupId}`); // Use navigate for redirection
  }

  function handleTagChange(t) {
    setStartupTags(t);
  }

  return (
    <div className="wrapper wrapper-content animated fadeInRight">
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h3>{t('startups.EditStartup')}</h3>
            </div>
            <div className="ibox-content">
              <form className="form" onSubmit={onSubmit}>
                <div className="form-group">
                  <label className="control-label">
                    {t('startups.Mentor')}
                  </label>
                  <div>
                    <Select
                      required
                      name="tag-input"
                      value={startup.veileder}
                      options={veiledere}
                      onChange={(e) => setStartup({ ...startup, veileder: e })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label">
                    {t('startups.IdeaName')}
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={startup.ideNavn}
                      onChange={(e) =>
                        setStartup({ ...startup, ideNavn: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label">
                    {t('startups.IdeaDescription')}
                  </label>
                  <div>
                    <textarea
                      type="text"
                      rows="5"
                      className="form-control"
                      value={startup.ideBeskrivelse}
                      onChange={(e) =>
                        setStartup({
                          ...startup,
                          ideBeskrivelse: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label">{t('startups.Phase')}</label>
                  <div>
                    <Select
                      required
                      name="tag-input"
                      value={startup.fase}
                      options={filterIdePhase}
                      onChange={(e) => setStartup({ ...startup, fase: e })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label">Status</label>
                  <div>
                    <Select
                      required
                      name="tag-input"
                      value={startup.status}
                      options={filterIdeStatus}
                      onChange={(e) => setStartup({ ...startup, status: e })}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label">
                    {t('startups.TeamMembers')}
                  </label>
                  <div className="col-sm-12">
                    <ul className="tag-list" style={{ padding: 0 }}>
                      <ShowTeamButtons
                        teamMembers={teamMembers}
                        startupId={startupId}
                        showAddButton
                      />
                    </ul>
                  </div>
                </div>

                <div className="hr-line-dashed" />
                <br />
                <h2>{t('startups.OriginatorInformation')}</h2>
                <button
                  type="button"
                  className={
                    isShowingOrigInfo ? 'btn btn-light' : 'btn btn-dark'
                  }
                  onClick={() => setIsShowingOrigInfo(!isShowingOrigInfo)}
                >
                  {isShowingOrigInfo
                    ? t('startups.ShowLess')
                    : t('startups.ShowMore')}
                </button>
                {isShowingOrigInfo ? (
                  <div>
                    {hostname === 'localhost' || hostname === 'sparknord' ? (
                      <div className="form-group">
                        <label className="control-label">
                          Kan vi dele kontaktinformasjon internt i Spark*, Start
                          og Engage?
                        </label>
                        <div className="col-sm-12 mb-3">
                          <div>
                            <label>
                              <input
                                type="radio"
                                checked={
                                  idehaver.shareContactInformation === true
                                    ? true
                                    : ''
                                }
                                onChange={() =>
                                  setIdehaver({
                                    ...idehaver,
                                    shareContactInformation: true,
                                  })
                                }
                              />
                              Ja
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                checked={
                                  idehaver.shareContactInformation === false
                                    ? true
                                    : ''
                                }
                                onChange={() =>
                                  setIdehaver({
                                    ...idehaver,
                                    shareContactInformation: false,
                                  })
                                }
                              />
                              Nei
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <h3>{t('startups.Personals')}</h3>
                    <div>
                      <div className="form-group">
                        <label className="control-label">
                          {t('startups.Originator')}
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={idehaver.navn}
                            onChange={(e) =>
                              setIdehaver({ ...idehaver, navn: e.target.value })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label">
                          {t('startups.PhoneNumber')}
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={idehaver.mobilnr}
                            onChange={(e) =>
                              setIdehaver({
                                ...idehaver,
                                mobilnr: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="form-group">
                        <label className="control-label">
                          {t('startups.Email')}
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={idehaver.email}
                            onChange={(e) =>
                              setIdehaver({
                                ...idehaver,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label">
                          {t('profile.AlternativeEmail')}
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={idehaver.alternativeMail}
                            onChange={(e) =>
                              setIdehaver({
                                ...idehaver,
                                alternativeMail: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t('startups.Gender')}
                      </label>
                      <div className="col-sm-12">
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={idehaver.sex === 'f' ? true : ''}
                              onChange={() =>
                                setIdehaver({ ...idehaver, sex: 'f' })
                              }
                            />
                            {t('startups.Woman')}
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={idehaver.sex === 'm' ? true : ''}
                              onChange={() =>
                                setIdehaver({ ...idehaver, sex: 'm' })
                              }
                            />
                            {t('startups.Man')}
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={idehaver.sex === 'other' ? true : ''}
                              onChange={() =>
                                setIdehaver({ ...idehaver, sex: 'other' })
                              }
                            />
                            {t('startups.Other')}
                          </label>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />

                    <div className="form-group mt-5">
                      <label className="control-label">
                        {t('startups.WhereDidYouHearOfSpark')}:{' '}
                      </label>
                      <div>
                        <Select
                          name="horteomspark-input"
                          value={
                            {
                              label: idehaver.horteomspark,
                              value: idehaver.horteomspark,
                            } || ''
                          }
                          options={horteomsparkOptions}
                          onChange={(e) =>
                            setIdehaver({ ...idehaver, horteomspark: e.label })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t('startups.Study')}
                      </label>
                      <div>
                        <Select
                          required
                          name="tag-input"
                          value={idehaver.studieSted}
                          options={studieOptions}
                          onChange={(e) =>
                            setIdehaver({ ...idehaver, studieSted: e })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t('startups.RoleInStartup')}:{' '}
                      </label>
                      <div>
                        <Select
                          name="role-input"
                          value={
                            {
                              label: idehaver.startupRole || '',
                              value: idehaver.startupRole,
                            } || ''
                          }
                          options={roleOptions}
                          onChange={(e) =>
                            setIdehaver({ ...idehaver, startupRole: e.label })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t('startups.Competance')}
                      </label>
                      <div>
                        <textarea
                          type="text"
                          rows="5"
                          className="form-control"
                          value={idehaver.competance}
                          onChange={(e) =>
                            setIdehaver({
                              ...idehaver,
                              competance: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="hr-line-dashed" />
                    <h3>{t('startups.OriginatorsEntrepeneurBackground')}</h3>

                    <div className="form-group mt-5">
                      <label className="control-label">
                        {t('startups.AgeAtFirstMentoring')}
                      </label>
                      <div>
                        <input
                          type="number"
                          className="form-control"
                          value={idehaver.alder}
                          onChange={(e) =>
                            setIdehaver({ ...idehaver, alder: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t('startups.ClassYearAtFirstMentoring')}
                      </label>
                      <div className="col-sm-12">
                        <div className="col-sm-6">
                          <div className="col-sm-1">
                            <label>
                              <input
                                type="radio"
                                checked={idehaver.kull === '1' ? true : ''}
                                onChange={() =>
                                  setIdehaver({ ...idehaver, kull: '1' })
                                }
                              />
                              1
                            </label>
                          </div>
                          <div className="col-sm-1">
                            <label>
                              <input
                                type="radio"
                                checked={idehaver.kull === '2' ? true : ''}
                                onChange={() =>
                                  setIdehaver({ ...idehaver, kull: '2' })
                                }
                              />
                              2
                            </label>
                          </div>
                          <div className="col-sm-1">
                            <label>
                              <input
                                type="radio"
                                checked={idehaver.kull === '3' ? true : ''}
                                onChange={() =>
                                  setIdehaver({ ...idehaver, kull: '3' })
                                }
                              />
                              3
                            </label>
                          </div>
                          <div className="col-sm-1">
                            <label>
                              <input
                                type="radio"
                                checked={idehaver.kull === '4' ? true : ''}
                                onChange={() =>
                                  setIdehaver({ ...idehaver, kull: '4' })
                                }
                              />
                              4
                            </label>
                          </div>
                          <div className="col-sm-1">
                            <label>
                              <input
                                type="radio"
                                checked={idehaver.kull === '5' ? true : ''}
                                onChange={() =>
                                  setIdehaver({ ...idehaver, kull: '5' })
                                }
                              />
                              5
                            </label>
                          </div>
                          <div className="col-sm-1">
                            <label>
                              <input
                                type="radio"
                                checked={idehaver.kull === '6' ? true : ''}
                                onChange={() =>
                                  setIdehaver({ ...idehaver, kull: '6' })
                                }
                              />
                              6
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t('startups.YearsOfEarlierWorkExperience')} (&gt;75%)
                      </label>
                      <div>
                        <input
                          type="number"
                          className="form-control"
                          value={idehaver.arbeidserfaring}
                          onChange={(e) =>
                            setIdehaver({
                              ...idehaver,
                              arbeidserfaring: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <br />

                    <div className="form-group mt-5">
                      <label className="control-label">
                        {t('startups.StartedCompanyBefore')}
                      </label>
                      <div className="col-sm-12">
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={
                                idehaver.erfarenGrunder === true ? true : ''
                              }
                              onChange={() =>
                                setIdehaver({
                                  ...idehaver,
                                  erfarenGrunder: true,
                                })
                              }
                            />
                            {t('admin.Yes')}
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={
                                idehaver.erfarenGrunder === false ? true : ''
                              }
                              onChange={() =>
                                setIdehaver({
                                  ...idehaver,
                                  erfarenGrunder: false,
                                })
                              }
                            />
                            {t('admin.No')}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t(
                          'startups.CloseFamilyOrCloseFriendsOfYoursWhoHaveStartedACompany'
                        )}
                      </label>
                      <div className="col-sm-12">
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={
                                idehaver.familieVenner === true ? true : ''
                              }
                              onChange={() =>
                                setIdehaver({
                                  ...idehaver,
                                  familieVenner: true,
                                })
                              }
                            />
                            {t('admin.Yes')}
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={
                                idehaver.familieVenner === false ? true : ''
                              }
                              onChange={() =>
                                setIdehaver({
                                  ...idehaver,
                                  familieVenner: false,
                                })
                              }
                            />
                            {t('admin.No')}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">
                        {t(
                          'startups.ParticipatedInCoursesActivitiesFocusingOnInnovationAndEntrepreneurship'
                        )}
                      </label>
                      <div className="col-sm-12">
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={
                                idehaver.deltattKurs === true ? true : ''
                              }
                              onChange={() =>
                                setIdehaver({ ...idehaver, deltattKurs: true })
                              }
                            />
                            {t('admin.Yes')}
                          </label>
                        </div>
                        <div>
                          <label>
                            <input
                              type="radio"
                              checked={
                                idehaver.deltattKurs === false ? true : ''
                              }
                              onChange={() =>
                                setIdehaver({ ...idehaver, deltattKurs: false })
                              }
                            />
                            {t('admin.No')}
                          </label>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div className="hr-line-dashed" />
                    <br />
                  </div>
                ) : (
                  ''
                )}

                <h2>{t('startups.StartupInformation')}</h2>
                <button
                  type="button"
                  className={
                    isShowingStartupInfo ? 'btn btn-light' : 'btn btn-dark'
                  }
                  onClick={() => setIsShowingStartupInfo(!isShowingStartupInfo)}
                >
                  {isShowingStartupInfo ? 'Vis mindre' : 'Vis mer'}
                </button>
                {isShowingStartupInfo ? (
                  <div>
                    {hostname === 'sparknord' || hostname === 'localhost' ? (
                      <div className="mb-5">
                        <div className="form-group">
                          <label className="control-label">
                            Hva er deres motivasjon for å starte en bedrift?
                          </label>
                          <div>
                            <textarea
                              row="3"
                              type="text"
                              className="form-control"
                              value={startup.motivation}
                              onChange={(e) =>
                                setStartup({
                                  ...startup,
                                  motivation: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Hva er deres ambisjoner for startupen?
                          </label>
                          <div>
                            <textarea
                              row="3"
                              type="text"
                              className="form-control"
                              value={startup.ambitions}
                              onChange={(e) =>
                                setStartup({
                                  ...startup,
                                  ambitions: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Hva er deres forventninger til samarbeidet med
                            Spark*?
                          </label>
                          <div>
                            <textarea
                              row="3"
                              type="text"
                              className="form-control"
                              value={startup.sparkExpectations}
                              onChange={(e) =>
                                setStartup({
                                  ...startup,
                                  sparkExpectations: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Hvilken spesifikke kompetanse mangler i startupen og
                            er ønskelig?
                          </label>
                          <div>
                            <textarea
                              type="text"
                              rows="3"
                              className="form-control"
                              value={startup.lackingCompetance}
                              onChange={(e) =>
                                setStartup({
                                  ...startup,
                                  lackingCompetance: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {hostname === 'basen' || hostname === 'localhost' ? (
                      <div className="mb-5">
                        <div className="form-group">
                          <label className="control-label">
                            Eksperter i Team idé
                          </label>
                          <div className="col-sm-12">
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  checked={startup.eit === true ? true : ''}
                                  onChange={() =>
                                    setStartup({ ...startup, eit: true })
                                  }
                                />
                                Ja
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  checked={startup.eit === false ? true : ''}
                                  onChange={() =>
                                    setStartup({ ...startup, eit: false })
                                  }
                                />
                                Nei
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Meldt inn idé gjennom Kvinneprosjektet
                          </label>
                          <div className="col-sm-12">
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  checked={
                                    startup.kvinneprosjektet === true
                                      ? true
                                      : ''
                                  }
                                  onChange={() =>
                                    setStartup({
                                      ...startup,
                                      kvinneprosjektet: true,
                                    })
                                  }
                                />
                                Ja
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  checked={
                                    startup.kvinneprosjektet === false
                                      ? true
                                      : ''
                                  }
                                  onChange={() =>
                                    setStartup({
                                      ...startup,
                                      kvinneprosjektet: false,
                                    })
                                  }
                                />
                                Nei
                              </label>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    ) : (
                      ''
                    )}

                    <div>
                      <div className="form-group">
                        <label className="control-label">
                          {t('startups.ReceivedFinancialSupport')}
                        </label>
                        <div className="col-sm-12">
                          <div>
                            <label>
                              <input
                                type="radio"
                                checked={startup.stotte === true ? true : ''}
                                onChange={() =>
                                  setStartup({ ...startup, stotte: true })
                                }
                              />
                              Ja
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                checked={startup.stotte === false ? true : ''}
                                onChange={() =>
                                  setStartup({ ...startup, stotte: false })
                                }
                              />
                              Nei
                            </label>
                          </div>
                        </div>
                      </div>

                      {startup.stotte === true ? (
                        <div className="form-group">
                          <label className="control-label">
                            {t('startups.TypeOfFinancialSupport')}{' '}
                            {hostname === 'basen' ? '(f.eks TEB)' : ''}
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={startup.stotteType}
                              onChange={(e) =>
                                setStartup({
                                  ...startup,
                                  stotteType: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {hostname === 'sparknord' || hostname === 'localhost' ? (
                        <div className="form-group">
                          <label className="control-label">
                            Hvor mye penger har blitt brukt?
                          </label>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              value={startup.moneyUsed}
                              onChange={(e) =>
                                setStartup({
                                  ...startup,
                                  moneyUsed: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="hr-line-dashed" />

                    <div className="form-group mt-5">
                      <label className="control-label">
                        {t('startups.WebPage')}
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={startup.nettside}
                          onChange={(e) =>
                            setStartup({ ...startup, nettside: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Org.nr</label>
                      <div>
                        <input
                          type="number"
                          className="form-control"
                          value={startup.organisasjonsNr}
                          onChange={(e) =>
                            setStartup({
                              ...startup,
                              organisasjonsNr: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    {orgnrInfo ? (
                      <div>
                        <div className="form-group">
                          <label className="control-label">
                            Organisasjonsform
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={orgnrInfo}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="form-group mt-5">
                      <label className="control-label">
                        {t('startups.Industry')}
                      </label>
                      <div>
                        <Select
                          required
                          name="tag-input"
                          value={startup.bransje}
                          options={bransjeOptions}
                          onChange={(e) =>
                            setStartup({ ...startup, bransje: e })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Tags</label>
                      <ProfileTags
                        handleParentValues={handleTagChange}
                        tagOptions={tagOptions}
                        chosenTags={startup.tags}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <br />

                <div className="hr-line-dashed my-5" />

                <div className="form-group">
                  <div className="col-sm-4 col-sm-offset-2">
                    <button
                      type="button"
                      className="btn btn-white m-r"
                      onClick={onCancel}
                    >
                      {t('profile.Cancel')}
                    </button>
                    <button
                      className="btn btn-primary"
                      disabled={startupEdit.updating}
                      type="submit"
                    >
                      {t('startups.Save')}
                    </button>
                  </div>
                </div>
              </form>

              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    startupEdit: state.startupEdit,
    startups: state.startups,
    users: state.users,
    idehavere: state.idehavere,
    tags: state.tags,
    teammembers: state.teammembers,
  }))
)(EditStartupContainer);
