import { IPosition } from '../../../types/IPosition';
import { IStatusTypesLabel } from '../../../types/IStatusTypes';
import isLeader from '../../utils/isLeader';

function checkIfUserBelongs(
  position: IPosition,
  status: IStatusTypesLabel,
  currentClientTab: string
) {
  if (currentClientTab === 'Leder' && isLeader(position, status)) {
    return true;
  }
  if (currentClientTab === position && status === IStatusTypesLabel.Aktiv) {
    // This check triggers when the position is either Veileder or Promo
    return true;
  }
  if (currentClientTab === 'Alumni' && status === IStatusTypesLabel.Alumni) {
    return true;
  }
  if (currentClientTab === 'Alle') {
    return true;
  }
  return false;
}

export default checkIfUserBelongs;
