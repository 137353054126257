const initialState = {
  view: 'dashboard',
  clientWidget: null,
  currentClientTab: 'Veileder',
  currentTagsTab: 'bransjeOptions',
  currentTeamMembersTab: 'New',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CURRENT_VIEW':
      return {
        ...state,
        currentView: action.currentView,
      };
    case 'UPDATE_CURRENT_CLIENT_WIDGET':
      return {
        ...state,
        clientWidget: action.currentClient,
      };
    case 'UPDATE_CURRENT_CLIENT_TAB':
      return {
        ...state,
        currentClientTab: action.currentClientTab,
      };
    case 'UPDATE_CURRENT_TAGS_TAB':
      return {
        ...state,
        currentTagsTab: action.currentTagsTab,
      };
    case 'UPDATE_CURRENT_TEAMMEMBERS_TAB':
      return {
        ...state,
        currentTeamMembersTab: action.currentTeamMembersTab,
      };
    default:
      return state;
  }
};
