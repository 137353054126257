/* Unused - Used for filtering in <th> */

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Column, Row } from 'react-table';

interface IOption {
  label: string;
  value: string;
}

interface IHeaderSelectFilter {
  column: any;
  state: any;
  rows: any;
}

const HeaderSelectFilter = ({ column, state, rows }: IHeaderSelectFilter) => {
  const { setFilter, id, filterValue } = column;
  const [options, setOptions] = useState<IOption[]>();

  useEffect(() => {
    const optionsAdded: string[] = [];
    const newOptions: IOption[] = [{ label: 'Vis alle', value: '' }];

    Object.keys(rows).forEach((row) => {
      let option = '';

      if (id === 'phaseLabel') {
        option = rows[row].values.phaseLabel;
      }
      if (id === 'field') {
        option = rows[row].values.field;
      }
      if (id === 'statusLabel') {
        option = rows[row].values.statusLabel;
      }

      if (!optionsAdded.includes(option)) {
        newOptions.push({
          label: option,
          value: option,
        });

        optionsAdded.push(option);
      }
    });

    setOptions(newOptions);
  }, [state.filters]);

  return (
    <Select
      // https://github.com/JedWatson/react-select/issues/1879
      key={JSON.stringify({ id: `${Math.floor(Math.random() * 1001)}` })}
      onChange={(e: any) => setFilter(e.value)}
      options={options}
      value={{ label: filterValue, value: '' }}
    />
  );
};

export default HeaderSelectFilter;
