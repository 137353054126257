import React from 'react';

export default function MenuItem({
  faIcon,
  updateTo,
  menuText,
  onClick,
  currentClientTab,
}) {
  return (
    <li
      className={currentClientTab === updateTo ? 'active' : ''}
      onClick={() => onClick(updateTo)}
    >
      <a data-toggle="tab">
        {faIcon} {menuText}{' '}
      </a>
    </li>
  );
}
