import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import { FaPen as FaPencil } from 'react-icons/fa';
import EditMeetingNote from '../EditMeetingNote/EditMeetingNote.jsx';
import MeetingNote from '../MeetingNote/MeetingNote';
import sortMeetingNotes from './sortMeetingNotes';
import editModalStyle from '../MeetingNote/editModalStyle';
import AdminFrame from '../../admin/AdminFrame';
import WhiteButton from '../MeetingNote/WhiteButton';
import { Link } from 'react-router-dom';

function StartupMeetingNotes({
  meetingNotes,
  usersObj,
  currentUser,
  startupId,
  t,
  setAddMeetingNote,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [meetingNotesDisplay, setMeetingNotesDisplay] = useState(null);

  useEffect(() => {
    let noteArray = [];
    if (meetingNotes[0] === 'No notes') {
      setMeetingNotesDisplay(t('startups.NoNotesYet'));
    } else {
      // Sort notes according to posted date and make meetingnotes
      sortMeetingNotes(meetingNotes);
      noteArray = Object.keys(meetingNotes).map((key) => {
        const author = usersObj[meetingNotes[key].author];
        author.uid = meetingNotes[key].author;
        return (
          <MeetingNote
            key={key}
            note={meetingNotes[key]}
            author={author}
            currentUser={currentUser}
            startupId={startupId}
          />
        );
      });
      setMeetingNotesDisplay(noteArray);
    }
  }, [meetingNotes, usersObj, currentUser, startupId]);

  return (
    <AdminFrame
      title={
        <div>
          <WhiteButton
            onClick={() => setAddMeetingNote(true)}
            type="button"
            icon={<FaPencil />}
            text={t('startups.NewNote')}
          />
          <h1>{t('startups.MeetingNotes')}</h1>
        </div>
      }
      textTitle={false}
      content={
        <div className="feed-activity-list">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={editModalStyle}
            contentLabel=""
            ariaHideApp={false}
          >
            <EditMeetingNote
              closeEditModal={() => setModalIsOpen(false)}
              startupId={startupId}
              currentUser={currentUser}
              currentUserObj={usersObj[currentUser]}
              newNote
            />
          </Modal>
          {meetingNotesDisplay}
        </div>
      }
    />
  );
}

export default withTranslation('common')(StartupMeetingNotes);
