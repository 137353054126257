import React, { useRef, useState } from 'react';
import LightButton from './LightButton';

export default function CopyToClipboard({ copyValues, handleFilterChange }) {
  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess('Kopiert!');
  }

  return (
    <div>
      <form>
        <LightButton
          text="Aktive veiledere"
          handle={() => handleFilterChange('activeMentors')}
        />
        <LightButton
          text="Aktive ansatte"
          handle={() => handleFilterChange('activeEmployees')}
        />
        <LightButton
          text="Aktive idehavere"
          handle={() => handleFilterChange('activeOriginators')}
        />
        <LightButton
          text="Aktive teammedlemmer"
          handle={() => handleFilterChange('activeTeam')}
        />
        <LightButton
          text="Aktive idehavere og teammedlemmer"
          handle={() => handleFilterChange('activeOriginatorsAndTeam')}
        />
        <textarea
          style={{ height: '180px' }}
          type="text"
          ref={textAreaRef}
          value={copyValues}
          className="form-control"
          readOnly
        />
      </form>
      {
        /* Logical shortcut for only displaying the
            button if the copy command exists */
        document.queryCommandSupported('copy') && (
          <div>
            <button
              className="btn btn-primary block full-width m-b"
              type="submit"
              onClick={copyToClipboard}
            >
              Kopier til utklippstavle
            </button>
            {copySuccess}
          </div>
        )
      }
    </div>
  );
}
