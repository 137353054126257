import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import feedback from '../../../redux/actions/feedback';
import { feedbackMessages } from '../../../constants';

// Generate a new note ID to be used
export const getNewNoteID = (startupID: string) => {
  return firebase
    .database()
    .ref()
    .child(`startups/${startupID}/meetingNotes`)
    .push().key;
};

// Use values from context and newNoteId to save the meeting note in Firebase
export const saveMeetingNote = (
  startupID: string,
  newNoteID: string,
  meetingNoteValues: any
) => {
  firebase
    .database()
    .ref(`startups/${startupID}/meetingNotes/${newNoteID}`)
    .update(meetingNoteValues)
    .then(() => {
      feedback.success(feedbackMessages.meetingNoteAddedSuccsess);
    })

    .catch((err) => {
      console.log(err);
    });
};
