function filterActiveEmployees(userArray) {
  const veilederEmails = userArray
    .filter((user) => user.sparkMail)
    .filter((user) => user.status['0'].label === 'Aktiv')
    .filter((user) => user.position !== 'Annet')
    .map((user) => user.sparkMail);
  return veilederEmails;
}

export default filterActiveEmployees;
