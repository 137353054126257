function countActiveStartups(startups, startupList) {
  let count = 0;
  if (startups) {
    Object.keys(startups).map((key) => {
      if (startupList[key] && startupList[key].status.label === 'Aktiv') {
        count += 1;
      }
    });
  }
  return count;
}

export default countActiveStartups;
