import React from 'react';

export function generatePushID() {
  /* See https://gist.github.com/mikelehen/3596a30bd69384624c11 */

  const PUSH_CHARS =
    '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

  let lastPushTime = 0;
  const lastRandChars = [];

  let now = new Date().getTime();
  const duplicateTime = now === lastPushTime;
  lastPushTime = now;

  const timeStampChars = new Array(8);
  for (let i = 7; i >= 0; i -= 1) {
    timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
    now = Math.floor(now / 64);
  }
  if (now !== 0)
    throw new Error('We should have converted the entire timestamp.');

  let id = timeStampChars.join('');

  if (!duplicateTime) {
    for (let i = 0; i < 12; i += 1) {
      lastRandChars[i] = Math.floor(Math.random() * 64);
    }
  } else {
    let counter = 0;
    for (let i = 11; i >= 0 && lastRandChars[i] === 63; i -= 1) {
      lastRandChars[i] = 0;
      counter = i;
    }
    lastRandChars[counter] += lastRandChars;
  }
  for (let i = 0; i < 12; i += 1) {
    id += PUSH_CHARS.charAt(lastRandChars[i]);
  }
  if (id.length !== 20) throw new Error('Length should be 20.');

  return id;
}

export function sincePosted(d) {
  const date = new Date(d);
  const dateNow = new Date();

  const timeDiff = Math.abs(dateNow.getTime() - date.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const diffHours = Math.ceil(timeDiff / (1000 * 3600));
  const diffMinutes = Math.ceil(timeDiff / (1000 * 60));
  let timeSincePosted = '';
  if (diffMinutes < 60) {
    timeSincePosted = `${diffMinutes}m`;
  } else if (diffHours < 24) {
    timeSincePosted = `${diffHours}t`;
  } else {
    timeSincePosted = `${diffDays}d`;
  }
  return timeSincePosted;
}

/* ShowDate tar inn en ISO8601-string:  2018-09-11T10:53:21Z  */
export const ShowDate = ({ regDate }) => {
  if (regDate === '') {
    const output = 'Not defined';
    return <div>{output}</div>;
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const regyear = regDate.substr(0, 4);
  const regmonth = regDate.substr(5, 2);
  const regmonthNum = parseInt(regmonth);
  const regday = regDate.substr(8, 2);

  const cleanRegDate = `${regday}. ${monthNames[regmonthNum - 1]} ${regyear}`;

  return <div>{cleanRegDate}</div>;
};
