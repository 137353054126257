import React from 'react';
import PhaseComponent from '../../../PhaseComponent';
import IPhase from '../../../../../types/IPhase';

interface IPhaseCellStyling {
  row: any;
}

const PhaseCellStyling = ({ row }: IPhaseCellStyling) => {
  const phase: IPhase = {
    label: row.original.phase.phaseLabel,
    value: row.original.phase.phaseValue,
  };

  return <PhaseComponent phase={phase} />;
};

export default PhaseCellStyling;
