import React, { useEffect, useState } from 'react';
import { ITableData } from '../../containers/StartupTableContainer';
import { IIdehaverList } from '../../types/IIdehaver';
import { IStartup, IStartupList } from '../../types/IStartup';
import StartupTable from '../startup/StartupTable/StartupTable';
import prepareStartupData from '../startup/StartupTable/utils/prepareStartupData';

interface IStartupsThisMonth {
  startupsList: IStartupList;
  idehaverList: IIdehaverList;
}

// Determines if a startup is made in the current month
const startupIsMadeThisMonth = (startupDate: Date) => {
  // Save todays month and year as a string
  const today = (
    new Date().getMonth().toString() + new Date().getFullYear().toString()
  ).toString();

  // Return true if it matches month and year of startup
  return (
    today ===
    startupDate.getMonth().toString() + startupDate.getFullYear().toString()
  );
};

const StartupsThisMonth = ({
  startupsList,
  idehaverList,
}: IStartupsThisMonth) => {
  const [tableData, setTableData] = useState<ITableData[]>([]);

  useEffect(() => {
    let startupsMadeThisMonth: { [startupID: string]: IStartup } = {};

    Object.keys(startupsList).map((key) => {
      const startupCreatedDate = new Date(startupsList[key].registreringsDato);

      if (startupIsMadeThisMonth(startupCreatedDate)) {
        startupsMadeThisMonth[key] = startupsList[key];
      }
    });

    setTableData(prepareStartupData(startupsMadeThisMonth, idehaverList));
  }, []);

  return tableData.length ? (
    <>
      <StartupTable
        tableData={tableData}
        showFilters={false}
        title={'Nye startups denne måneden'}
      />
    </>
  ) : (
    <p>Ingen nye startups registrert denne måneden enda</p>
  );
};

export default StartupsThisMonth;
