const initialState = {
  updating: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_PROFILE_UPDATE':
      return {
        ...state,
        updating: true,
      };

    case 'PROFILE_UPDATE_SUCCESS':
      return {
        ...state,
        updating: false,
      };
    case 'PROFILE_UPDATE_ERROR':
      return {
        updating: false,
        error: action.error,
      };
    default:
      return state;
  }
};
