import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  updateCurrentClientWidget,
  updateCurrentView,
} from '../redux/actions/nav';
import fetchUsersData from '../redux/actions/users';
import PageHeader from '../components/common/PageHeader';
import LoadingSquare from '../components/common/LoadingSquare';
import ClientTabComponent from '../components/client/ClientTabComponent/ClientTabComponent';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import UserProfileContainer from './UserProfileContainer';
import { useParams } from 'react-router-dom';

function VeilederListContainer(props) {
  const { dispatch, users, startups, nav, auth, t } = props;
  const { veilederId } = useParams(); // Using useParams to access route parameters

  useEffect(() => {
    dispatch(updateCurrentView('veiledere'));
    dispatch(fetchUsersData());
    if (!nav.clientWidget) {
      dispatch(updateCurrentClientWidget(auth.uid));
    }
  }, [dispatch, nav.clientWidget, auth.uid]);

  if (veilederId) {
    return (
      <div>
        <UserProfileContainer veilederId={veilederId} />
      </div>
    );
  }
  if (users.fetchingData || !startups.startupList) {
    return (
      <div>
        <PageHeader view={t('nav.Originators')} subview={null} />
        <LoadingSquare />
      </div>
    );
  }
  return (
    <div>
      <PageHeader view={t('nav.Originators')} subview={null} />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <ClientTabComponent startupList={startups.startupList} />
        </div>
      </div>
    </div>
  );
}

export default compose(
  withTranslation('common'),
  connect((state) => ({
    nav: state.nav,
    users: state.users,
    auth: state.auth,
    startups: state.startups,
  }))
)(VeilederListContainer);
