/* 

  General purpose select-filter, responsible for column filtering  

  Props:
    - setFilter: assigns a filter to a column
    - column: specify which column to perform filtering on (using SelectFilterTypes enum)
    - options: specify which options should be available in <Select />

*/

import React from 'react';
import Select from 'react-select';

export enum SelectFilterTypes {
  Name = 'name',
  Status = 'statusLabel',
  Phase = 'phaseLabel',
  Field = 'field',
  Supervisor = 'supervisorName',
}

interface ISelectFilter {
  setFilter: (columnId: string, updater: any) => void;
  column: SelectFilterTypes;
  options: {}[];
}

const SelectFilter = ({ setFilter, column, options }: ISelectFilter) => {
  return (
    <Select
      isClearable
      placeholder={'Velg...'}
      onChange={(e: any) => {
        // If the clear-button is pressed, e will be null. If that is the case, clear the filter
        if (e != null) {
          setFilter(column, e.label);
        } else {
          setFilter(column, '');
        }
      }}
      defaultValue={null}
      options={options}
    />
  );
};

export default SelectFilter;
