import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import feedback from './feedback';
import { feedbackMessages } from '../../constants';

export default function setTEBNumbers(tebNumbers) {
  const updates = {};

  updates['other/teb'] = tebNumbers;

  firebase
    .database()
    .ref()
    .update(updates)
    .then(() => {
      feedback.success(feedbackMessages.TEBNumbersSuccess);
    })
    .catch((err) => {
      feedback.error(err);
    });
}
