import React from 'react';
import { withTranslation } from 'react-i18next';

var HRNumbers = require('human-readable-numbers');

function TronderEnergiKeyFigures(props) {

    const { rounds, sokere, innvilget_stotte, sokt_stotte } = props;

    return (
        <>
            <div className="row">

                <div className="col-6">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {rounds}
                            </h1>
                            <p className="lead">
                                runder
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {sokere}
                            </h1>
                            <p className="lead">
                                søkere
                            </p>
                        </div>
                    </div>
                </div>

            </div>


            <div className="row">

                <div className="col-6">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {HRNumbers.toHumanString(innvilget_stotte)}
                            </h1>
                            <p className="lead">
                                innvilget totalt
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container text-center">
                            <h1 className="display-4">
                                {(innvilget_stotte / sokt_stotte * 100).toFixed(2)}%
                            </h1>
                            <p className="lead">
                                innvilgingsgrad
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withTranslation('common')(TronderEnergiKeyFigures);
