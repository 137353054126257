import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* Accepts a currentView and currentSubview */
interface PageHeaderProps {
  view: string;
  subview: string;
}

const PageHeader = ({ subview, view }: PageHeaderProps) => {
  const { t } = useTranslation('common');

  if (subview === null) {
    return (
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>{view}</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/dashboard">{t('dashboard.Home')}</Link>
            </li>
            <li className="active" />
          </ol>
        </div>
      </div>
    );
  }
  return (
    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-lg-10">
        <h2>{subview}</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/dashboard">{t('dashboard.Home')}</Link>
          </li>
          <li>
            <Link to={`/${view}`}>{view}</Link>
          </li>
          <li className="active">
            <strong>{subview}</strong>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PageHeader;
