const setMeetingNoteText = (payload) => ({
  type: 'SET_MEETING_NOTE_TEXT',
  payload,
});

const setMeetingNoteAuthor = (payload) => ({
  type: 'SET_MEETING_NOTE_AUTHOR',
  payload,
});

export const updateMeetingNoteText = (text) => {
  return setMeetingNoteText(text);
};

export const updateMeetingNoteAuthor = (author) => {
  return setMeetingNoteAuthor(author);
};
