import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import exportToCsv from './exportToCsv';
import formatAllData from './formatAllData';
import hostname from '../../../config/hostname';
import DataDescription from './DataDescription';
import AdminFrame from '../AdminFrame';

function ExportToCSVComponent(props) {
  const { t, startupList, idehaverList, teamList } = props;
  const [downloading, setDownloading] = useState(false);
  const chosenDelimiter = '~';
  const [allKeys, setAllKeys] = useState('');
  const [filteredStartupArray, setFilteredStartupArray] = useState([]);

  useEffect(() => {
    const values = formatAllData(
      startupList,
      idehaverList,
      teamList,
      hostname,
      chosenDelimiter
    );
    setFilteredStartupArray(values[0]);
    setAllKeys(values[1]);
  }, [startupList, idehaverList, teamList, hostname]);

  function handleExportToCsv() {
    exportToCsv(filteredStartupArray, chosenDelimiter, setDownloading);
  }

  return (
    <AdminFrame
      title={t('admin.ExportStartupDataToCSV')}
      content={
        <div className="ibox-content">
          <DataDescription
            chosenDelimiter={chosenDelimiter}
            allKeys={allKeys}
          />
          <button
            className="btn btn-primary block full-width m-b"
            type="submit"
            disabled={downloading}
            onClick={() => handleExportToCsv()}
          >
            {t('admin.Download')}
          </button>
        </div>
      }
    />
  );
}

export default withTranslation('common')(ExportToCSVComponent);
