import React, { useEffect } from 'react';
import MarkdownEditor from '../components/startup/MeetingNoteV2/MarkdownEditor';
import {
  getNewNoteID,
  saveMeetingNote,
} from '../components/startup/MeetingNoteV2/firebase';
import { Button } from 'react-bootstrap';
import { useDispatch, useStore } from 'react-redux';
import {
  updateMeetingNoteText,
  updateMeetingNoteAuthor,
} from '../redux/actions/meetingNote';
import '../css/MarkdownMeetingNotes.css';
import { feedbackMessages } from '../constants';
import feedback from '../redux/actions/feedback';
import { useTranslation } from 'react-i18next';

export interface IMarkdownMeetingNotes {
  startupID: string;
  setAddMeetingNote: (event: React.SetStateAction<boolean>) => void;
}

const MeetingNoteV2 = ({
  startupID,
  setAddMeetingNote,
}: IMarkdownMeetingNotes) => {
  const store = useStore();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // Save author of meeting note in redux state
  useEffect(() => {
    const author: string = store.getState().auth.uid;
    dispatch(updateMeetingNoteAuthor(author));
  }, []);

  const publishMeetingNote = () => {
    // Check if meeting note is not empty
    if (store.getState().meetingNote.text.length > 0) {
      const newNoteID = getNewNoteID(startupID);

      newNoteID
        ? saveMeetingNote(startupID, newNoteID, store.getState().meetingNote)
        : console.log('No meeting note ID number was assigned');

      // Close meeting notes viewer
      setAddMeetingNote(false);

      // Clear meeting note text stored in redux state
      dispatch(updateMeetingNoteText(''));
    } else {
      feedback.warning(feedbackMessages.lackingMeetingNoteText);
    }
  };

  return (
    <>
      <MarkdownEditor />
      <div className="buttonGroup">
        <Button variant="primary" onClick={() => publishMeetingNote()}>
          {t('meetingNotes.Publish')}{' '}
        </Button>
      </div>
    </>
  );
};

export default MeetingNoteV2;
