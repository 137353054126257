const axios = require('axios');

// Send user entered code to server
// ... to check if code is correct.
// Returns true if correct, else returns false.
export const verifyRegistrationCode = async (
  code: string,
  endpoint: string
): Promise<boolean> => {
  const data = {
    registrationCode: code,
  };
  const result = await axios.post(
    `https://quiet-coast-10640.herokuapp.com/${endpoint}`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result;
};
