// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.droptext {
    text-align: center;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    line-height: 200px;
    width:200px;
  }

  .dropzonebtns {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }


`, "",{"version":3,"sources":["webpack://./src/css/Dropzone.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB","sourcesContent":["\n.droptext {\n    text-align: center;\n    font-size: 16px;\n    margin-left: auto;\n    margin-right: auto;\n    height: 200px;\n    line-height: 200px;\n    width:200px;\n  }\n\n  .dropzonebtns {\n    display: flex;\n    justify-content: center;\n    margin-top: 30px;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
