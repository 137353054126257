/* Unused - Used for filtering in <th> */

import React from 'react';

const HeaderInputFilter = ({ column }: { column: any }) => {
  const { filterValue, setFilter } = column;

  return (
    <input
      onChange={(e: { target: { value: string } }) => setFilter(e.target.value)}
    />
  );
};

export default HeaderInputFilter;
