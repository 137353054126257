import msToFormattedDate from '../../utils/msToFormattedDate';

function meetingNotesData(
  allStartups,
  userList,
  meetingDateStart,
  meetingDateEnd,
  chosenDelimiter
) {
  const filteredNotes = [];
  let allNoteKeys;
  let allKeys = '';
  Object.keys(allStartups).map((startupKey) => {
    const currentStartup = allStartups[startupKey];
    if (currentStartup.meetingNotes) {
      Object.keys(currentStartup.meetingNotes).map((meetingKey) => {
        let {
          postedOn,
          author,
          attendees,
          meetingPlace,
          meetingStart,
          meetingEnd,
          meetingDate,
        } = currentStartup.meetingNotes[meetingKey];
        if (meetingDateStart < postedOn && postedOn < meetingDateEnd) {
          if (attendees) {
            let attString = '';
            Object.keys(attendees).map((index) => {
              attString += `${attendees[index].name} - ${attendees[index].phonenumber}, `;
            });
            attendees = attString;
          }
          const note = {
            postedOn: msToFormattedDate(postedOn),
            author: `${userList[author].firstname} ${userList[author].lastname} - ${userList[author].phoneNumber}`,
            attendees,
            meetingPlace,
            meetingDate: msToFormattedDate(meetingDate),
            meetingStart,
            meetingEnd,
          };
          if (allKeys === '') {
            allNoteKeys = Object.keys(note);
            allNoteKeys.map((key) => {
              allKeys += `${key}${chosenDelimiter}`;
            });
          }
          filteredNotes.push(note);
        }
      });
    }
  });
  return [filteredNotes, allKeys];
}

export default meetingNotesData;
