function filterActiveMentors(userArray) {
  const veilederEmails = userArray
    .filter((user) => user.sparkMail)
    .filter((user) => user.status['0'].label === 'Aktiv')
    .filter((user) => user.position === 'Veileder')
    .map((user) => user.sparkMail);
  return veilederEmails;
}

export default filterActiveMentors;
