export const updateCurrentView = (currentView) => ({
  type: 'UPDATE_CURRENT_VIEW',
  currentView,
});

export const updateCurrentClientWidget = (currentClient) => ({
  type: 'UPDATE_CURRENT_CLIENT_WIDGET',
  currentClient,
});

export const updateCurrentClientTab = (currentClientTab) => ({
  type: 'UPDATE_CURRENT_CLIENT_TAB',
  currentClientTab,
});

export const updateCurrentTagsTab = (currentTagsTab) => ({
  type: 'UPDATE_CURRENT_TAGS_TAB',
  currentTagsTab,
});

export const updateCurrentTeamMembersTab = (currentTeamMembersTab) => ({
  type: 'UPDATE_CURRENT_TEAMMEMBERS_TAB',
  currentTeamMembersTab,
});
