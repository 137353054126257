import React from 'react';

export default function WhiteButton({ onClick, icon, text }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="btn btn-white btn-xs pull-right space-button"
    >
      {icon}
      {text}
    </button>
  );
}
