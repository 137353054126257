import React, { useState, useEffect } from 'react';
import CopyToClipboard from './CopyToClipboard';
import AdminFrame from '../AdminFrame';
import filterActiveMentors from './filterActiveMentors';
import filterActiveOriginators from './filterActiveOriginators';
import filterActiveTeam from './filterActiveTeam';
import filterActiveEmployees from './filterActiveEmployees';

export default function MailList({
  userArray,
  startupMap,
  idehaverList,
  teamList,
}) {
  // Formatting startups from map to a list
  const startupKeys = Object.keys(startupMap);
  const startupList = startupKeys.map((key) => startupMap[key]);

  const [filter, setFilter] = useState('activeMentors');
  const [mailString, setMailString] = useState('');

  useEffect(() => {
    let tempString = '';
    let dataList;
    if (filter === 'activeMentors') dataList = filterActiveMentors(userArray);
    if (filter === 'activeEmployees')
      dataList = filterActiveEmployees(userArray);
    if (filter === 'activeOriginators')
      dataList = filterActiveOriginators(startupList, idehaverList);
    if (filter === 'activeTeam')
      dataList = filterActiveTeam(startupList, teamList);
    if (filter === 'activeOriginatorsAndTeam')
      dataList = [
        ...filterActiveTeam(startupList, teamList),
        ...filterActiveOriginators(startupList, idehaverList),
      ];

    dataList.map((email) => {
      tempString += `${email}, `;
    });
    setMailString(tempString);
  }, [filter]);

  return (
    <AdminFrame
      title="Maillister"
      content={
        <CopyToClipboard
          copyValues={mailString}
          handleFilterChange={setFilter}
        />
      }
    />
  );
}
