// Hostname-variables:
// localhost
// basen.sparkntnu.no // ...split('.')[0] === 'basen'
// sparknord-prod.web.app ...split('.')[0] === 'sparknord-prod'

export const shortenHostname = (hostname: string) => {
  hostname = hostname.split('.')[0];

  // Shorten name
  if (hostname === 'sparknord-prod') {
    hostname = 'sparknord';
  }

  return hostname;
};

const getHostname = () => {
  let hostname = window.location.hostname;

  return shortenHostname(hostname);
};

const hostname = getHostname();

export default hostname;
