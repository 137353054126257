import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IMailchimpData } from '../../../types/IMailchimpData';
import AdminFrame from '../AdminFrame';
import { signUpToMailchimp } from './signUpToMailchimp';

const MailSignup = () => {
  const { register, handleSubmit, reset } = useForm<IMailchimpData>();
  const [feedback, setFeedback] = useState<any>(null);

  async function onSubmit(data: any) {
    reset();
    const response = signUpToMailchimp(data);
    // API call takes 2 seconds. Gives user feedback
    setFeedback(<p>Prøver å legge til epost ... </p>);
    setFeedback(
      (await response).status === 200 ? (
        <div className="alert alert-success" role="alert">
          La til epost i mailliste
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          En feil skjedde
        </div>
      )
    );
  }

  return (
    <AdminFrame
      title="Legg til i mailchimp-mailliste"
      content={
        <div>
          <p>
            Ved registrering legges man automatisk til i mailliste for
            arrangement, annen informasjon, TrønderEnergi-bidraget og
            Kvinneprosjektet. Man kan valgfritt legges til i Nyhetsbrev.
            Mottaker av epost fra maillisten kan selv velge interesser eller
            melde seg helt av maillisten. Maillisten er på{' '}
            <a href="https://mailchimp.com/" target="_blank">
              mailchimp
            </a>
            .
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="email_address">Epost</label>
              <input
                {...register('email_address', { required: true })}
                type="email"
                className="form-control"
                name="email_address"
                id="email_address"
              />
            </div>
            <div className="form-group form-check">
              <input
                id="signupToNewsletter"
                type="checkbox"
                {...register('signupToNewsletter')}
                className="form-check-input"
              />
              <label
                htmlFor="signupToNewsletter"
                className="form-check-label ml-4"
              >
                Legg epost også til i nyhetsbrev
              </label>
            </div>
            {feedback}
            <button type="submit" className="btn btn-primary mt-3">
              Registrer
            </button>
          </form>
        </div>
      }
    />
  );
};

export default MailSignup;
